import React from 'react'
import { reasonSchema } from './schema'
import { ErrorMessage, Formik } from 'formik'
import { userDisableReasons } from '../constants'
import { Modal, Form, Button } from '@themesberg/react-bootstrap'

const DisableReason = ({
  show,
  handleClose,
  markUserStatusInactive,
  name
}) => {
  return (
    <>
      <Formik
        initialValues={{
          reason: '',
          description: ''
        }}
        validationSchema={reasonSchema}
        onSubmit={(formValues, { resetForm }) => {
          markUserStatusInactive(formValues)
          resetForm({ formValues: '' })
          handleClose()
        }}
      >
        {({ values, touched, errors, handleChange, handleBlur, handleSubmit }) => (
          <Modal
            show={show}
            onHide={handleClose}
            backdrop='static'
            keyboard={false}
          >
            <Modal.Header closeButton>
              <h5>Mark {name || 'Player'} Inactive</h5>
            </Modal.Header>
            <Modal.Body>
              <div className='form-group'>
                <label
                  htmlFor='reason'
                  className={touched.reason && errors.reason ? 'text-danger' : ''}
                >
                  Status<span className='text-danger'> *</span>
                </label>
                <Form.Select
                  name='reason'
                  value={values.reason}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={touched.reason && errors.reason ? 'border border-danger' : ''}
                >
                  <option value=''>Select Reason</option>
                  {userDisableReasons.map(reason => <option value={reason?.value} key={reason?.value}>{reason?.label}</option>)}
                </Form.Select>
                <ErrorMessage
                  component='div'
                  name='reason'
                  className='text-danger'
                />
              </div>
              <div className='form-group mt-3'>
                <label
                  htmlFor='reason'
                >
                  Reason
                </label>
                <Form.Control
                  name='description'
                  type='text'
                  placeholder='Enter Description'
                  value={values.description}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
            </Modal.Body>
            <div className='mt-4 '>
              <Modal.Footer className='d-flex justify-content-between align-items-center'>
                <Button
                  variant='outline-warning'
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                  variant='outline-success'
                  onClick={handleSubmit}
                  className='ml-2'
                >
                  Submit
                </Button>
              </Modal.Footer>
            </div>
          </Modal>
        )}

      </Formik>
    </>
  )
}

export default DisableReason
