import { Button, Modal, Spinner } from '@themesberg/react-bootstrap'
import React from 'react'

const ConfirmationModal = ({
  showModal,
  onConfirm,
  onCancel,
  confirmText = 'Yes',
  cancelText = 'No',
  bodyText = 'Are you sure you want to proceed?',
  additionalNote = '',
  isLoading = false
}) => {
  return (
    <Modal show={showModal} onHide={onCancel}>
      <Modal.Body>
        <div className='fs-5'>
          {bodyText}
        </div>

        {additionalNote && (
          <>
            <hr className='mt-2 mb-3' />
            <div className='text-danger mt-2'>
              {additionalNote}
            </div>
          </>
        )}
      </Modal.Body>

      <Modal.Footer>
        <Button variant='outline-secondary' onClick={onConfirm}>
          {confirmText}
          {isLoading && (
            <Spinner
              as='span'
              animation='border'
              size='sm'
              role='status'
              aria-hidden='true'
            />
          )}
        </Button>

        <Button variant='outline-primary' onClick={onCancel}>
          {cancelText}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ConfirmationModal
