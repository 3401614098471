import {
  Button,
  Row,
  Col,
  Table,
  ButtonGroup
} from '@themesberg/react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import React from 'react'
import PaginationComponent from '../../../components/Pagination'
import CreateCasinoProviders from './components/CreateCasinoProvider'
import ConfirmationModal from '../../../components/ConfirmationModal'
import {
  faCheckSquare,
  faWindowClose,
  faEdit,
  faBan
} from '@fortawesome/pro-solid-svg-icons'
import useProviderListing from './useProviderListing'
import Trigger from '../../../components/OverlayTrigger'
import useCheckPermission from '../../../utils/checkPermission'
import { Form } from 'react-bootstrap'
import { isTenant } from '../../../utils/constants'

export default () => {
  const {
    page,
    show,
    data,
    type,
    name,
    limit,
    status,
    search,
    loading,
    setPage,
    setLimit,
    navigate,
    setSearch,
    handleYes,
    handleShow,
    statusShow,
    totalPages,
    aggregators,
    handleClose,
    aggregatorId,
    setStatusShow,
    updateProvider,
    createProvider,
    allAggregators,
    setAggregatorId,
    handleStatusShow,
    casinoProvidersData
  } = useProviderListing()

  const { isHidden } = useCheckPermission()
  return (
    <>
      <Row>
        <Col sm={8}>
          <h3>{!isTenant ? 'Master ' : ''}Casino Providers</h3>
        </Col>

        <Col sm={4}>
          <div className='d-flex justify-content-end text-right mb-2'>
            {!isTenant &&
              <>
                <Form.Label column='sm' style={{ marginBottom: '0', marginRight: '15px' }}>
                  Aggregator:
                </Form.Label>

                <Form.Select
                  onChange={(e) => setAggregatorId(e.target.value)}
                  value={aggregatorId}
                  size='sm'
                  className='me-2'
                  style={{ minWidth: '120px' }}
                >
                  <option value=''>All</option>
                  {aggregators?.rows?.map(({ masterGameAggregatorId, name }) => {
                    return <option value={masterGameAggregatorId} key={masterGameAggregatorId}>{name}</option>
                  })}
                </Form.Select>
              </>}
            <Form.Control
              type='search'
              name='search'
              placeholder='Search Provider'
              size='sm'
              style={{ minWidth: '230px' }}
              className='me-2'
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <Button
              variant='outline-success'
              className='f-right'
              size='sm'
              onClick={() => handleShow('Create', null)}
              hidden={isHidden({ module: { key: 'CasinoManagement', value: 'C' } }) || isTenant}
            >
              Create
            </Button>

            {isTenant && <Button
              variant='outline-success'
              size='sm'
              hidden={isHidden({ module: { key: 'CasinoManagement', value: 'U' } })}
              onClick={() => navigate('/tenant/casino-providers/reorder')}
            >
              Reorder
            </Button>}
          </div>
        </Col>
      </Row>

      <Table
        bordered
        striped
        responsive
        hover
        size='sm'
        className='text-center mt-4'
      >
        <thead className='thead-dark'>
          <tr>
            <th>Id</th>
            <th>Order Id</th>
            <th>Name</th>
            <th>Aggregator</th>
            <th>Thumbnail</th>
            <th>Allowed Fee Groups</th>
            <th>Restricted Fee Groups</th>
            <th>Status</th>
            <th>Demo Mode</th>
            <th>Actions</th>
          </tr>
        </thead>

        <tbody id={loading ? 'cover-spin' : ''}>
          {!loading &&
            Boolean(casinoProvidersData) &&
            casinoProvidersData?.rows?.map(
              (
                item,
                index
              ) => {
                return (
                  <tr key={isTenant ? item?.tenantCasinoProviderId : item?.masterCasinoProviderId}>
                    <td>{isTenant ? item?.tenantCasinoProviderId : item?.masterCasinoProviderId}</td>
                    <td>{item?.orderId || '-'}</td>
                    <td>
                      <Trigger message={item?.name}>
                        <span
                          style={{
                            width: '100px',
                            cursor: 'pointer'
                          }}
                          className='d-inline-block text-truncate'
                        >
                          {item?.name}
                        </span>
                      </Trigger>
                    </td>

                    <td>
                      {isTenant
                        ? allAggregators?.find(({ masterGameAggregatorId }) => masterGameAggregatorId === item?.masterGameAggregatorId)?.name
                        : item?.MasterGameAggregator?.name}
                    </td>

                    <td>
                      {item?.thumbnailUrl
                        ? (
                          <span
                            onClick={() => window.open(item?.thumbnailUrl)}
                            className='text-link'
                            style={{ cursor: 'pointer' }}
                          >
                            View Here
                          </span>
                        )
                        : (
                          <span className='text-danger'>No Image Available</span>
                        )}
                    </td>

                    <td>
                      {item?.allowedFeeGroups?.length
                        ? (
                          <Trigger message={item?.allowedFeeGroups?.toString()}>
                            <span>{item?.allowedFeeGroups?.toString()}</span>
                          </Trigger>
                        )
                        : (
                          '-'
                        )}
                    </td>
                    <td>
                      {item?.restrictedFeeGroups?.length
                        ? (
                          <Trigger
                            message={item?.restrictedFeeGroups?.toString()}
                          >
                            <span>{item?.restrictedFeeGroups?.toString()}</span>
                          </Trigger>
                        )
                        : (
                          '-'
                        )}
                    </td>

                    <td>
                      {item?.isActive
                        ? (
                          <span className='text-success'>Active</span>
                        )
                        : (
                          <span className='text-danger'>In Active</span>
                        )}
                    </td>

                    <td>{item?.demo ? 'Yes' : 'No'}</td>

                    <td>
                      {!isHidden({
                        module: { key: 'CasinoManagement', value: 'U' }
                      }) ||
                        !isHidden({
                          module: { key: 'CasinoManagement', value: 'T' }
                        })
                        ? (
                          <ButtonGroup>
                            <Trigger message='Edit'>
                              <Button
                                className='m-1'
                                size='sm'
                                variant='warning'
                                onClick={() =>
                                  handleShow('Edit', casinoProvidersData.rows[index])}
                                hidden={isHidden({ module: { key: 'CasinoManagement', value: 'U' } })}
                              >
                                <FontAwesomeIcon icon={faEdit} />
                              </Button>
                            </Trigger>

                            {!item?.isActive
                              ? (
                                <Trigger message='Set Status Active'>
                                  <Button
                                    className='m-1'
                                    size='sm'
                                    variant='success'
                                    onClick={() =>
                                      handleStatusShow(
                                        isTenant ? item?.tenantCasinoProviderId : item?.masterCasinoProviderId,
                                        item?.isActive,
                                        item?.name
                                      )}
                                    hidden={isHidden({ module: { key: 'CasinoManagement', value: 'T' } })}
                                  >
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </Button>
                                </Trigger>
                              )
                              : (
                                <Trigger message='Set Status In-Active'>
                                  <Button
                                    className='m-1'
                                    size='sm'
                                    variant='danger'
                                    onClick={() =>
                                      handleStatusShow(
                                        isTenant ? item?.tenantCasinoProviderId : item?.masterCasinoProviderId,
                                        item?.isActive,
                                        item?.name
                                      )}
                                    hidden={isHidden({ module: { key: 'CasinoManagement', value: 'T' } })}
                                  >
                                    <FontAwesomeIcon icon={faWindowClose} />
                                  </Button>
                                </Trigger>
                              )}

                            <Trigger message='View Blocked Countries'>
                              <Button
                                className='m-1'
                                size='sm'
                                variant='secondary'
                                hidden={isHidden({ module: { key: 'CasinoManagement', value: 'U' } }) || isTenant}
                                onClick={() =>
                                  navigate(
                                    `/super-admin/casino-providers/restrict-countries/${item?.masterCasinoProviderId}`
                                  )}
                              >
                                <FontAwesomeIcon icon={faBan} />
                              </Button>
                            </Trigger>
                          </ButtonGroup>
                        )
                        : (
                          'NA'
                        )}
                    </td>
                  </tr>
                )
              }
            )}

          {casinoProvidersData?.count === 0 && !loading && (
            <tr>
              <td colSpan={9} className='text-danger text-center'>
                No data found
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      {casinoProvidersData?.count !== 0 && !loading && (
        <PaginationComponent
          page={casinoProvidersData?.count < page ? setPage(1) : page}
          totalPages={totalPages}
          setPage={setPage}
          limit={limit}
          setLimit={setLimit}
        />
      )}

      <ConfirmationModal
        name={name}
        active={status}
        show={statusShow}
        handleYes={handleYes}
        setShow={setStatusShow}
      />
      <CreateCasinoProviders
        data={data}
        type={type}
        show={show}
        loading={loading}
        aggregators={aggregators}
        handleClose={handleClose}
        createProvider={createProvider}
        updateProvider={updateProvider}
      />
    </>
  )
}
