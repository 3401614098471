import { useEffect, useState } from 'react'
import { depositGGRReportStart } from '../../../../store/redux-slices/dashboard'
import { useDispatch, useSelector } from 'react-redux'
import { formatDateYMD, getDateDaysAgo } from '../../../../utils/dateFormatter'
import { checkIsTenant } from '../../../../utils/constants'
import useDidMountEffect from '../../../../utils/useDidMountEffect'

// Custom hook to manage the logic for fetching and handling deposits GGR (Gross Gaming Revenue) report
const useDepositsGGRReport = (selectedPortal) => {
  const dispatch = useDispatch()
  const { tenantDetails } = useSelector((state) => state.login)
  // Extracting loading state and report data from the Redux store
  const { depositGGRReportLoading, depositGGRReport } = useSelector(
    (state) => state.dashboard
  )
  // Check if the current user is a tenant
  const isTenant = checkIsTenant()

  // State for date options, initialized with 'today'
  const [dateOptions, setDateOptions] = useState('today')

  // Custom hook to check if the component has just mounted
  const isInitialRender = useDidMountEffect()

  // State for managing date range, initialized to today
  const [state, setState] = useState([
    {
      startDate: getDateDaysAgo(0), // Start date is today
      endDate: new Date(), // End date is today
      key: 'selection' // Key to identify the selection
    }
  ])

  // Configuration for table columns used in the report display
  const columns = [
    {
      key: 'casinoName',
      label: 'Casino Name',
      isTruncated: true,
      width: '150px',
      render: (rowData) => rowData?.casinoName || 'N/A'
    },
    {
      key: 'country',
      label: 'Country',
      isTruncated: true,
      width: '100px',
      render: (rowData) => rowData?.country || 'N/A'
    },
    {
      key: 'licenseName',
      label: 'License Name',
      isTruncated: true,
      width: '150px',
      render: (rowData) => rowData?.licenseName || 'N/A'
    },
    {
      key: 'playerType',
      label: 'Player Type',
      isTruncated: true,
      width: '120px',
      render: (rowData) => rowData?.playerType || 'N/A'
    },
    {
      key: 'totalPlayerDeposited',
      label: 'Total Player Deposit',
      isTruncated: true,
      width: '130px',
      render: (rowData) => rowData?.totalPlayerDeposited || 'N/A'
    },
    {
      key: 'totalRounds',
      label: 'Total Rounds',
      isTruncated: true,
      width: '130px',
      render: (rowData) => rowData?.totalRounds || 'N/A'
    },
    {
      key: 'totalRealBets',
      label: 'Total Real Bets',
      isTruncated: true,
      width: '150px',
      render: (rowData) => rowData?.totalRealBets || 'N/A'
    },
    {
      key: 'totalRealWins',
      label: 'Total Real Wins',
      isTruncated: true,
      width: '150px',
      render: (rowData) => rowData?.totalRealWins || 'N/A'
    },
    {
      key: 'totalBonusBets',
      label: 'Total Bonus Bets',
      isTruncated: true,
      width: '150px',
      render: (rowData) => rowData?.totalBonusBets || 'N/A'
    },
    {
      key: 'totalBonusWins',
      label: 'Total Bonus Wins',
      isTruncated: true,
      width: '150px',
      render: (rowData) => rowData?.totalBonusWins || 'N/A'
    },
    {
      key: 'totalDeposits',
      label: 'Total Deposits',
      isTruncated: true,
      width: '150px',
      render: (rowData) => rowData?.totalDeposits || 'N/A'
    },
    {
      key: 'totalGgr',
      label: 'Total GGR',
      isTruncated: true,
      width: '130px',
      render: (rowData) => rowData?.totalGgr || 'N/A'
    }
  ]

  // Trigger report initiation when dependencies change
  useEffect(() => {
    initiateDepositGGRReport()
  }, [selectedPortal, state])

  useEffect(() => {
    if (dateOptions !== 'custom' && !isInitialRender) {
      initiateDepositGGRReport()
    }
  }, [dateOptions])

  /**
   * Function to initiate the report dispatch action.
   * It dispatches the action with necessary parameters for generating the report.
   */
  const initiateDepositGGRReport = () => {
    dispatch(
      depositGGRReportStart({
        isTenant, // Assuming non-tenant as 'superadmin' logic
        startDate: formatDateYMD(state[0].startDate),
        endDate: formatDateYMD(state[0].endDate),
        tenantId: isTenant ? tenantDetails?.tenantId : selectedPortal || '',
        dateOptions
      })
    )
  }

  const getCsvDownloadUrl = (selectedPortal) => {
    const dateParams = dateOptions === 'custom'
      ? `customStartDate=${formatDateYMD(state[0].startDate)}&customEndDate=${formatDateYMD(state[0].startDate)}`
      : `startDate=${formatDateYMD(state[0].startDate)}&endDate=${formatDateYMD(state[0].startDate)}`

    // Construct the request URL
    return `${process.env.REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/report/deposit-ggr?tenantId=${isTenant ? tenantDetails.tenantId : selectedPortal || ''}&dateOptions=${dateOptions}&${dateParams}&csvDownload=true`
  }

  // Return the state and functions to be used in components
  return {
    columns,
    data: depositGGRReport,
    loading: depositGGRReportLoading,
    initiateDepositGGRReport,
    dateOptions,
    setDateOptions,
    state,
    setState,
    getCsvDownloadUrl
  }
}

export default useDepositsGGRReport
