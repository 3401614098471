import React from 'react'
import {
  Row,
  Col
} from '@themesberg/react-bootstrap'

import useFormFields from './useFormFields'
import RegistrationFormFields from '../../../components/RegistrationFormField'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenField } from '@fortawesome/pro-solid-svg-icons'
import { checkIsTenant } from '../../../utils/constants'
import ClientFilter from '../../../components/ClientFilter'
import PortalFilter from '../../../components/PortalFilter'

const FormFields = () => {
  const {
    loading,
    data,
    handleChange,
    updateFields,
    selectedClient,
    setSelectedClient,
    selectedPortal,
    setSelectedPortal
  } = useFormFields()

  return (
    <>
      <Row>
        <Col>
          <h3><FontAwesomeIcon icon={faPenField} />&nbsp;Registration</h3>
        </Col>
        {!checkIsTenant() && (
          <Col className='d-flex align-self-center mt-2' lg={3}>
            <ClientFilter
              selectedClient={selectedClient}
              setSelectedClient={setSelectedClient}
              setSelectedPortal={setSelectedPortal}
            />
          </Col>
        )}
        {selectedClient && (
          <Col className='d-flex align-self-center mt-2' lg={3}>
            <PortalFilter
              selectedClient={selectedClient}
              selectedPortal={selectedPortal}
              setSelectedPortal={setSelectedPortal}
            />
          </Col>
        )}
      </Row>
      <RegistrationFormFields
        loading={loading}
        data={data}
        handleChange={handleChange}
        updateFields={updateFields}
        isTenant={(checkIsTenant() || selectedPortal)}
        selectedPortal={selectedPortal}
      />
    </>
  )
}

export default FormFields
