import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Button,
  Form
} from '@themesberg/react-bootstrap';
import { formatDateYMD, getDateDaysAgo } from '../../../utils/dateFormatter';
import { DateRangePickerWithoutInput } from '../../DateRangePicker';

export const dateConstants = [
  { label: 'All', value: 'all' },
  { label: 'Today', value: 'today' },
  { label: 'Yesterday', value: 'yesterday' },
  { label: 'Month To Date', value: 'monthtodate' },
  { label: 'Last 7 Days', value: 'last7days' },
  { label: 'Last 30 Days', value: 'last30days' },
  { label: 'Last 90 Days', value: 'last90days' },
  { label: 'Week To Date', value: 'weektodate' },
  { label: 'Year To Date', value: 'yeartodate' },
  { label: 'Previous Month', value: 'previousmonth' },
  { label: 'Previous Year', value: 'previousyear' },
  { label: 'Custom', value: 'custom' }
];

const CustomDatePicker = ({ onDateChange, date, isDisabled = false }) => {
  const [dateRange, setDateRange] = useState([
    {
      startDate: getDateDaysAgo(0),
      endDate: new Date(),
      key: 'selection'
    }
  ]);

  const [dateOptions, setDateOptions] = useState('today');

  useEffect(() => {
    onDateChange(dateRange)
  }, [dateRange])

  useEffect(() => {
    if (formatDateYMD(date?.[0]?.startDate) == formatDateYMD(getDateDaysAgo(0))) setDateOptions('today');
  }, [date])

  useEffect(() => {
    let startDate;
    let endDate = new Date();

    switch (dateOptions) {
      case 'all':
        startDate = new Date(2020, 0, 1);
        break;
      case 'today':
        startDate = getDateDaysAgo(0);
        break;
      case 'yesterday':
        startDate = getDateDaysAgo(1);
        endDate = getDateDaysAgo(1);
        break;
      case 'last7days':
        startDate = getDateDaysAgo(7);
        break;
      case 'last30days':
        startDate = getDateDaysAgo(30);
        break;
      case 'last90days':
        startDate = getDateDaysAgo(90);
        break;
      case 'monthtodate':
        startDate = new Date(endDate.getFullYear(), endDate.getMonth(), 1);
        break;
      case 'weektodate':
        startDate = getDateDaysAgo(endDate.getDay());
        break;
      case 'yeartodate':
        startDate = new Date(endDate.getFullYear(), 0, 1);
        break;
      case 'previousmonth':
        startDate = new Date(endDate.getFullYear(), endDate.getMonth() - 1, 1);
        endDate.setDate(0); // Last day of previous month
        break;
      case 'previousyear':
        startDate = new Date(endDate.getFullYear() - 1, 0, 1);
        endDate.setFullYear(endDate.getFullYear() - 1, 11, 31);
        break;
      default:
        return;
    }

    setDateRange([{ startDate, endDate, key: 'selection' }]);
  }, [dateOptions]);

  return (
    <>
      <Form.Select
        onChange={(e) => setDateOptions(e.target.value)}
        value={dateOptions}
        style={{ width: 'auto', marginRight: '10px' }}
        size='sm'
        disabled={isDisabled}
      >
        {dateConstants.map(({ label, value }) =>
          <option value={value} key={value}>{label}</option>
        )}
      </Form.Select>
      {(dateOptions === 'custom' && !isDisabled) &&
        <DateRangePickerWithoutInput
          state={dateRange} setState={setDateRange}
          isDisabled={isDisabled}
        />
      }
    </>
  );
};

export default CustomDatePicker;
