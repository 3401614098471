import { useSelector } from 'react-redux'
import { commonDateFormat, formatDateYMD } from '../../../utils/dateFormatter'
import { languageCode } from '../../Tenant/TenantConfigurations/Languages/constants'
import { getLabelByValue } from '../../../utils/helpers'
import { accountStatus } from '../Users/userConstant'
import { userDisableReasons } from './constants'

const useUserData = ({ userData }) => {
  const printData = (data) => (data ? 'Yes' : 'No')
  const { adminPermissions } = useSelector(state => state.admins)
  const showStyle = (data) => (data ? 'text-success' : 'text-danger')
  const { userCurrentStatus, userStatusLoading } = useSelector((state) => state.adminUser)
  const allAccountAndDisableOptions = [...accountStatus, ...userDisableReasons]

  const basicInfo = [
    { label: 'ID', value: userData?.userId },
    { label: 'Email', value: adminPermissions?.Users?.includes('RUI') ? userData?.email : 'test@yopmail.com', subValue: !adminPermissions?.Users?.includes('RUI') && 'blur-contant' },
    { label: 'Email Verified', value: printData(userData?.isEmailVerified), subValue: showStyle(userData?.isEmailVerified) },
    { label: 'Phone Verified', value: printData(userData?.isPhoneVerified), subValue: showStyle(userData?.isPhoneVerified) },
    { label: 'Full Name', value: `${userData?.firstName} ${userData?.lastName}` },
    { label: 'User Name', value: userData?.username },
    { label: 'Gender', value: userData?.gender },
    { label: 'NewsLetter', value: userData?.newsLetter ? 'True' : 'False', subValue: showStyle(userData?.newsLetter) },
    { label: 'SMS', value: userData?.sms ? 'True' : 'False', subValue: showStyle(userData?.sms) },
    { label: 'Status', value: userData?.status === 'true' || userData?.status === true ? 'Active' : userData?.status === null ? 'Temp In-Active' : getLabelByValue(allAccountAndDisableOptions, userData?.status), subValue: showStyle(userData?.status === 'true' || userData?.status === true) },
    { label: 'In-Active Reason', value: userData?.disableReason || '-' },
    { label: 'Player Level', value: userData?.level || '-' },
    { label: 'Portal', value: `${userData?.tenant?.name} (${userData?.tenant?.domain})` },
    { label: 'Reason', value: !userData?.status ? userData?.reason : '' },
    {
      label: 'Tags',
      value: userData?.tags
        ? userData?.tags?.length < 1 ? 'N/A' : userData?.tags?.join(', ')
        : 'N/A'
    }
  ]

  const moreInfo = [
    { label: 'IP Address', value: userData?.signInIp, subValue: 'text-success' },
    { label: 'Date Of Birth', value: commonDateFormat(userData?.dateOfBirth) },
    { label: 'Phone Number', value: adminPermissions?.Users?.includes('RUI') ? !userData?.phoneCode ? 'NA' : userData?.phoneCode + ' ' + userData?.phone : '00000000000', subValue: !adminPermissions?.Users?.includes('RUI') && 'blur-contant' },
    { label: 'City', value: userData?.city },
    { label: 'Preferred Language ', value: languageCode[userData?.locale] || '-' },
    { label: 'ZipCode', value: userData?.zipCode },
    { label: 'Country Code', value: userData?.countryCode }
  ]
  return {
    moreInfo,
    basicInfo,
    adminPermissions,
    userCurrentStatus,
    userStatusLoading
  }
}

export default useUserData
