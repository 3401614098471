import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import {
  fetchTenantDetailsStart,
  getAdminRoleStart,
  getTenantAdminRoleStart,
  getTenantRoleStart
} from '../../store/redux-slices/login'
import {
  getSuperAdminDetailsStart
} from '../../store/redux-slices/admins'
import { getItem, getLoginToken } from '../../utils/storageUtils'
import { SuperAdminRoutes, TenantRoutes } from '../../routes'
import { getAllSAProvidersStart } from '../../store/redux-slices/superAdminCasinoManagement'

const usePrivateRoute = (isTenantRoute) => {
  const accessToken = getLoginToken()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { loading } = useSelector((state) => state.login)

  useEffect(() => {
    if (accessToken) {
      if (isTenantRoute) {
        dispatch(fetchTenantDetailsStart())
        dispatch(getTenantAdminRoleStart())
        dispatch(getSuperAdminDetailsStart({ isTenant: true }))
        dispatch(getAllSAProvidersStart({ allData: true }))
      } else {
        dispatch(getAdminRoleStart())
        dispatch(getTenantRoleStart())
        dispatch(getSuperAdminDetailsStart({ isTenant: false, onStart: true }))
      }
    } else {
      isTenantRoute
        ? navigate(TenantRoutes.TenantSignin)
        : navigate(SuperAdminRoutes.SuperAdminSignin)
    }
  }, [accessToken, isTenantRoute, getItem('tenant-id')])

  return {
    accessToken,
    loading
  }
}

export default usePrivateRoute
