export const transactionFields = [
  {
    group: 'paymentiq',
    fields: [
      { key: 'authCode', label: 'Auth Code' },
      { key: 'accountId', label: 'Account Id' },
      { key: 'maskedAccount', label: 'Masked Account' },
      { key: 'accountHolder', label: 'Account Holder' }
    ]
  },
  {
    group: 'coinspaid',
    fields: [
      { key: 'address', label: 'Address' },
      { key: 'amount', label: 'Amount' },
      { key: 'currency', label: 'Currency' },
      { key: 'walletId', label: 'Wallet Id' },
      { key: 'cardBrand', label: 'Card Brand' },
      { key: 'accountHolder', label: 'Account Holder' }
    ]
  },
  {
    group: 'flaxpay',
    fields: [
      { key: 'address', label: 'Address' },
      { key: 'amount', label: 'Amount' },
      { key: 'currency', label: 'Currency' },
      { key: 'walletId', label: 'Wallet Id' },
      { key: 'cardBrand', label: 'Card Brand' },
      { key: 'accountHolder', label: 'Account Holder' }
    ]
  },
  {
    group: 'nodapay',
    fields: [
      { key: 'BankId', label: 'Bank Id' },
      { key: 'CardId', label: 'Card Id' },
      { key: 'Iban', label: 'Iban' },
      { key: 'AccountNumber', label: 'Account Number' },
      { key: 'accountHolder', label: 'Account Holder' }
    ]
  },
  {
    group: 'interrkassa',
    fields: [
      { key: 'ik_co_prs_id:', label: 'Parse Id' },
      { key: 'accountHolder', label: 'Account Holder' }
    ]
  }
]

// Function to get the fields based on the aggregator
export const getFieldsByAggregator = (aggregator) => {
  const group = transactionFields.find(entry => entry.group === aggregator)
  return group ? group.fields : []
}

// Function to check if there is any valid field in the transaction details
export const hasValidTransactionDetails = (transactionsMoreDetails) => {
  const selectedFields = getFieldsByAggregator(transactionsMoreDetails.aggregator)
  return selectedFields.some(
    ({ key }) => key in transactionsMoreDetails && transactionsMoreDetails[key]
  )
}
