import React from 'react'
import {
  Form,
  Table,
  Button,
  Spinner
} from '@themesberg/react-bootstrap'
import useCheckPermission from '../../utils/checkPermission'
import { fields } from './constants'
import { checkIsTenant } from '../../utils/constants'

const RegistrationFormFields = ({ loading, data, handleChange, updateFields, isTenant, selectedPortal = '' }) => {
  const { isHidden } = useCheckPermission()
  return (
    <>
      <div className='d-flex align-items-center  mt-3'>
        {(checkIsTenant() || selectedPortal) &&
          <div className='d-flex align-items-center mx-2'>
            <label className='mb-0'>Phone Verification</label>
            <Form.Check
              name='phoneVerification'
              className='mx-2'
              type='switch'
              checked={data?.phoneVerification}
              value={data?.phoneVerification}
              onChange={(e) => handleChange(e, e.target.checked ? 1 : 0)}
            />
          </div>}

        {(checkIsTenant() || selectedPortal) &&
          <div className='d-flex align-items-center mx-2'>
            <label className='mb-0'>Duplicate Check</label>
            <Form.Check
              name='duplicateUser'
              className='mx-2'
              type='switch'
              checked={data?.duplicateUser}
              value={data?.duplicateUser}
              onChange={(e) => handleChange(e, e.target.checked ? 1 : 0)}
            />
          </div>}
      </div>
      <Table bordered responsive hover className='mt-4'>
        <thead className='thead-dark'>
          <tr>
            <th>Field</th>
            {isTenant && <th>Action</th>}
            <th>Required</th>
          </tr>
        </thead>

        <tbody id={loading ? 'cover-spin' : ''}>
          {data && !loading &&
            fields?.map((field, index) => field !== 'phoneVerification' && (
              <tr key={index}>
                <td>{field.label}</td>
                <td>
                  <Form.Check
                    name={field.value}
                    type='switch'
                    checked={data[field.value]}
                    value={data[field.value]}
                    onChange={(e) => handleChange(e, '')}
                    disabled={data?.disable?.includes(field.value)}
                  />
                </td>
                {
                  isTenant &&
                  <td>
                    <Form.Check
                      name={field.value}
                      type='switch'
                      checked={data[field.value] === 2}
                      value={data[field.value] === 2}
                      onChange={(e) => handleChange(e, e.target.checked ? 1 : 0)}
                      disabled={data?.disable?.includes(field.value)}
                    />
                  </td>
                }
              </tr>
            ))}
        </tbody>
      </Table>

      {!loading &&
        <div className='mt-3 d-flex justify-content-between'>
          <Button
            variant='outline-success'
            onClick={updateFields}
            hidden={checkIsTenant() ? isHidden({ module: { key: 'TenantSettings', value: 'U' } }) : isHidden({ module: { key: 'RegistrationField', value: 'U' } })}
          >
            Submit
            {loading && (
              <Spinner
                as='span'
                animation='border'
                role='status'
                aria-hidden='true'
                style={{ marginLeft: '10px' }}
              />
            )}
          </Button>
        </div>}
    </>
  )
}

export default RegistrationFormFields
