import React from 'react'
import { checkIsTenant } from '../../utils/constants'
import ClientFilter from '../ClientFilter'
import PortalFilter from '../PortalFilter'
import { InlineLoader } from '../Preloader'
import useKYCTrigger from './hooks/useKYCTrigger'
import { Row, Col, Form as BForm, Spinner } from '@themesberg/react-bootstrap'
import { ErrorMessage, Form, Formik } from 'formik'
import { Button } from 'react-bootstrap'
import { kycTriggerSchema } from './schema'
import { getItem } from '../../utils/storageUtils'

const KYCTrigger = () => {
  const {
    loading,
    setting,
    setSetting,
    updateSettings,
    tenantDetails,
    selectedClient,
    selectedPortal,
    setSelectedClient,
    setSelectedPortal
  } = useKYCTrigger()

  return (
    <>
      <Row>
        <Col className='d-flex align-items-center'>
          <h3>KYC Trigger Settings</h3>
        </Col>
        <Col className='d-flex align-items-center flex-wrap custom-input-group'>
          {!checkIsTenant() &&
            <>
              <div className='m-1 label-input-block'>
                <ClientFilter
                  setSelectedClient={setSelectedClient}
                  setSelectedPortal={setSelectedPortal}
                  selectedClient={selectedClient}
                />
              </div>
              <div className='m-1 label-input-block'>
                <PortalFilter
                  setSelectedPortal={setSelectedPortal}
                  selectedPortal={selectedPortal}
                  selectedClient={selectedClient}
                  cms={selectedClient === ''}
                  hasAllOptions={false}
                />
              </div>
            </>}
        </Col>
      </Row>

      {!checkIsTenant() && !selectedPortal
        ? <p className='text-danger d-flex justify-content-center mt-3'>Please Select Tenant</p>
        : loading
          ? <InlineLoader />
          : (
            <Formik
              enableReinitialize
              initialValues={{
                ...(tenantDetails?.tenantConfig?.allowedCurrencies?.length &&
                      Object.fromEntries(tenantDetails?.tenantConfig?.allowedCurrencies.map((x) =>
                        [x, typeof tenantDetails?.tenantConfig?.kycSettings?.trigger === 'string' ? 0 : tenantDetails?.tenantConfig?.kycSettings?.trigger?.[x] || 0])))
              }}
              validateOnChange={setting}
              validationSchema={setting === 'after_withdrawal' ? kycTriggerSchema(tenantDetails?.tenantConfig?.allowedCurrencies) : null}
              onSubmit={(formValues) => {
                updateSettings({
                  data: {
                    tenantId: checkIsTenant()
                      ? parseInt(getItem('tenant-id'))
                      : parseInt(selectedPortal),
                    triggerSetting: setting === 'after_withdrawal' ? formValues : setting
                  }
                })
              }}
            >
              {({ values, handleChange, handleSubmit }) => (
                <Form className='mx-auto align-items-center mt-3' style={{ maxWidth: '600px' }}>
                  <div className='px-4 d-flex align-items-center'>
                    <BForm.Label style={{ width: '80px' }}>KYC Request</BForm.Label>
                    <BForm.Select
                      size='sm'
                      value={setting}
                      style={{ maxWidth: '220px' }}
                      onChange={(e) => setSetting(e.target.value)}
                    >
                      <option value=''>---Select---</option>
                      <option value='no_kyc'>No KYC Required</option>
                      <option value='before_withdrawal'>Before Withdrawal</option>
                      <option value='after_withdrawal'>After Withdrawal</option>
                    </BForm.Select>
                  </div>
                  {tenantDetails?.tenantConfig?.allowedCurrencies?.length && setting === 'after_withdrawal' &&
                    <Row>
                      {tenantDetails?.tenantConfig?.allowedCurrencies?.map(curr =>
                        <Col key={curr} lg={6} className='d-flex justify-content-between align-items-center mt-2 px-4'>
                          <BForm.Label className='me-3'>{curr}</BForm.Label>
                          <div>
                            <BForm.Control
                              name={curr}
                              type='number'
                              step='1'
                              min='0'
                              value={values?.[curr]}
                              onChange={handleChange}
                              style={{ width: '220px' }}
                            />
                            <ErrorMessage
                              component='div'
                              name={curr}
                              className='text-danger'
                            />
                          </div>
                        </Col>
                      )}
                    </Row>}

                  {setting &&
                    <div className='d-flex justify-content-between mt-3'>
                      <Button
                        className='m-2'
                        variant='outline-warning'
                      >
                        Cancel
                      </Button>
                      <Button
                        className='m-2'
                        variant='outline-success'
                        onClick={handleSubmit}
                      >
                        Submit
                        {loading && (
                          <Spinner
                            as='span'
                            animation='border'
                            size='sm'
                            role='status'
                            aria-hidden='true'
                          />)}
                      </Button>
                    </div>}
                </Form>
              )}
            </Formik>)}
    </>
  )
}
export default KYCTrigger
