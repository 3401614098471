import { Row, Col, Card, Tabs, Tab } from '@themesberg/react-bootstrap'
import { useLocation } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import Credentials from './components/TenantCredentials'
import AffiliateSystem from './components/AffiliateSystem'
import { checkIsTenant } from '../../../utils/constants'
import WhiteListDomains from './components/WhiteListDomains'
import RealityCheck from '../../../components/RealityCheck'
import KYCTrigger from '../../../components/KYCTrigger'
import useCheckPermission from '../../../utils/checkPermission'

const TenantCredentials = () => {
  const { state } = useLocation()
  const isTenant = checkIsTenant()
  const { isHidden } = useCheckPermission()
  const [selectedTab, setSelectedTab] = useState(state || (isTenant ? 'affiliate' : 'whitelistDomains'))

  useEffect(() => {
    window.history.replaceState({}, '')
  }, [])

  return (
    <>
      <Row>
        <Col>
          <h3>{isTenant ? 'Tenant Settings' : 'Settings'}</h3>
        </Col>
      </Row>

      <Card body>

        <Tabs activeKey={selectedTab} className='nav-light' onSelect={key => setSelectedTab(key)}>
          {isTenant && (
            <Tab eventKey='affiliate' title='Affiliate System'>
              <div className='mt-5'>
                <AffiliateSystem />
              </div>
            </Tab>
          )}

          {isTenant && (
            <Tab eventKey='credentials' title='Credentials'>
              <div className='mt-5'>
                <Credentials />
              </div>
            </Tab>
          )}

          <Tab eventKey='whitelistDomains' mountOnEnter title='Whitelist Domains'>
            <div className='mt-5'>
              <WhiteListDomains />
            </div>
          </Tab>

          {!isHidden({ module: { key: 'RealityCheck', value: 'R' } }) &&
            <Tab eventKey='realityCheck' mountOnEnter title='Reality Check'>
              <div className='mt-5'>
                <RealityCheck />
              </div>
            </Tab>}

          <Tab eventKey='kycTriggerSettings' mountOnEnter title='KYC Trigger Settings'>
            <div className='mt-5'>
              <KYCTrigger />
            </div>
          </Tab>

        </Tabs>
      </Card>
    </>
  )
}

export default TenantCredentials
