import { Button, Col, Form as BForm, Form, Row } from '@themesberg/react-bootstrap'
import useLanguageWise from '../hooks/useLanguageWise'
import { SuperAdminRoutes, TenantRoutes } from '../../../routes'
import React from 'react'
import Select from 'react-select'
import { ErrorMessage, Formik } from 'formik'
import { languageCode } from '../../../pages/Tenant/TenantConfigurations/Languages/constants'
import Preloader from '../../Preloader'
import { keysWiseSchema } from './schema'
import { GalleryModal } from '../../ConfirmationModal'
import Trigger from '../../OverlayTrigger'
import { faImages } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ClientFilter from '../../ClientFilter'
import PortalFilter from '../../PortalFilter'

const KeyWise = ({ errorCodes = false }) => {
  const {
    navigate,
    isTenant,
    keyOptions,
    setSelectedKey,
    selectedKey,
    myKeys,
    keys,
    myKeyData,
    loading,
    updateData,
    galleryModal,
    setGalleryModal,
    isHidden,
    selectedClient,
    setSelectedClient,
    selectedPortal,
    setSelectedPortal,
    isCountries
  } = useLanguageWise({ errorCodes })

  return (
    <>
      {loading
        ? <Preloader />
        : (
          <>
            <div>
              <div className='d-flex justify-content-between mb-2 align-items-center'>
                <h3>{errorCodes ? 'Error' : 'Language'} Management {errorCodes ? '(PaymentIQ)' : ''}</h3>
                <div className='d-flex justify-content-between'>
                  {(selectedKey && ['footerImageOne', 'footerImageTwo', 'footerImageThree'].includes(selectedKey?.label)) &&
                    <Trigger message='Gallery'>
                      <Button
                        hidden={isHidden({ module: { key: 'ImageGallery', value: 'R' } })}
                        onClick={() => setGalleryModal(true)}
                        variant='outline-secondary'
                      >
                        <FontAwesomeIcon icon={faImages} />
                      </Button>
                    </Trigger>}
                  {!isCountries &&
                    <Button
                      style={{ padding: '5px 10px', fontSize: '13px', lineHeight: '110%' }}
                      variant='warning'
                      onClick={() => !isTenant
                        ? navigate(errorCodes ? SuperAdminRoutes.EditErrSupportLanguage : SuperAdminRoutes.EditSupportLanguage, { state: { myAdminId: selectedClient || '', myTenantId: selectedPortal || '' } })
                        : navigate(errorCodes ? TenantRoutes.EditErrSupportLanguage : TenantRoutes.EditSupportLanguage)}
                    >
                      Edit by Position
                    </Button>}
                </div>
              </div>

              <div className='d-flex align-items-center flex-wrap custom-input-group'>
                {!isTenant &&
                  <>
                    <div className='m-1 label-input-block'>
                      <ClientFilter
                        setSelectedClient={setSelectedClient}
                        setSelectedPortal={setSelectedPortal}
                        selectedClient={selectedClient}
                      />
                    </div>
                    <div className='m-1 label-input-block'>
                      <PortalFilter
                        setSelectedPortal={setSelectedPortal}
                        selectedPortal={selectedPortal}
                        selectedClient={selectedClient}
                        cms={selectedClient === ''}
                        hasAllOptions={false}
                      />
                    </div>
                  </>}
              </div>
            </div>

            {(myKeys[keys] !== undefined || keys === ':keys')
              ? (
                <>
                  <div className='m-1 label-input-block'>
                    <BForm.Label className='m-1'>Keys</BForm.Label>
                    <Select
                      isClearable={false}
                      name='keys'
                      options={keyOptions}
                      value={selectedKey}
                      className='basic-multi-select'
                      classNamePrefix='select'
                      onChange={(option, e) => {
                        setSelectedKey({ label: option.value, value: option.value })
                      }}
                    />
                  </div>
                  <Formik
                    enableReinitialize
                    initialValues={
              { [selectedKey?.value]: { ...myKeyData?.[selectedKey?.value] } }
}
                    validationSchema={errorCodes ? null : keysWiseSchema(selectedKey?.value, myKeyData?.[selectedKey?.value])}
                    onSubmit={(formValues) => {
                      const data = Object.assign({ }, formValues)
                      updateData({
                        data: errorCodes ? { key: selectedKey?.value, data: formValues?.[selectedKey?.value], tenantId: selectedPortal || '' } : data,
                        type: 'key',
                        tenantId: selectedPortal || ''
                      })
                    }}
                  >
                    {({
                      values,
                      handleChange,
                      handleSubmit,
                      handleBlur
                    }) => (
                      <Form>
                        <Row className=' mt-3 mb-3'>
                          {selectedKey?.value && Object.keys(myKeyData?.[selectedKey?.value])?.map(lang => {
                            return (
                              <Col sm={6} key={lang} className='mb-3'>
                                <label
                                  className='fs-8'
                                  htmlFor={lang}
                                >
                                  {languageCode[lang]}{!['footerImageOne', 'footerImageTwo', 'footerImageThree'].includes(selectedKey?.value) && !errorCodes && <span className='text-danger'> *</span>}
                                </label>
                                <BForm.Control
                                  name={`${[selectedKey.value]}[${lang}]`}
                                  as='textarea'
                                  placeholder='Enter Value'
                                  value={values?.[selectedKey.value]?.[lang]}
                                  onInput={handleChange}
                                  onBlur={handleBlur}
                                  onChange={(e) => {
                                    handleChange(e)
                                  }}
                                />
                                <ErrorMessage
                                  component='div'
                                  name={`${[selectedKey.value]}[${lang}]`}
                                  className='text-danger'
                                />
                              </Col>
                            )
                          })}
                        </Row>
                        <div className='mt-4 d-flex justify-content-between'>
                          <Button
                            variant='outline-warning'
                            onClick={() => isTenant
                              ? navigate(errorCodes ? TenantRoutes.ErrLanguageManagement : window.location.href.includes('countries') ? TenantRoutes.Countries : TenantRoutes.LanguageManagement)
                              : navigate(errorCodes ? SuperAdminRoutes.ErrLanguageManagement : window.location.href.includes('countries') ? SuperAdminRoutes.Countries : SuperAdminRoutes.LanguageManagement, { state: { myAdminId: selectedClient || '', myTenantId: selectedPortal || '' } })}
                            className='ml-2'
                          >
                            Cancel
                          </Button>
                          <Button
                            className='ml-2'
                            variant='outline-success'
                            onClick={handleSubmit}
                          >
                            Submit
                          </Button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </>
                )
              : (
                <>
                  <Row>
                    <h5 className='text-center text-danger'>Key Not Found</h5>
                  </Row>
                </>
                )}
          </>
          )}

      {galleryModal &&
        <GalleryModal
          galleryModal={galleryModal}
          setGalleryModal={setGalleryModal}
          isTenant={isTenant}
        />}
    </>
  )
}

export default KeyWise
