import React from 'react'
import {
  Button,
  ButtonGroup,
  Col,
  Form,
  Row,
  Table
} from '@themesberg/react-bootstrap'
import useYourBonuses from '../hooks/useYourBonuses'
import Preloader from '../../../../components/Preloader'
import Trigger from '../../../../components/OverlayTrigger'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { BonusInfoModal } from '../../../../components/InfoModals'
import useCheckPermission from '../../../../utils/checkPermission'
import PaginationComponent from '../../../../components/Pagination'
import { bonusStatus, bonusTypes, tableHeaders } from '../constants'
import { faInfo, faWindowClose } from '@fortawesome/pro-solid-svg-icons'
import CustomDatePicker from '../../../../components/Custom/CustomDatePicker'
import { commonDateFormat, formatDate, getDateTime } from '../../../../utils/dateFormatter'

const YourBonuses = ({
  currencyCode,
  limit,
  setLimit,
  page,
  setPage,
  status,
  setStatus,
  bonusType,
  setBonusType,
  isTenant = false
}) => {
  const {
    loading,
    userBonus,
    totalPages,
    cancelBonusHandler,
    infoModal,
    setInfoModal,
    bonusData,
    setBonusData,
    searchByBonusId,
    setSearchByBonusId,
    state,
    setState
  } = useYourBonuses({ limit, page, status, bonusType, setPage, isTenant })

  const { isHidden } = useCheckPermission()

  return (
    <>
      {loading && <Preloader />}

      <Row>
        <Col className='d-flex' xs='auto'>
          <Form.Label
            column='sm'
            style={{ marginRight: '15px', minWidth: 'fit-content' }}
          >
            Bonus Type
          </Form.Label>
          <Form.Select
            name='bonusType'
            size='sm'
            value={bonusType}
            onChange={(e) => setBonusType(e.target.value)}
            style={{ maxWidth: '230px' }}
          >
            {bonusTypes.map((item) => {
              return (
                item.value !== 'cashfreespins' && (
                  <option key={`bonusType ${item.value}`} value={item.value}>
                    {item?.label}
                  </option>
                )
              )
            })}
          </Form.Select>

          <Form.Label
            column='sm'
            style={{ margin: '0 15px', minWidth: 'fit-content' }}
          >
            Status
          </Form.Label>
          <Form.Select
            name='isActive'
            size='sm'
            value={status}
            onChange={(e) => setStatus(e.target.value)}
            style={{ maxWidth: '230px' }}
          >
            {bonusStatus?.map(({ label, value }, i) => {
              return (
                <option
                  value={value}
                  key={`player-your-bonuses-status-filter ${i}`}
                >
                  {label}
                </option>
              )
            })}
          </Form.Select>
        </Col>

        <Col className='d-flex' xs='auto'>
          <Form.Label
            style={{ marginBottom: '0', marginRight: '15px', marginTop: '5px' }}
          >
            Bonus ID
          </Form.Label>

          <Form.Control
            type='number'
            value={searchByBonusId}
            placeholder='Bonus ID'
            size='sm'
            style={{ maxWidth: '130px', maxHeight: '15px' }}
            onChange={(e) => setSearchByBonusId(e.target.value)}
          />
        </Col>

        <Col className='d-flex' xs='auto'>
          <Form.Label
            style={{ marginBottom: '0', marginRight: '15px', marginTop: '5px' }}
          >
            Time Period
          </Form.Label>

          <CustomDatePicker onDateChange={setState} date={state} />
        </Col>
      </Row>

      <Table
        bordered
        striped
        responsive
        hover
        size='sm'
        className='text-center mt-4'
      >
        <thead className='thead-dark'>
          <tr>
            {tableHeaders.map((h, idx) => (
              <th key={`player-your-bonuses-heading ${idx}`}>{h.label}</th>
            ))}
          </tr>
        </thead>

        <tbody>
          {userBonus?.rows?.map((item, i) => {
            return (
              <tr key={`player-your-bonuses-row ${i}`}>
                <td>{item?.userBonusId}</td>
                <td>
                  {item.other?.bonusTitle?.EN ||
                    item?.other?.bonusTitle ||
                    item?.bonus?.promotionTitle?.EN}{item?.bonusId ? `(${item?.bonusId})` : ''}
                </td>
                <td>{item?.bonusType}</td>
                <td>{item?.expireAt ? commonDateFormat(item?.expireAt) : '-'}</td>
                <td>
                  {formatDate(new Date(String(item?.expireAt))) <
                    formatDate(new Date())
                    ? 'Yes'
                    : 'No'}
                </td>
                <td>{item?.status}</td>
                <td>
                  {item?.issuerRole
                    ? item?.issuerRole === 'user'
                      ? 'SYSTEM'
                      : item?.issuerRole?.toUpperCase()
                    : '-'}
                </td>
                <td>{commonDateFormat(item?.createdAt)}</td>
                <td>{item?.claimedAt ? getDateTime(item?.claimedAt) : '-'}</td>
                <td>{item?.amountToWager || '-'}</td>
                <td>
                  {item?.wageredAmount || (item?.amountToWager ? '0' : '-')}
                </td>
                <td>
                  {!isNaN(item?.other?.depositAmount)
                    ? item?.other?.depositAmount
                    : '-'}
                </td>
                <td>{!isNaN(item?.bonusAmount) ? item?.bonusAmount : '-'}</td>
                <td>{item?.cancelledBy || '-'}</td>
                <td>{item?.updatedAt ? getDateTime(item?.updatedAt) : '-'}</td>
                <td>
                  <ButtonGroup>
                    {!isHidden({
                      module: { key: 'Bonus', value: 'Issue' }
                    }) && (
                      <Trigger message='Cancel Bonus'>
                        <Button
                          className='m-1'
                          size='sm'
                          variant='danger'
                          disabled={
                              ![
                                'CLAIMING',
                                'PENDING',
                                'IN-PROCESS',
                                'ACTIVE'
                              ].includes(item?.status)
                            }
                          onClick={() => cancelBonusHandler(item?.userBonusId)}
                        >
                          <FontAwesomeIcon icon={faWindowClose} />
                        </Button>
                      </Trigger>
                    )}
                    <Trigger message='More Info'>
                      <Button
                        className='m-1'
                        size='sm'
                        variant='warning'
                        onClick={() => {
                          setBonusData(item)
                          setInfoModal(true)
                        }}
                      >
                        <FontAwesomeIcon icon={faInfo} />
                      </Button>
                    </Trigger>
                  </ButtonGroup>
                </td>
              </tr>
            )
          })}

          {userBonus?.count === 0 && (
            <tr>
              <td colSpan={14} className='text-danger text-center'>
                No data found
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      {userBonus?.count > 0 && (
        <PaginationComponent
          page={userBonus?.count < page ? setPage(1) : page}
          totalPages={totalPages}
          setPage={setPage}
          limit={limit}
          setLimit={setLimit}
        />
      )}

      {infoModal && (
        <BonusInfoModal
          show={infoModal}
          isTenant={isTenant}
          setShow={setInfoModal}
          bonusData={bonusData}
          currencyCode={currencyCode}
        />
      )}
    </>
  )
}

export default YourBonuses
