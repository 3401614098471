import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getAllTenantsListStart } from '../../../store/redux-slices/tenants'
import { debounce } from '../../../utils/debounce'
import { getItem, setItem } from '../../../utils/storageUtils'

const useHeader = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { loading, tenantsList } = useSelector((state) => state.tenants)
  const [tenantDetails, setTenantDetails] = useState()

  const tenantChangeHandler = (id) => {
    const details = tenantsList?.rows?.filter((item) => item.tenantId === Number(id))
    setItem('tenant-id', id)
    setTenantDetails(details[0])
  }

  useEffect(() => {
    if (tenantsList && tenantsList.rows && tenantsList.rows.length > 0) {
      tenantChangeHandler(getItem('tenant-id'))
    } else {
      setTenantDetails()
    }
  }, [tenantsList])

  useEffect(() => {
    if (tenantsList === null) {
      debounce(dispatch(getAllTenantsListStart({})), 1000)
    }
  }, [])

  return {
    navigate,
    loading,
    tenantsList,
    tenantDetails,
    tenantChangeHandler,
    dispatch
  }
}

export default useHeader
