import { takeLatest, put } from 'redux-saga/effects'
import {
  getDemographicDetailsTenant,
  getDemographicDetailsSuperAdmin,
  getSuperAdminPlayerManagement,
  getTenantPlayerManagement,
  getTenantLivePlayerReport,
  getSuperAdminLivePlayerReport,
  getTAPlayerLiability,
  getSAPlayerLiability,
  getSAKPIReport,
  getSAKPISummary,
  getTAKPIReport,
  getTAKPISummary,
  getSAGameReport,
  getTAGameReport,
  getTAPlayerGameReport,
  getSAPlayerGameReport,
  getElasticHealth,
  getCurrencyWiseWithdrawals,
  getJackpotContribution,
  getDepositGGRReport
} from '../../utils/apiCalls'

import {
  getDemographicReportFailed,
  getDemographicReportComplete,
  getDemographicReportStart,
  getPlayerManagementStart,
  getPlayerManagementComplete,
  getPlayerManagementFailure,
  getLivePlayersReportStart,
  getLivePlayersReportComplete,
  getLivePlayersReportFailure,
  getPlayerLiabilityStart,
  getPlayerLiabilitySuccess,
  getPlayerLiabilityFailure,
  getKPISummaryStart,
  getKPISummarySuccess,
  getKPISummaryFailure,
  getKPIReportStart,
  getKPIReportSuccess,
  getKPIReportFailure,
  getGameReportFailure,
  getGameReportSuccess,
  getGameReportStart,
  getPlayerGameReportFailure,
  getPlayerGameReportSuccess,
  getPlayerGameReportStart,
  getElasticHealthStart,
  getElasticHealthComplete,
  getElasticHealthFailure,
  getCurrencyWiseWithdrawalStart,
  getCurrencyWiseWithdrawalComplete,
  getCurrencyWiseWithdrawalFailure,
  getJackpotContributionStart,
  getJackpotContributionComplete,
  getJackpotContributionFailure,
  depositGGRReportStart,
  depositGGRReportComplete,
  depositGGRReportFailure
} from '../redux-slices/dashboard'
import { toast } from '../../components/Toast'
import { checkIsTenant } from '../../utils/constants'

export default function * dataWatcher () {
  yield takeLatest(getDemographicReportStart.type, getDemographicDataWorker)
  yield takeLatest(getPlayerManagementStart.type, getPlayerManagementWorker)
  yield takeLatest(getLivePlayersReportStart.type, getTopPlayerReportWorker)
  yield takeLatest(getPlayerLiabilityStart.type, getPlayerLiabilityWorker)
  yield takeLatest(getKPISummaryStart.type, getKPISummaryWorker)
  yield takeLatest(getKPIReportStart.type, getKPIReportWorker)
  yield takeLatest(getGameReportStart.type, getGameReportWorker)
  yield takeLatest(getPlayerGameReportStart.type, getPlayerGameReportWorker)
  yield takeLatest(getElasticHealthStart.type, getElasticHealthWorker)
  yield takeLatest(getCurrencyWiseWithdrawalStart.type, getCurrencyWiseWithdrawalWorker)
  yield takeLatest(getJackpotContributionStart.type, getJackpotContributionWorker)
  yield takeLatest(depositGGRReportStart.type, depositGGRReportWorker)
}

function * getDemographicDataWorker (action) {
  try {
    const { endDate, startDate, adminId, tenantId, dateOptions } = action && action.payload

    const { data } = yield checkIsTenant() ? getDemographicDetailsTenant({ endDate, startDate, dateOptions }) : getDemographicDetailsSuperAdmin({ endDate, startDate, adminId, tenantId, dateOptions })

    yield put(getDemographicReportComplete(data?.data?.demographic))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getDemographicReportFailed(e?.response?.data?.errors[0]?.description))
  }
}

function * getPlayerManagementWorker (action) {
  try {
    const { isTenant, endDate, startDate, adminId, tenantId, limit, dateOptions, tab } = action && action.payload

    const { data } = yield isTenant ? getTenantPlayerManagement({ endDate, startDate, limit, dateOptions, tab }) : getSuperAdminPlayerManagement({ endDate, startDate, adminId, tenantId, limit, dateOptions, tab })

    yield put(getPlayerManagementComplete(data?.data))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getPlayerManagementFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * getTopPlayerReportWorker (action) {
  try {
    const { adminId, tenantId } = action && action.payload

    const { data } = yield checkIsTenant() ? getTenantLivePlayerReport({}) : getSuperAdminLivePlayerReport({ adminId, tenantId })

    yield put(getLivePlayersReportComplete(data?.data))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getLivePlayersReportFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * getPlayerLiabilityWorker (action) {
  try {
    const { isTenant, endDate, startDate, adminId, tenantId } = action && action.payload

    const { data } = yield isTenant ? getTAPlayerLiability({ tenantId, endDate, startDate }) : getSAPlayerLiability({ endDate, startDate, adminId, tenantId })

    yield put(getPlayerLiabilitySuccess(data?.data?.playerLiabilityData))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getPlayerLiabilityFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * getKPISummaryWorker (action) {
  try {
    const { isTenant, endDate, startDate, adminId, tenantId } = action && action.payload

    const { data } = yield isTenant ? getTAKPISummary({ tenantId, endDate, startDate }) : getSAKPISummary({ endDate, startDate, adminId, tenantId })

    yield put(getKPISummarySuccess(data?.data?.KPISummary))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getKPISummaryFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * getKPIReportWorker (action) {
  try {
    const { isTenant, endDate, startDate, adminId, tenantId, dateOptions, selectedTab } = action && action.payload

    const { data } = yield isTenant ? getTAKPIReport({ tenantId, endDate, startDate, dateOptions, selectedTab }) : getSAKPIReport({ endDate, startDate, adminId, tenantId, dateOptions, selectedTab })
    yield put(getKPIReportSuccess(data?.data?.KPIReport))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getKPIReportFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * getGameReportWorker (action) {
  try {
    const { isTenant, endDate, startDate, adminId, tenantId, dateOptions, selectedTab, orderBy = '', sort = '' } = action && action.payload

    const { data } = yield isTenant ? getTAGameReport({ tenantId, endDate, startDate, dateOptions, selectedTab, orderBy, sort }) : getSAGameReport({ endDate, startDate, adminId, tenantId, dateOptions, selectedTab, orderBy, sort })
    yield put(getGameReportSuccess(data?.data?.gameReport))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getGameReportFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * getPlayerGameReportWorker (action) {
  try {
    const { isTenant, endDate, startDate, dateOptions, selectedTab, limit, userId } = action && action.payload

    const { data } = yield isTenant ? getTAPlayerGameReport({ endDate, startDate, dateOptions, selectedTab, limit, userId }) : getSAPlayerGameReport({ endDate, startDate, dateOptions, selectedTab, limit, userId })

    yield put(getPlayerGameReportSuccess(data?.data?.gameReport))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getPlayerGameReportFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * getElasticHealthWorker (action) {
  try {
    const { isTenant } = action && action.payload

    const { data } = yield getElasticHealth(isTenant)

    if (data?.data?.success) {
      yield put(getElasticHealthComplete(data?.data?.success))
    } else {
      yield toast(data?.data?.message, 'error')

      yield put(getElasticHealthFailure())
    }
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getElasticHealthFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * getCurrencyWiseWithdrawalWorker (action) {
  try {
    const { data } = yield getCurrencyWiseWithdrawals(action.payload)

    yield put(getCurrencyWiseWithdrawalComplete(data?.data?.getPendingWithdrawals))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(getCurrencyWiseWithdrawalFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * getJackpotContributionWorker (action) {
  try {
    const { data } = yield getJackpotContribution(action.payload)

    yield put(getJackpotContributionComplete(data?.data?.jackpotReport))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(getJackpotContributionFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * depositGGRReportWorker (action) {
  try {
    const { data } = yield getDepositGGRReport(action.payload)
    yield put(depositGGRReportComplete(data?.data?.depositGgr))
  } catch (e) {
    yield put(depositGGRReportFailure())
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
  }
}
