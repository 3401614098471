import { takeLatest, put } from 'redux-saga/effects'
import { serialize } from 'object-to-formdata'

import {
  createBonus,
  updateBonus,
  getSAConvertAmount,
  getBonus,
  superAdminViewToggleStatus,
  getPaymentMethod,
  getSuperAdminAllWageringTemplate,
  getTenantAllWageringTemplate,
  getSuperAdminWageringTemplateDetail,
  updateWageringTemplate,
  createWageringTemplate,
  tenantViewToggleStatus,
  getTAConvertAmount,
  getAllTABonus,
  getTenantWageringTemplate,
  getSuperAdminWageringTemplate,
  getTenantWageringTemplateDetail,
  getAllWageringCasinoGames,
  issueBonus,
  getUserBonus,
  cancelBonus,
  getTenantLanguages,
  getSegments,
  deleteBonus
} from '../../utils/apiCalls'
import {
  getSAConvertAmountStart,
  getSAConvertAmountSuccess,
  getSAConvertAmountFailure,
  createBonusStart,
  createBonusComplete,
  updateBonusStart,
  updateBonusComplete,
  getBonusStart,
  getBonusSuccess,
  getBonusFailure,
  issueBonusStart,
  issueBonusComplete,
  getUserBonusStart,
  getUserBonusSuccess,
  getUserBonusFailure,
  cancelBonusStart,
  cancelBonusComplete,
  updateSABonusStatusStart,
  updateSABonusStatusComplete,
  getSAPaymentMethodStart,
  getSAPaymentMethodSuccess,
  getSAPaymentMethodFailure,
  getWageringTemplateFailure,
  getWageringTemplateComplete,
  getWageringTemplateStart,
  getWageringTemplateDetailsStart,
  getWageringTemplateDetailsComplete,
  getWageringTemplateDetailsFailure,
  createWageringTemplateStart,
  createWageringTemplateComplete,
  createWageringTemplateFailure,
  updateWageringTemplateStart,
  updateWageringTemplateComplete,
  updateWageringTemplateFailure,
  getTABonusStart,
  getTABonusSuccess,
  getTABonusFailure,
  createTABonusStart,
  createTABonusComplete,
  updateTABonusStart,
  updateTABonusComplete,
  updateTABonusStatusStart,
  updateTABonusStatusComplete,
  getTAConvertAmountStart,
  getTAConvertAmountSuccess,
  getTAConvertAmountFailure,
  getAllTABonusStart,
  getAllTABonusComplete,
  getAllTABonusFailure,
  getTAPaymentMethodStart,
  getTAPaymentMethodSuccess,
  getTAPaymentMethodFailure,
  getWageringTemplatePaginationFailure,
  getWageringTemplatePaginationComplete,
  getWageringTemplatePaginationStart,
  getTenantAllCasinoGamesStart,
  getTenantAllCasinoGamesComplete,
  getTenantAllCasinoGamesFailure,
  getTenantLanguagesStart,
  getTenantLanguagesSuccess,
  getTenantLanguagesFailure,
  getSegmentsStart,
  getSegmentsSuccess,
  getSegmentsFailure,
  deleteBonusStart,
  deleteBonusComplete
} from '../redux-slices/bonus'
import { toast } from '../../components/Toast'
import { getAllBonusStart } from '../redux-slices/admins'
import { SuperAdminRoutes, TenantRoutes } from '../../routes'
import { resetPrepaidsStore } from '../redux-slices/superAdminCasinoManagement'

export default function * bonusWatcher () {
  yield takeLatest(getSAConvertAmountStart.type, getSAConvertAmountWorker)
  yield takeLatest(createBonusStart.type, createBonusStartWorker)
  yield takeLatest(updateBonusStart.type, updateBonusStartWorker)
  yield takeLatest(getBonusStart.type, getBonusStartWorker)
  yield takeLatest(issueBonusStart.type, issueBonusWorker)
  yield takeLatest(getUserBonusStart.type, getUserBonusWorker)
  yield takeLatest(cancelBonusStart.type, cancelBonusWorker)
  yield takeLatest(updateSABonusStatusStart.type, updateSABonusStatusWorker)
  yield takeLatest(getSAPaymentMethodStart.type, getSAPaymentMethodWorker)
  yield takeLatest(getWageringTemplateStart.type, getWageringTemplateWorker)
  yield takeLatest(getWageringTemplateDetailsStart.type, getWageringTemplateDetailWorker)
  yield takeLatest(createWageringTemplateStart.type, createWageringTemplateWorker)
  yield takeLatest(updateWageringTemplateStart.type, updateWageringTemplateWorker)
  yield takeLatest(createTABonusStart.type, createTABonusWorker)
  yield takeLatest(updateTABonusStart.type, updateTABonusWorker)
  yield takeLatest(updateTABonusStatusStart.type, updateTABonusStatusWorker)
  yield takeLatest(getTABonusStart.type, getTABonusWorker)
  yield takeLatest(getTAConvertAmountStart.type, getTAConvertAmountWorker)
  yield takeLatest(getAllTABonusStart.type, getAllTABonusWorker)
  yield takeLatest(getTAPaymentMethodStart.type, getTAPaymentMethodWorker)
  yield takeLatest(getWageringTemplatePaginationStart.type, getWageringTemplatePaginationWorker)
  yield takeLatest(getTenantAllCasinoGamesStart.type, getTenantAllCasinoGamesWorker)
  yield takeLatest(getTenantLanguagesStart.type, getTenantLanguagesWorker)
  yield takeLatest(getSegmentsStart.type, getSegmentsWorker)
  yield takeLatest(deleteBonusStart.type, deleteBonusWorker)
}

function * getBonusStartWorker (action) {
  try {
    const { bonusId, isTenant, userBonusId = '' } = action && action.payload
    const { data } = yield getBonus({ bonusId, isTenant, userBonusId })
    yield put(getBonusSuccess(data?.data?.bonusDetails))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(getBonusFailure())
  }
}
function * createBonusStartWorker (action) {
  try {
    let { data, navigate } = action && action.payload
    data = serialize(data)
    const isTenant = false
    yield createBonus(data, isTenant)
    yield toast('Bonus Created successfully', 'success')
    navigate('/super-admin/bonus')
    yield put(createBonusComplete())
    yield put(resetPrepaidsStore())
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(createBonusComplete())
  }
}

function * updateBonusStartWorker (action) {
  try {
    let { data, navigate } = action && action.payload
    data = serialize(
      data
    )
    const isTenant = false
    yield updateBonus(data, isTenant)
    yield toast('Bonus Updated successfully', 'success')
    navigate('/super-admin/bonus')
    yield put(updateBonusComplete())
    yield put(resetPrepaidsStore())
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(updateBonusComplete())
  }
}
function * issueBonusWorker (action) {
  try {
    const { data, isTenant, handleIssue } = action && action.payload

    yield issueBonus({ data, isTenant })

    yield put(issueBonusComplete())

    if (handleIssue) {
      handleIssue()
    } else {
      yield put(getUserBonusStart({ limit: 10, pageNo: 1, bonusType: 'all', status: 'all', userId: data?.userId, isTenant }))
    }

    yield toast('Bonus issued successfully', 'success')
    yield put(resetPrepaidsStore())
  } catch (e) {
    yield put(issueBonusComplete())

    yield toast(e?.response?.data?.errors[0]?.description, 'error')
  }
}

function * getUserBonusWorker (action) {
  try {
    const { limit, pageNo, bonusType, status, userId, isTenant, bonusId = '', startDate, endDate } = action && action.payload

    const { data } = yield getUserBonus({ limit, pageNo, bonusType, status, userId, isTenant, bonusId, startDate, endDate })

    yield put(getUserBonusSuccess(data?.data?.userBonus))
  } catch (e) {
    yield put(getUserBonusFailure())
  }
}

function * cancelBonusWorker (action) {
  try {
    const { data, limit, pageNo, bonusType, status, userId, isTenant } = action && action.payload

    yield cancelBonus({ data, isTenant })

    yield put(getUserBonusStart({ limit, pageNo, bonusType, status, userId, isTenant }))

    yield put(cancelBonusComplete())

    yield toast('Bonus cancelled successfully', 'success')
  } catch (e) {
    yield put(cancelBonusComplete())

    yield toast(e?.response?.data?.errors[0]?.description, 'error')
  }
}

function * getSAConvertAmountWorker (action) {
  try {
    const { currencyCode, maxBonusThreshold, minDeposit, maxWinAmount, zeroOutThreshold, tenantIds } = action && action.payload
    const { res } = yield getSAConvertAmount({ currencyFields: { maxBonusThreshold, minDeposit, maxWinAmount, zeroOutThreshold }, currencyCode, tenantIds })

    yield put(getSAConvertAmountSuccess(res?.data?.wallet))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getSAConvertAmountFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * updateSABonusStatusWorker (action) {
  try {
    const { adminId, tenantId, limit, pageNo, bonusType, isActive, search, data } = action && action.payload

    yield superAdminViewToggleStatus(data)
    yield put(updateSABonusStatusComplete())
    yield toast('Bonus Status Updated Successfully', 'success')

    yield put(getAllBonusStart({ adminId, tenantId, limit, pageNo, bonusType, isActive, search }))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(updateSABonusStatusComplete())
  }
}

function * getSAPaymentMethodWorker () {
  try {
    const { data } = yield getPaymentMethod({ flag: 'superadmin' })
    yield put(getSAPaymentMethodSuccess(data?.data?.paymentMethods))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(getSAPaymentMethodFailure())
  }
}

function * getWageringTemplateWorker (action) {
  try {
    const { isTenant, adminId } = action && action.payload
    const { data } = isTenant ? yield getTenantAllWageringTemplate() : yield getSuperAdminAllWageringTemplate({ adminId: adminId || '' })

    yield put(getWageringTemplateComplete(data?.data?.getTemplates))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(getWageringTemplateFailure())
  }
}

function * getWageringTemplateDetailWorker (action) {
  try {
    const { isTenant, wageringTemplateId, limit, pageNo, providerId, search } = action && action.payload
    const { data } = isTenant ? yield getTenantWageringTemplateDetail({ wageringTemplateId, limit, pageNo, providerId, search: search || '' }) : yield getSuperAdminWageringTemplateDetail({ wageringTemplateId, limit, pageNo, providerId, search: search || '' })
    yield put(getWageringTemplateDetailsComplete(data?.data))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(getWageringTemplateDetailsFailure())
  }
}

function * createWageringTemplateWorker (action) {
  try {
    const { isTenant, templateData, navigate } = action && action.payload
    const { data } = yield createWageringTemplate(isTenant, templateData)
    yield toast(data.message, 'success')
    yield put(createWageringTemplateComplete())

    navigate(isTenant ? TenantRoutes.WageringTemplate : SuperAdminRoutes.WageringTemplate)
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(createWageringTemplateFailure())
  }
}

function * updateWageringTemplateWorker (action) {
  try {
    const { isTenant, templateData, navigate } = action && action.payload
    const { data } = yield updateWageringTemplate(isTenant, templateData)
    yield toast(data.message, 'success')
    yield put(updateWageringTemplateComplete())

    navigate(isTenant ? TenantRoutes.WageringTemplate : SuperAdminRoutes.WageringTemplate)
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(updateWageringTemplateFailure())
  }
}

function * createTABonusWorker (action) {
  try {
    let { data, navigate } = action && action.payload
    data = serialize(data)
    const isTenant = true
    yield createBonus(data, isTenant)
    yield toast('Bonus Created successfully', 'success')
    navigate('/tenant/bonus')
    yield put(createTABonusComplete())
    yield put(resetPrepaidsStore())
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(createTABonusComplete())
  }
}
function * updateTABonusWorker (action) {
  try {
    let { data, navigate } = action && action.payload
    data = serialize(
      data
    )
    const isTenant = true
    yield updateBonus(data, isTenant)
    yield toast('Bonus Updated successfully', 'success')
    navigate('/tenant/bonus')
    yield put(updateTABonusComplete())
    yield put(resetPrepaidsStore())
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(updateTABonusComplete())
  }
}

function * updateTABonusStatusWorker (action) {
  try {
    const { limit, pageNo, bonusType, isActive, search, data } = action && action.payload

    yield tenantViewToggleStatus(data)
    yield put(updateTABonusStatusComplete())
    yield toast('Bonus Status Updated Successfully', 'success')

    yield put(getAllTABonusStart({ limit, pageNo, bonusType, isActive, search }))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(updateTABonusStatusComplete())
  }
}

function * getTABonusWorker (action) {
  try {
    const { bonusId, userBonusId } = action && action.payload
    const { data } = yield getBonus({ bonusId, isTenant: true, userBonusId })
    yield put(getTABonusSuccess(data?.data?.bonusDetails))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(getTABonusFailure())
  }
}

function * getTAConvertAmountWorker (action) {
  try {
    const { maxBonusThreshold, minDeposit, maxWinAmount, zeroOutThreshold, currencyCode, tenantIds } = action && action.payload
    const { res } = yield getTAConvertAmount({ currencyFields: { maxBonusThreshold, minDeposit, maxWinAmount, zeroOutThreshold }, currencyCode, tenantIds })

    yield put(getTAConvertAmountSuccess(res?.data?.wallet))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getTAConvertAmountFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * getAllTABonusWorker (action) {
  try {
    const { tenantId, limit, pageNo, bonusType, isActive, search, userId = '', reorder = false, bonusId = '' } = action && action.payload

    const { data } = yield getAllTABonus({ userId, tenantId, limit, pageNo, bonusType, isActive, search, reorder, bonusId })

    yield put(getAllTABonusComplete(data?.data?.bonus))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')

    yield put(getAllTABonusFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * getTAPaymentMethodWorker () {
  try {
    const { data } = yield getPaymentMethod({ flag: 'tenant' })
    yield put(getTAPaymentMethodSuccess(data?.data?.paymentMethods))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(getTAPaymentMethodFailure())
  }
}

function * getWageringTemplatePaginationWorker (action) {
  try {
    const { isTenant, adminId, search, limit, pageNo } = action && action.payload
    const { data } = isTenant ? yield getTenantWageringTemplate({ search, limit, pageNo }) : yield getSuperAdminWageringTemplate({ adminId, search, limit, pageNo })

    yield put(getWageringTemplatePaginationComplete(data?.data?.wageringTemplates))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(getWageringTemplatePaginationFailure())
  }
}

function * getTenantAllCasinoGamesWorker (action) {
  try {
    const { isTenant, providerId, search } = action && action.payload
    const { data } = yield getAllWageringCasinoGames({ isTenant, providerId, search: search || '' })
    yield put(getTenantAllCasinoGamesComplete(data?.data?.games))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(getTenantAllCasinoGamesFailure())
  }
}

function * getTenantLanguagesWorker (action) {
  try {
    const { tenantId, isTenant } = action && action.payload
    const { data } = yield getTenantLanguages({ tenantId, isTenant })
    yield put(getTenantLanguagesSuccess(data?.data?.languages?.filter(lang => lang !== 'EN')))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(getTenantLanguagesFailure())
  }
}

function * getSegmentsWorker (action) {
  try {
    const { isTenant, tenantId = '' } = action && action.payload
    const { data } = yield getSegments({ isTenant, tenantId })
    yield put(getSegmentsSuccess(data?.data?.segments))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(getSegmentsFailure())
  }
}

function * deleteBonusWorker (action) {
  try {
    const { isTenant, data, handleClose } = action && action.payload
    yield deleteBonus({ isTenant, data })
    yield put(deleteBonusComplete())

    if (handleClose) {
      handleClose()
    }
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(deleteBonusComplete())
  }
}
