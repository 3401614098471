// CustomTable.js
import React from 'react'
import { Table } from 'react-bootstrap'

const TableLayout = ({ columns, rowData, loading, renderRow }) => {
  return (
    <Table bordered striped responsive hover size='sm' className='text-center mt-4'>
      <thead className='thead-dark'>
        <tr>
          {columns.map((header, idx) => (
            <th key={idx}>{header.label}</th>
          ))}
        </tr>
      </thead>
      <tbody id={loading ? 'cover-spin' : ''}>
        {!loading && rowData?.length > 0 && rowData.map((row) => renderRow(row))}
        {rowData?.length === 0 && !loading && (
          <tr>
            <td colSpan={columns?.length} className='text-danger text-center'>
              No data found
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  )
}

export default TableLayout
