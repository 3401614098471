import React from 'react'
import { useSelector } from 'react-redux'
import useBannedGames from './hooks/useBannedGames'
import Trigger from '../../../components/OverlayTrigger'
import PortalFilter from '../../../components/PortalFilter'
import ClientFilter from '../../../components/ClientFilter'
import useCheckPermission from '../../../utils/checkPermission'
import PaginationComponent from '../../../components/Pagination'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ConfirmationModal from '../../../components/ConfirmationModal'
import { faCopy, faEdit, faEye } from '@fortawesome/free-solid-svg-icons'
import { Button, Row, Col, Table, ButtonGroup, Form } from '@themesberg/react-bootstrap'
import { faCheckSquare, faWindowClose, faRedoAlt } from '@fortawesome/pro-solid-svg-icons'

export default () => {
  const {
    loading,
    bannedGames,
    navigate,
    setLimit,
    setPageNo,
    setSearch,
    search,
    totalPages,
    limit,
    pageNo,
    isTenant,
    setSelectedClient,
    setSelectedPortal,
    selectedClient,
    selectedPortal,
    activeListId,
    setShow,
    show,
    handleYes,
    gamesDetails,
    setGamesDetails,
    status,
    setStatus
  } = useBannedGames()
  const { isHidden } = useCheckPermission()
  const tenantId = useSelector(state => state?.login?.tenantDetails?.tenantId)

  const activeStatus = () => {
    return (
      <>
        <Trigger message='Set Status Active'>
          <Button
            className='m-1'
            size='sm'
            disabled
            variant='success'
          >
            <FontAwesomeIcon icon={faCheckSquare} />
          </Button>
        </Trigger>
      </>
    )
  }

  const inActiveStatus = (bannedGamesListId, name, myStatus) => {
    return (
      <>
        <Trigger message='Set Status To Active'>
          <Button
            className='m-1'
            size='sm'
            variant={myStatus ? 'success' : 'danger'}
            onClick={() => {
              setStatus(!myStatus)
              setGamesDetails({ id: bannedGamesListId, name })
              setShow(true)
            }}
            hidden={isHidden({ module: { key: 'BannedGames', value: 'T' } })}
          >
            <FontAwesomeIcon icon={myStatus ? faCheckSquare : faWindowClose} />
          </Button>
        </Trigger>
      </>
    )
  }

  return (
    <>
      <Row>
        <Col>
          <h3>Banned Games List</h3>
        </Col>

        <Col className='col-auto'>
          <div className='d-flex justify-content-end align-items-center'>
            {!isTenant && <ClientFilter
              hasAllOptions={false}
              setSelectedClient={setSelectedClient}
              setSelectedPortal={setSelectedPortal}
              selectedClient={selectedClient}
            />}

            {!isTenant && selectedClient && <PortalFilter
              setSelectedPortal={setSelectedPortal}
              selectedPortal={selectedPortal}
              selectedClient={selectedClient}
              hasAllOptions={false}
            />}

            <Form.Label className='m-2 d-flex align-items-center'>
              Search
            </Form.Label>

            <Form.Control
              type='search'
              placeholder='Search Banned Games'
              size='sm'
              style={{ maxWidth: '230px', marginRight: '10px', maxHeight: '15px' }}
              value={search}
              onChange={(event) =>
                setSearch(
                  event.target.value.replace(/[~`!$%^&*#=)()><?]+/g, '')
                )}
            />

            <Trigger message='Reset Filters'>
              <Button
                variant='outline-success'
                className='me-3'
                size='sm'
                onClick={() => {
                  setPageNo(1)
                  setLimit(15)
                  setSearch('')
                  setSelectedClient('')
                  setSelectedPortal('')
                }}
              >
                <FontAwesomeIcon icon={faRedoAlt} />
              </Button>
            </Trigger>

            <Button
              className='m-1'
              size='sm'
              variant='outline-success'
              onClick={() =>
                navigate(`/${isTenant ? 'tenant' : 'super-admin'}/banned-games/create?tenantId=${tenantId || ''}`)}
              hidden={isHidden({ module: { key: 'BannedGames', value: 'C' } })}
            >
              Create
            </Button>
          </div>
        </Col>

      </Row>

      <Row>
        <Table bordered striped responsive hover size='sm' className='text-center mt-4'>
          <thead className='thead-dark'>
            <tr>
              {['ID',
                'Name',
                'Creator',
                'Action'
              ].map((h) => (
                <th key={h}>{h}</th>
              ))}
            </tr>
          </thead>

          <tbody id={loading ? 'cover-spin' : ''}>
            {!loading && bannedGames?.rows?.length > 0
              ? bannedGames.rows?.map(({ bannedGamesListId, name, parentType, tenantId }) => {
                return (
                  <tr key={`banned-games ${bannedGamesListId}${activeListId}`}>
                    <td>{bannedGamesListId}</td>
                    <td>{name}</td>
                    <td>{parentType}</td>
                    <td>
                      <ButtonGroup>
                        <Trigger message='View Details'>
                          <Button
                            className='m-1'
                            size='sm'
                            variant='info'
                            onClick={() =>
                              navigate(`/${isTenant ? 'tenant' : 'super-admin'}/banned-games/details/${bannedGamesListId}?tenantId=${tenantId || ''}`)}
                            hidden={isHidden({ module: { key: 'BannedGames', value: 'R' } })}
                          >
                            <FontAwesomeIcon icon={faEye} />
                          </Button>
                        </Trigger>
                        <Trigger message='Edit'>
                          <Button
                            className='m-1'
                            size='sm'
                            variant='warning'
                            disabled={isTenant && parentType === 'superadmin'}
                            onClick={() =>
                              navigate(`/${isTenant ? 'tenant' : 'super-admin'}/banned-games/edit/${bannedGamesListId}?tenantId=${tenantId || ''}`)}
                            hidden={isHidden({ module: { key: 'BannedGames', value: 'U' } })}
                          >
                            <FontAwesomeIcon icon={faEdit} />
                          </Button>
                        </Trigger>
                        <Trigger message='Clone'>
                          <Button
                            className='m-1'
                            size='sm'
                            variant='danger'
                            onClick={() =>
                              navigate(`/${isTenant ? 'tenant' : 'super-admin'}/banned-games/clone/${bannedGamesListId}`, { state: { isClone: true, bannedGamesListId } })}
                            hidden={isHidden({ module: { key: 'BannedGames', value: 'C' } })}
                          >
                            <FontAwesomeIcon icon={faCopy} />
                          </Button>
                        </Trigger>
                        {isTenant
                          ? (inActiveStatus(bannedGamesListId, name, bannedGamesListId === activeListId))
                          : selectedPortal && (
                            <>
                              {bannedGamesListId === activeListId
                                ? activeStatus()
                                : (inActiveStatus(bannedGamesListId, name))}
                            </>
                          )}
                      </ButtonGroup>
                    </td>
                  </tr>
                )
              })
              : !loading && (
                <tr>
                  <td colSpan={4} className='text-danger text-center'>
                    {bannedGames?.description || 'No data found'}
                  </td>
                </tr>
              )}
          </tbody>
        </Table>
      </Row>
      {bannedGames?.rows?.length > 0 && !loading && (
        <PaginationComponent
          page={bannedGames?.count < pageNo ? setPageNo(1) : pageNo}
          totalPages={totalPages}
          setPage={setPageNo}
          limit={limit}
          setLimit={setLimit}
        />
      )}

      {show && (
        <ConfirmationModal
          setShow={setShow}
          show={show}
          handleYes={handleYes}
          active={status}
          name={gamesDetails.name}
        />
      )}
    </>
  )
}
