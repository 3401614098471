import React from 'react'
import {
  Button,
  Row,
  Col
} from '@themesberg/react-bootstrap'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import useReorderProviders from '../hooks/useReorderProviders'
import Preloader from '../../../../components/Preloader'

export default () => {
  const {
    loading,
    state, onDragEnd, handleSave
  } = useReorderProviders()

  return (
    <>
      {loading
        ? <Preloader />
        : (
          <>
            <Row>
              <Col sm={8}>
                <h3>Casino Providers Reorder</h3>
              </Col>

              <Col>
                <div className='text-right mb-3'>
                  <Button
                    variant='outline-success'
                    onClick={() => handleSave()}
                  >
                    Save
                  </Button>
                </div>
              </Col>
            </Row>

            <div className='reorder-heading'>
              {[
                'ID',
                'Order Id',
                'Name',
                'Status'
              ].map((h) => (
                <p key={h}>{h}</p>
              ))}
            </div>

            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId='list'>
                {provided => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {state.count > 0 &&
                      state?.rows?.map(
                        (item, idx) => (
                          <Draggable draggableId={`id-${idx}`} key={idx} index={idx}>
                            {provided => (
                              <div
                                className='reorder-content'
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <p>{item?.tenantCasinoProviderId}</p>
                                <p>{item?.orderId || '-'}</p>
                                <p>
                                  {item?.name}
                                </p>

                                <p>
                                  {item?.isActive
                                    ? (
                                      <span className='text-success'>Active</span>
                                    )
                                    : (
                                      <span className='text-danger'>In Active</span>
                                    )}
                                </p>
                              </div>
                            )}
                          </Draggable>
                        ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>

            {state.count === 0
              ? <p className='text-danger text-center'>No data found</p>
              : null}
          </>
        )}
    </>
  )
}
