import React from 'react'
import { Route } from 'react-router-dom'

import PrivateRoute from '../../../components/PrivateRoute'
import { SuperAdminRoutes } from '../../../routes'

// Super-Admin routes
import Tenants from '../../Super-Admin/Tenants'
import TenantOwner from '../../Super-Admin/TenantOwner'
import Themes from '../../Super-Admin/Themes'
import Admins from '../../Super-Admin/Admins'
import AdminDetails from '../../Super-Admin/AdminDetails'
import TenantDetails from '../../Super-Admin/TenantDetails'
import Currencies from '../../Super-Admin/Currencies'
import CreateCurrencies from '../../Super-Admin/Currencies/components/CreateCurrencies'
import EditCurrency from '../../Super-Admin/Currencies/components/editCurrency'
import CreateTenantAdmin from '../../Super-Admin/TenantOwner/components/CreateTenantAdmin'
import Dashboard from '../../Super-Admin/Dashboard'
import EditTenant from '../../Super-Admin/EditTenant'
import EditTheme from '../../Super-Admin/Themes/EditTheme'
import CreateTheme from '../../Super-Admin/Themes/CreateTheme'
import Cms from '../../Super-Admin/CMS'
import Users from '../../Super-Admin/Users'
import UserDetails from '../../Super-Admin/UserDetails'
import CreateCredential from '../../Super-Admin/TenantDetails/components/CreateCredential'
import CMSDetail from '../../Super-Admin/CMS/components/CmsDetail'
import CasinoProviders from '../../Super-Admin/CasinoProviders'
import CasinoGames from '../../Super-Admin/CasinoGames'
import Countries from '../../Super-Admin/Countries'
import CreateSuperAdminUser from '../../Super-Admin/Admins/components/CreateSuperAdminUser'
import EditSuperAdminUser from '../../Super-Admin/Admins/components/EditSuperAdminUser'
import EditTenantAdmin from '../../Super-Admin/TenantOwner/components/EditTenantAdmin'
import TransactionsBanking from '../../Super-Admin/TransactionsBanking'
import Wallet from '../../../components/Sidebar/components/Wallet'
import Aggregators from '../../Super-Admin/Aggregators'
import CasinoCategory from '../../Super-Admin/CasinoCategory'
import CasinoSubCategory from '../../Super-Admin/CasinoSubCategory'
import RestrictedGames from '../../Super-Admin/RestrictedGames'
import RestrictedProviders from '../../Super-Admin/RestrictedProviders'
import RestrictProviderCountries from '../../Super-Admin/RestrictProviderCountries'
import RestrictGamesCountries from '../../Super-Admin/RestrictGamesCountries'
import CasinoTransactions from '../../Super-Admin/CasinoTransactions'
import CreateCMSNew from '../../Super-Admin/CMS/components/CreateCMSNew'
import EditCms from '../../Super-Admin/CMS/components/EditCms'
import FormFields from '../../Super-Admin/FormFields'
import CreateBonus from '../../Super-Admin/Bonus/components/CreateBonus'
import BonusManagement from '../../Super-Admin/Bonus'
import EditBonus from '../../Super-Admin/Bonus/components/EditBonus'
import KYCLabels from '../../Super-Admin/KYCLabels'
import CloneBonus from '../../Super-Admin/Bonus/components/CloneBonus'
import BonusDetail from '../../Super-Admin/Bonus/BonusDetail'
import WageringTemplate from '../../Super-Admin/WageringTemplate'
import WageringTeplateDetails from '../../Super-Admin/WageringTemplate/components/WageringTeplateDetails'
import EditWageringTemplate from '../../Super-Admin/WageringTemplate/components/EditWageringTemplate'
import CreateWageringTemplate from '../../Super-Admin/WageringTemplate/components/CreateWageringTemplate'
import ProfilePage from '../../../components/ProfilePage'
import LoyaltyPoints from '../../../components/LoyaltyPoints'
import EmailTemplate from '../../Super-Admin/EmailTemplate'
import EditEmailTemplate from '../../Super-Admin/EmailTemplate/editEmailTemplate'
import OwnerDetails from '../../Super-Admin/TenantOwner/components/OwnerDetails'
import Languages from '../../Super-Admin/Languages'
import ImageGallery from '../../Super-Admin/ImageGallery'
import BannerManagement from '../../Super-Admin/BannerManagement'
import LanguageManagement from '../../Super-Admin/Languages/components/LanguageManagement'
import LanguageWise from '../../../components/LanguageMgmt/components/LanguageWise'
import KeyWise from '../../../components/LanguageMgmt/components/KeyWise'
import AddLangauge from '../../../components/LanguageMgmt/components/AddLanguage'
import AddKeys from '../../../components/LanguageMgmt/components/AddKeys'
import CreateTenant from '../../Super-Admin/CreateTenant'
import CustomPayment from '../../../pages/Super-Admin/CustomPayment'
import EditProvider from '../../Super-Admin/CustomPayment/components/EditProvider'
import ReorderBonus from '../../../components/ReorderBonus'
import ReviewManagement from '../../Super-Admin/ReviewManagement'
import ManagersList from '../../Super-Admin/TenantOwner/components/ManagersList'
import BannedGames from '../../Super-Admin/BannedGames'
import CreateBannedGames from '../../Super-Admin/BannedGames/components/CreateBannedGames'
import EditBannedGames from '../../Super-Admin/BannedGames/components/EditBannedGames'
import BannedGamesDetails from '../../Super-Admin/BannedGames/components/BannedGamesDetails'
import CreateGroup from '../../../components/GroupManagement/components/CreateGroup'
import GroupManagement from '../../../components/GroupManagement'
import ProviderReorder from '../../Super-Admin/CustomPayment/components/ProviderReorder'
import Tournaments from '../../../components/Tournaments'
import CreateTournament from '../../../components/Tournaments/components/CreateTournament'
import EditBanners from '../../Super-Admin/BannerManagement/components/EditBanners'
import RGModule from '../../Super-Admin/RGModule'
import SetRGThresholds from '../../Super-Admin/RGModule/SetThresholds'
import SetAMLThresholds from '../../Super-Admin/AMLModule/setThresholds'
import AmlPlayerListing from '../../Super-Admin/AMLModule/playerListing'
import BannedUsers from '../../Super-Admin/BannedUsers'
import BannedPlayerSetting from '../../../components/BannedPlayerSetting'
import Hub88PrepaidList from '../../Super-Admin/Hub88PrepaidList'
import TenantCredentials from '../../Tenant/TenantCredentials'

const SuperAdminPages = () => (
  <>
    <Route
      path={SuperAdminRoutes.Wallet}
      element={
        <PrivateRoute>
          <Wallet />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.TenantOwner}
      element={
        <PrivateRoute module={{ Tenant: 'R' }}>
          <TenantOwner />
          {/* <Tenants /> */}
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.Tenants}
      element={
        <PrivateRoute module={{ Tenant: 'R' }}>
          <Tenants />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.CreateTenant}
      element={
        <PrivateRoute module={{ Tenant: 'C' }}>
          <CreateTenant />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.TenantDetails}
      element={
        <PrivateRoute module={{ Tenant: 'R' }}>
          <TenantDetails />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.Credentials}
      element={
        <PrivateRoute module={{ Settings: 'R' }}>
          <TenantCredentials />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.Admins}
      element={
        <PrivateRoute module={{ Admins: 'R' }}>
          <Admins />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.AdminDetails}
      element={
        <PrivateRoute module={{ Admins: 'R' }}>
          <AdminDetails />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.Themes}
      element={
        <PrivateRoute module={{ Themes: 'R' }}>
          <Themes />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.Currencies}
      element={
        <PrivateRoute module={{ Currencies: 'R' }}>
          <Currencies />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.Languages}
      element={
        <PrivateRoute module={{ MultiLanguage: 'R' }}>
          <Languages />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.CreateTheme}
      element={
        <PrivateRoute module={{ Themes: 'C' }}>
          <CreateTheme />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.CMSDetail}
      element={
        <PrivateRoute module={{ CMS: 'R' }}>
          <CMSDetail />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.EditTheme}
      element={
        <PrivateRoute module={{ Themes: 'U' }}>
          <EditTheme />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.CreateCurrencies}
      element={
        <PrivateRoute module={{ Currencies: 'C' }}>
          <CreateCurrencies />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.CreateTenantAdmin}
      element={
        <PrivateRoute module={{ Tenant: 'C' }}>
          <CreateTenantAdmin />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.CasinoGames}
      element={
        <PrivateRoute module={{ CasinoManagement: 'R' }}>
          <CasinoGames />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.CMS}
      element={
        <PrivateRoute module={{ CMS: 'R' }}>
          <Cms />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.CreateCMS}
      element={
        <PrivateRoute module={{ CMS: 'C' }}>
          <CreateCMSNew />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.BannedUsers}
      element={
        <PrivateRoute module={{ BannedPlayer: 'R' }}>
          <BannedUsers />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.Users}
      element={
        <PrivateRoute module={{ Users: 'R' }}>
          <Users />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.UserDetails}
      element={
        <PrivateRoute module={{ Users: 'R' }}>
          <UserDetails />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.EditTenant}
      element={
        <PrivateRoute module={{ Tenant: 'U' }}>
          <EditTenant />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.EditCurrency}
      element={
        <PrivateRoute module={{ Currencies: 'U' }}>
          <EditCurrency />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.Dashboard}
      element={
        <PrivateRoute isWithoutCard>
          <Dashboard />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.Credential}
      element={
        <PrivateRoute module={{ TenantCredentials: 'C' }}>
          <CreateCredential />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.CasinoProviders}
      element={
        <PrivateRoute module={{ CasinoManagement: 'R' }}>
          <CasinoProviders />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.Countries}
      element={
        <PrivateRoute module={{ RestrictedCountry: 'R' }}>
          <Countries />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.CreateSuperAdminUser}
      element={
        <PrivateRoute module={{ Admins: 'C' }}>
          <CreateSuperAdminUser />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.EditSuperAdminUser}
      element={
        <PrivateRoute module={{ Admins: 'U' }}>
          <EditSuperAdminUser />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.EditTenantAdmin}
      element={
        <PrivateRoute module={{ Tenant: 'U' }}>
          <EditTenantAdmin />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.TransactionsBanking}
      element={
        <PrivateRoute module={{ Transactions: 'R' }}>
          <TransactionsBanking />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.Aggregators}
      element={
        <PrivateRoute module={{ CasinoManagement: 'R' }}>
          <Aggregators />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.CasinoCategory}
      element={
        <PrivateRoute module={{ CasinoManagement: 'R' }}>
          <CasinoCategory />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.CasinoSubCategory}
      element={
        <PrivateRoute module={{ CasinoManagement: 'R' }}>
          <CasinoSubCategory />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.RestrictedGames}
      element={
        <PrivateRoute module={{ RestrictedCountry: 'U' }}>
          <RestrictedGames />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.RestrictedProviders}
      element={
        <PrivateRoute module={{ RestrictedCountry: 'U' }}>
          <RestrictedProviders />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.RestrictedProviderCountries}
      element={
        <PrivateRoute module={{ CasinoManagement: 'U' }}>
          <RestrictProviderCountries />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.RestrictedGameCountries}
      element={
        <PrivateRoute module={{ CasinoManagement: 'U' }}>
          <RestrictGamesCountries />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.ReviewManagement}
      element={
        <PrivateRoute module={{ Reviews: 'U' }}>
          <ReviewManagement />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.CasinoTransactions}
      element={
        <PrivateRoute module={{ Transactions: 'R' }}>
          <CasinoTransactions />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.EditCMS}
      element={
        <PrivateRoute module={{ CMS: 'U' }}>
          <EditCms />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.FormFields}
      element={
        <PrivateRoute module={{ RegistrationField: 'R' }}>
          <FormFields />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.CreateBonus}
      element={
        <PrivateRoute module={{ Bonus: 'C' }}>
          <CreateBonus />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.Bonus}
      element={
        <PrivateRoute module={{ Bonus: 'R' }}>
          <BonusManagement />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.EditBonus}
      element={
        <PrivateRoute module={{ Bonus: 'U' }}>
          <EditBonus />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.CloneBonus}
      element={
        <PrivateRoute module={{ Bonus: 'C' }}>
          <CloneBonus />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.BonusDetail}
      element={
        <PrivateRoute module={{ Bonus: 'R' }}>
          <BonusDetail />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.KYCLabels}
      element={
        <PrivateRoute module={{ KycLabel: 'R' }}>
          <KYCLabels />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.BannedGames}
      element={
        <PrivateRoute isWithoutHeader module={{ BannedGames: 'R' }}>
          <BannedGames />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.CreateBannedGames}
      element={
        <PrivateRoute module={{ BannedGames: 'C' }}>
          <CreateBannedGames />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.EditBannedGames}
      element={
        <PrivateRoute module={{ BannedGames: 'U' }}>
          <EditBannedGames />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.ClonedBannedGames}
      element={
        <PrivateRoute module={{ BannedGames: 'U' }}>
          <EditBannedGames />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.AddBannedGames}
      element={
        <PrivateRoute module={{ BannedGames: 'U' }}>
          <CreateBannedGames />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.AddClonedBannedGames}
      element={
        <PrivateRoute module={{ BannedGames: 'U' }}>
          <CreateBannedGames />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.BannedGamesDetail}
      element={
        <PrivateRoute module={{ BannedGames: 'R' }}>
          <BannedGamesDetails isGameListing />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.WageringTemplate}
      element={
        <PrivateRoute module={{ WageringTemplate: 'R' }}>
          <WageringTemplate />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.WageringEdit}
      element={
        <PrivateRoute module={{ WageringTemplate: 'U' }}>
          <EditWageringTemplate />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.WageringDetails}
      element={
        <PrivateRoute module={{ WageringTemplate: 'R' }}>
          <WageringTeplateDetails />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.CreateWagering}
      element={
        <PrivateRoute module={{ WageringTemplate: 'C' }}>
          <CreateWageringTemplate />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.EmailTemplate}
      element={
        <PrivateRoute module={{ EmailTemplate: 'R' }}>
          <EmailTemplate />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.EditEmailTemplate}
      element={
        <PrivateRoute module={{ EmailTemplate: 'U' }}>
          <EditEmailTemplate />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.Profile}
      element={
        <PrivateRoute>
          <ProfilePage isTenant={false} />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.Loyalty}
      element={
        <PrivateRoute module={{ LoyaltyManagement: 'R' }}>
          <LoyaltyPoints />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.TenantOwnerDetail}
      element={
        <PrivateRoute>
          <OwnerDetails />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.ImageGallery}
      element={
        <PrivateRoute module={{ ImageGallery: 'R' }}>
          <ImageGallery />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.BannerManagement}
      element={
        <PrivateRoute>
          <BannerManagement module={{ BannerManagement: 'R' }} />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.EditBanner}
      element={
        <PrivateRoute>
          <EditBanners module={{ BannerManagement: 'U' }} />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.ViewBanner}
      element={
        <PrivateRoute>
          <EditBanners module={{ BannerManagement: 'R' }} />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.CreateBanner}
      element={
        <PrivateRoute>
          <EditBanners module={{ BannerManagement: 'C' }} />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.LanguageManagement}
      element={
        <PrivateRoute module={{ MultiLanguage: 'R' }}>
          <LanguageManagement />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.EditSupportLanguage}
      element={
        <PrivateRoute module={{ MultiLanguage: 'U' }}>
          <LanguageWise />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.EditSupportLanguageKeys}
      element={
        <PrivateRoute module={{ MultiLanguage: 'U' }}>
          <KeyWise />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.AddLanguage}
      element={
        <PrivateRoute module={{ MultiLanguage: 'U' }}>
          <AddLangauge />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.AddSupportLanguageKeys}
      element={
        <PrivateRoute module={{ MultiLanguage: 'U' }}>
          <AddKeys />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.PaymentAggregators}
      element={
        <PrivateRoute module={{ CashierManagement: 'R' }}>
          <CustomPayment />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.EditCustomProviders}
      element={
        <PrivateRoute module={{ CashierManagement: 'U' }}>
          <EditProvider />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.ViewCustomProviders}
      element={
        <PrivateRoute module={{ CashierManagement: 'R' }}>
          <EditProvider />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.BonusReorder}
      element={
        <PrivateRoute module={{ Bonus: 'U' }}>
          <ReorderBonus />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.TOManagers}
      element={
        <PrivateRoute module={{ Tenant: 'R' }}>
          <ManagersList />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.ErrLanguageManagement}
      element={
        <PrivateRoute module={{ CashierManagement: 'R' }}>
          <LanguageManagement errorCodes />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.EditErrSupportLanguage}
      element={
        <PrivateRoute module={{ CashierManagement: 'U' }}>
          <LanguageWise errorCodes />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.EditErrSupportLanguageKeys}
      element={
        <PrivateRoute module={{ CashierManagement: 'U' }}>
          <KeyWise errorCodes />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.AddErrLanguage}
      element={
        <PrivateRoute module={{ CashierManagement: 'U' }}>
          <AddLangauge errorCodes />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.Groups}
      element={
        <PrivateRoute module={{ GroupPermission: 'R' }}>
          <GroupManagement />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.CreateGroup}
      element={
        <PrivateRoute module={{ GroupPermission: 'C' }}>
          <CreateGroup />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.EditGroup}
      element={
        <PrivateRoute module={{ GroupPermission: 'U' }}>
          <CreateGroup />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.ViewGroup}
      element={
        <PrivateRoute module={{ GroupPermission: 'R' }}>
          <CreateGroup />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.CashierReorder}
      element={
        <PrivateRoute module={{ CashierManagement: 'U' }}>
          <ProviderReorder />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.Tournaments}
      element={
        <PrivateRoute module={{ Tournament: 'R' }}>
          <Tournaments />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.CreateTournaments}
      element={
        <PrivateRoute module={{ Tournament: 'C' }}>
          <CreateTournament />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.EditTournaments}
      element={
        <PrivateRoute module={{ Tournament: 'U' }}>
          <CreateTournament type='edit' />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.ViewTournament}
      element={
        <PrivateRoute module={{ Tournament: 'R' }}>
          <CreateTournament type='view' />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.CloneTournament}
      element={
        <PrivateRoute module={{ Tournament: 'C' }}>
          <CreateTournament type='clone' />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.GamblingPlayerListing}
      element={
        <PrivateRoute module={{ RG: 'R' }}>
          <RGModule key='rg' />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.SetThresholds}
      element={
        <PrivateRoute module={{ RG: 'U' }}>
          <SetRGThresholds />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.GamblingSEPlayerListing}
      element={
        <PrivateRoute module={{ RG: 'R' }}>
          <RGModule key='rg-self' selfExcluded />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.SetAMLThresholds}
      element={
        <PrivateRoute module={{ AML: 'U' }}>
          <SetAMLThresholds />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.AMLPlayerListing}
      element={
        <PrivateRoute module={{ AML: 'R' }}>
          <AmlPlayerListing />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.BannedPlayerSetting}
      element={
        <PrivateRoute module={{ BannedPlayer: 'R' }}>
          <BannedPlayerSetting isTenant={false} />
        </PrivateRoute>
      }
    />
    <Route
      path={SuperAdminRoutes.Hub88PrepaidList}
      element={
        <PrivateRoute module={{ Bonus: 'R' }}>
          <Hub88PrepaidList isTenant={false} />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.CountryTranslationLangWise}
      element={
        <PrivateRoute module={{ RestrictedCountry: 'U' }}>
          <LanguageWise />
        </PrivateRoute>
      }
    />

    <Route
      path={SuperAdminRoutes.CountryTranslation}
      element={
        <PrivateRoute module={{ RestrictedCountry: 'U' }}>
          <KeyWise />
        </PrivateRoute>
      }
    />
  </>
)

export default SuperAdminPages
