// Trigger.js
import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

const TooltipTrigger = ({ message, children }) => {
  return (
    <OverlayTrigger
      placement='top' // You can change this to 'bottom', 'left', 'right' based on your preference
      overlay={<Tooltip id='tooltip-top'>{message}</Tooltip>}
    >
      {children}
    </OverlayTrigger>
  )
}

export default TooltipTrigger
