import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { addDepositToOtherStart, removeMoneyStart } from '../../../store/redux-slices/tenants'
import { getWalletDataStart } from '../../../store/redux-slices/fetchData'
import { useEffect } from 'react'

const useManageMoney = ({ isTenant, user, show }) => {
  const dispatch = useDispatch()
  const { userId } = useParams()

  const fetchWalletData = (fetch) => {
    (fetch
      ? true
      : user?.userWallet?.ownerId !== user?.userId) && show &&
    dispatch(
      getWalletDataStart({
        userId: user?.userId,
        isTenant,
        tenantId: user?.tenantId
      })
    )
  }

  useEffect(() => {
    fetchWalletData()
  }, [user?.userId, show])

  const deposit = (data) => {
    const addAmount = parseFloat(data?.addAmount?.toFixed(2))
    const walletType = data?.walletType === 'cash' ? 'CASH' : 'NONCASH'
    const payload = {
      data: {
        [data?.transactionType === 'add-money' ? 'addAmount' : 'removeAmount']: addAmount,
        walletType,
        userId
      },
      isTenant
    }

    if (data?.transactionType === 'add-money') {
      dispatch(addDepositToOtherStart(payload))
    } else {
      dispatch(removeMoneyStart(payload))
    }
  }

  return {
    deposit
  }
}

export default useManageMoney
