import { useEffect, useState } from 'react'
import useClientFilter from '../../../../components/ClientFilter/useClientFilter'
import { commonDateFormat } from '../../../../utils/dateFormatter'
import { useSelector } from 'react-redux'
import usePortalFilter from '../../../../components/PortalFilter/usePortalFilter'

const useBannedPlayerForm = (selectedBannedPlayer, isTenant) => {
  // States for form values
  const [selectedOwner, setSelectedOwner] = useState(
    selectedBannedPlayer?.adminId || ''
  )
  const [selectedPortal, setSelectedPortal] = useState(null)

  const [selectedBanOption, setSelectedBanOption] = useState('')

  const { tenantDetails } = useSelector((state) => state.login)

  const { tenantsList } = useSelector((state) => state.tenants)

  const addBannedUserInitialValues = {
    bannedType: '',
    ownerId: '',
    tenantIds: [],
    firstName: '',
    lastName: '',
    email: '',
    dateOfBirth: ''
  }

  const updateBannedUserInitialValues = {
    bannedPlayerId: selectedBannedPlayer?.id || '',
    firstName: selectedBannedPlayer?.firstName || '',
    lastName: selectedBannedPlayer?.lastName || '',
    email: selectedBannedPlayer?.email || '',
    dateOfBirth: commonDateFormat(selectedBannedPlayer?.dateOfBirth) || ''
  }

  // Fetch client and portal data
  const { clientsFilterData } = useClientFilter({ refetch: true, isTenant })

  const { portalsFilterData } = usePortalFilter(selectedOwner, isTenant)

  // const { portalOptions } = useMultiPortalSelection(selectedOwner, isTenant)

  // Owner options for the dropdown
  const ownerOptions = clientsFilterData?.rows.map((admin) => ({
    label: `${admin.firstName} ${admin.lastName}`,
    value: admin.adminUserId
  }))

  const portalOptions = isTenant
    ? tenantsList?.rows.map((portal) => ({
      label: portal.name,
      value: portal.tenantId
    }))
    : portalsFilterData?.rows.map((portal) => ({
      label: portal.name,
      value: portal.tenantId
    }))

  useEffect(() => {
    if (selectedBannedPlayer?.adminId && !isTenant) {
      setSelectedBanOption('ban_across_license')
    }
  }, [])

  useEffect(() => {
    if (isTenant) {
      setSelectedOwner(tenantDetails.adminUserId)
      setSelectedBanOption('ban_across_multiple_brands')
    }
  }, [])

  useEffect(() => {
    if (!selectedBannedPlayer?.adminId && selectedBannedPlayer.tenantId) {
      setSelectedPortal(selectedBannedPlayer.tenant.name)
    }
  }, [])

  return {
    selectedOwner,
    setSelectedOwner,
    selectedPortal,
    setSelectedPortal,
    selectedBanOption,
    setSelectedBanOption,
    addBannedUserInitialValues,
    updateBannedUserInitialValues,
    ownerOptions,
    portalOptions,
    clientsFilterData
  }
}

export default useBannedPlayerForm
