import React, { useEffect, useState } from 'react'
import { Button, Col, Form, InputGroup, Modal, Row, Table } from '@themesberg/react-bootstrap'
import { useSelector } from 'react-redux'
import './modalStyle.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy } from '@fortawesome/pro-regular-svg-icons'
import Trigger from '../OverlayTrigger'
import { toast } from '../Toast'
import PaginationComponent from '../Pagination'
import { commonDateFormat, getDateTime } from '../../utils/dateFormatter'
import CopyToClipboard from 'react-copy-to-clipboard'
import { kycMethods } from './constants'
import { faEye, faEyeSlash } from '@fortawesome/pro-solid-svg-icons'
import { ErrorMessage, Formik } from 'formik'
import { passwordValidation } from './schema'
import Select from 'react-select'
import { checkIsTenant } from '../../utils/constants'

export const UpdateKycMethodModal = ({ show, setShow, countryData, setCountryData, handleKycUpdateMethod, languages }) => (
  <Modal show={show} onHide={() => setShow(false)}>
    <Modal.Header className='justify-content-center'>
      <h5>Update Country Data</h5>
    </Modal.Header>
    <Modal.Body>
      <Table borderless>
        <tbody>
          <tr>
            <td>
              <b>Country Name</b>
            </td>
            <td>
              {countryData?.countryName?.EN}
            </td>
          </tr>
          <tr>
            <td>
              <b>Kyc Method</b>
            </td>
            <td>
              <Form.Select
                size='sm'
                className='ml-2'
                style={{ maxWidth: '230px' }}
                value={countryData?.kycMethod}
                onChange={(e) => {
                  setCountryData((prev) => ({ ...prev, kycMethod: e.target.value }))
                }}
              >
                {kycMethods?.map(
                  ({ label, value }) => (
                    <option key={value} value={value}>
                      {label}
                    </option>
                  )
                )}
              </Form.Select>
            </td>
          </tr>

          {!checkIsTenant() &&
            <tr>
              <td>
                <b>Language</b>
              </td>
              <td>
                <Form.Select
                  size='sm'
                  className='ml-2'
                  style={{ maxWidth: '230px' }}
                  value={countryData?.languageId}
                  onChange={(e) => {
                    setCountryData((prev) => ({ ...prev, languageId: e.target.value }))
                  }}
                >
                  {languages?.count > 0 &&
                  languages?.rows?.map(
                    ({ languageId, languageName }) => (
                      <option key={languageId} value={languageId}>
                        {languageName}
                      </option>
                    )
                  )}
                </Form.Select>
              </td>
            </tr>}
        </tbody>
      </Table>
    </Modal.Body>

    <Modal.Footer className='justify-content-between'>
      <Button variant='outline-warning' onClick={() => { setShow(false) }}>
        Cancel
      </Button>

      <Button
        variant='outline-success' onClick={() => {
          handleKycUpdateMethod({ countryData })
        }}
      >
        Confirm
      </Button>
    </Modal.Footer>
  </Modal>
)

export default ({ show, setShow, handleYes, active, name }) => (
  <Modal show={show} onHide={() => setShow(false)}>

    <Modal.Body>
      <div className='fs-5'>
        Are you sure you want to mark {name || ''} {active ? 'Active' : 'In-Active'}?
      </div>
    </Modal.Body>

    <Modal.Footer>
      <Button variant='outline-secondary' onClick={handleYes}>
        Yes
      </Button>

      <Button variant='outline-primary' onClick={() => setShow(false)}>
        No
      </Button>
    </Modal.Footer>
  </Modal>
)

export const DeleteConfirmationModal = ({
  deleteModalShow,
  setDeleteModalShow,
  handleDeleteYes,
  name
}) => (
  <Modal show={deleteModalShow} onHide={() => setDeleteModalShow(false)}>

    <Modal.Body>
      <div className='fs-5'>
        Are you sure you want to delete {name || ''}?
      </div>
    </Modal.Body>

    <Modal.Footer>
      <Button variant='outline-secondary' onClick={handleDeleteYes}>
        Yes
      </Button>

      <Button variant='outline-primary' onClick={() => setDeleteModalShow(false)}>
        No
      </Button>
    </Modal.Footer>
  </Modal>
)

export const ApproveRejectModal = ({
  show,
  setShow,
  handleYes,
  status,
  name
}) => (
  <Modal show={show} onHide={() => setShow(false)}>

    <Modal.Body>
      <div className='fs-5'>
        Are you sure you want to {status === 'approved' ? 'Approve ' : 'Cancel-ReRequested '} {name}?
      </div>
    </Modal.Body>

    <Modal.Footer>
      <Button
        variant='outline-secondary' onClick={() => {
          if (status === 'approved') { handleYes('', '') } else { handleYes('', 'cancel') }
        }}
      >
        Yes
      </Button>

      <Button variant='outline-primary' onClick={() => setShow(false)}>
        No
      </Button>
    </Modal.Footer>
  </Modal>
)

export const GalleryModal = ({
  galleryModal,
  setGalleryModal
}) => {
  const { gallery } = useSelector(state => state.emailTemplate)
  const [tabWiseFilter, setTabWiseFilter] = useState('')
  const [myGallery, setMyGallery] = useState([])

  const getColor = (text) => {
    if (text === 'COMMON') return 'blue'
    if (text === 'ADMIN') return 'red'
    return 'green'
  }

  useEffect(() => {
    setMyGallery(!tabWiseFilter ? gallery : gallery?.filter((data) => tabWiseFilter === 'common' ? !data?.imageBucketType : data?.imageBucketType === tabWiseFilter))
  }, [gallery, tabWiseFilter])

  return (
    <Modal show={galleryModal} onHide={() => setGalleryModal(false)} size='lg'>
      <Modal.Header closeButton>
        <Modal.Title>Gallery</Modal.Title>
        <Col className='d-flex justify-content-end'>
          <Form.Select
            type='select'
            size='sm'
            style={{ width: '220px', right: '0px' }}
            name='tabWiseFilter'
            value={tabWiseFilter}
            onChange={(e) => {
              setTabWiseFilter(e.target.value)
            }}
          >
            <option key='' value=''>All</option>
            <option key='common' value='common'>Common</option>
            <option key='admin' value='admin'>Admin</option>
            <option key='user' value='user'>User</option>
          </Form.Select>
        </Col>
      </Modal.Header>

      <Modal.Body>
        <Row className='text-center align-items-center'>
          {myGallery?.length > 0
            ? myGallery?.map((img, index) => {
              return (
                <Col key={index} md={3} className='imagecontainer'>

                  <div className='wrapper'>
                    <div className='ribbon-wrapper-green'>
                      <div className='ribbon-green' style={{ backgroundColor: getColor(img?.imageBucketType?.toUpperCase() || 'COMMON') }}>{img?.imageBucketType?.toUpperCase() || 'COMMON'}</div>
                    </div>
                  </div>

                  <CopyToClipboard
                    text={img?.imageUrl}
                    onCopy={() => {
                      setGalleryModal(false)
                      toast('Copied To ClipBoard', 'success')
                    }}
                  >
                    <img
                      src={(img?.imageBucketType === 'user' ? process.env.REACT_APP_CDN_IMAGE_HEADER : process.env.REACT_APP_S3_IMAGE_HEADER) + img?.imageUrl}
                      width='200'
                      height='150'
                      alt='gallery-img'
                      style={{ border: '2px solid aliceblue', borderRadius: '12px', cursor: 'pointer' }}
                      className='mb-2'
                    />
                  </CopyToClipboard>
                  <div className='text'>{img?.name}</div>
                  <Trigger message='Copy This Image URL to ClipBoard'>
                    <CopyToClipboard
                      text={(img?.imageBucketType === 'user' ? process.env.REACT_APP_CDN_IMAGE_HEADER : process.env.REACT_APP_S3_IMAGE_HEADER) + img?.imageUrl}
                      onCopy={() => {
                        setGalleryModal(false)
                        toast('Copied To ClipBoard', 'success')
                      }}
                    >
                      <Button
                        className='copy d-flex align-items-center'
                        variant='light'
                      >
                        <FontAwesomeIcon icon={faCopy} />
                      </Button>
                    </CopyToClipboard>
                  </Trigger>
                </Col>
              )
            })
            : <h4 className='text-danger'>No Image Found</h4>}
        </Row>
      </Modal.Body>

    </Modal>
  )
}

export const DuplicateUserModal = ({
  data = null,
  show,
  setShow,
  limit,
  pageNo,
  setLimit,
  setPageNo
}) => {
  const { duplicateUsers, user } = useSelector(state => state.tenantUsers)
  const { userData } = useSelector((state) => state.fetch)
  const totalPages = Math.ceil(duplicateUsers?.count / limit)
  const myUser = user || userData || data
  const tableHeaders = [
    { label: 'User Id', value: myUser?.userId || 'NA' },
    { label: 'First Name', value: myUser?.firstName || 'NA' },
    { label: 'Last Name', value: myUser?.lastName || 'NA' },
    { label: 'Email', value: myUser?.email || 'NA' },
    { label: 'User Name', value: myUser?.username || 'NA' },
    { label: 'Phone', value: myUser?.phone || 'NA' },
    { label: 'SignIn IP', value: myUser?.signInIp || 'NA' },
    { label: 'DOB', value: myUser?.dateOfBirth ? commonDateFormat(myUser?.dateOfBirth) : 'NA' }
  ]

  return (
    <Modal show={show} onHide={() => setShow(false)} size='xl'>
      <Modal.Header closeButton>
        <Modal.Title className='fs-2'>Duplicates</Modal.Title>
      </Modal.Header>

      <Modal.Body>

        <div style={{ overflow: 'auto', maxHeight: '640px' }}>
          <Table
            bordered
            size='sm'
            className='text-center fixTableHead'
          >
            <thead>
              <tr className='thead-dark fixTableHead-th'>
                {tableHeaders.map((h, idx) => (
                  <th key={`T-table_heading ${idx}`}>
                    <span>{h.label}</span><br />
                    <small>({h.value})</small>
                  </th>
                ))}
              </tr>
            </thead>

            <tbody>
              {duplicateUsers &&
                duplicateUsers?.rows?.map(
                  ({
                    firstName,
                    lastName,
                    email,
                    username,
                    phone,
                    signInIp,
                    dateOfBirth,
                    address,
                    userId
                  }) => {
                    return (
                      <tr key={`duplicate-list ${userId}`}>
                        <td>{userId}</td>
                        <td>{firstName || 'NA'}</td>
                        <td>{lastName || 'NA'}</td>
                        <td>{email || 'NA'}</td>
                        <td>{username || 'NA'}</td>
                        <td>{phone || 'NA'}</td>
                        <td>{signInIp || 'NA'}</td>
                        <td>{dateOfBirth ? commonDateFormat(dateOfBirth) : 'NA'}</td>
                      </tr>
                    )
                  }
                )}
              {duplicateUsers?.count === 0 && (
                <tr>
                  <td colSpan={9} className='text-danger text-center'>
                    No data found
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>

        {duplicateUsers?.count > 0 && (
          <PaginationComponent
            page={duplicateUsers?.count < pageNo ? setPageNo(1) : pageNo}
            totalPages={totalPages}
            setPage={setPageNo}
            limit={limit}
            setLimit={setLimit}
          />
        )}

      </Modal.Body>
    </Modal>
  )
}

export const ResetConfirmationModal = ({
  show,
  setShow,
  handleYes,
  data
}) => (
  <Modal show={show} onHide={() => setShow(false)}>

    <Modal.Body>
      <div className='fs-5'>
        Are you sure you want to reset {data || ''}?
      </div>
    </Modal.Body>

    <Modal.Footer>
      <Button variant='outline-secondary' onClick={() => handleYes(data)}>
        Yes
      </Button>

      <Button variant='outline-primary' onClick={() => setShow(false)}>
        No
      </Button>
    </Modal.Footer>
  </Modal>
)

export const ChangeLangConfirmationModal = ({
  show,
  setShow,
  handleYes
}) => (
  <Modal show={show} onHide={() => setShow(false)}>
    <Modal.Header closeButton>
      <Modal.Title>Are you sure?</Modal.Title>
    </Modal.Header>

    <Modal.Body>
      <h6>Switching Language would discard all the changes that you made.</h6>
      <h6>Are you sure you want to switch to other language?</h6>
    </Modal.Body>

    <Modal.Footer>
      <Button variant='outline-secondary' onClick={() => handleYes()}>
        Yes
      </Button>

      <Button variant='outline-primary' onClick={() => setShow(false)}>
        No
      </Button>
    </Modal.Footer>
  </Modal>
)

export const VerifyEmailModal = ({
  show,
  setShow,
  userData,
  handleVerifyEmail
}) => {
  return (
    <Modal show={show}>
      <Modal.Header>
        <h4>Mark '{userData?.firstName} {userData?.lastName} ({userData?.email || '-'})' as Verified
        </h4>
      </Modal.Header>
      <Modal.Body>
        <p>Do you really want to mark <strong>{userData?.firstName} {userData?.lastName} ({userData?.email})</strong> as Verified?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className='m-2'
          variant='outline-warning'
          onClick={() => {
            setShow(false)
          }}
        >
          No
        </Button>
        <Button
          className='m-2'
          variant='outline-success'
          style={{ width: '75px' }}
          onClick={handleVerifyEmail}
        >
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export const ResetPasswordEmailModal = ({
  show,
  setShow,
  userData,
  handleResetPasswordEmail
}) => {
  return (
    <Modal show={show}>
      <Modal.Header>
        <h4>Send Password Reset Email to '{userData?.firstName} {userData?.lastName} ({userData?.email || '-'})'
        </h4>
      </Modal.Header>
      <Modal.Body>
        <p>Are you sure you want to send  a password reset email to <strong>{userData?.firstName} {userData?.lastName} ({userData?.email})</strong>?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className='m-2'
          variant='outline-warning'
          onClick={() => {
            setShow(false)
          }}
        >
          No
        </Button>
        <Button
          className='m-2'
          variant='outline-success'
          style={{ width: '75px' }}
          onClick={handleResetPasswordEmail}
        >
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export const ResetUserPasswordModal = ({
  show,
  setShow,
  userData,
  handleResetUserPassword
}) => {
  const [type, setType] = useState('password')

  return (
    <Formik
      initialValues={{
        password: ''
      }}
      validationSchema={passwordValidation}
      onSubmit={(formValues) => {
        handleResetUserPassword({
          data: {
            password: Buffer.from(formValues?.password).toString('base64'),
            userId: userData?.userId
          }
        })
      }}
    >
      {({ values, handleChange, handleSubmit }) => (
        <Form className='align-items-center mt-3'>
          <Modal show={show}>
            <Modal.Header>
              <h4>Reset Password for '{userData?.firstName} {userData?.lastName} ({userData?.email || '-'})'
              </h4>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col>
                  <Form.Label>
                    Password <span className='text-danger'> *</span>
                  </Form.Label>
                  <InputGroup>
                    <Form.Control
                      name='password'
                      type={type}
                      placeholder='Enter Password'
                      value={values?.password}
                      onChange={handleChange}
                    />
                    <InputGroup.Text style={{ border: '0.0625rem solid #d1d7e0' }}>
                      {type === 'password'
                        ? <FontAwesomeIcon icon={faEye} style={{ cursor: 'pointer' }} onClick={() => { setType('text') }} />
                        : <FontAwesomeIcon icon={faEyeSlash} style={{ cursor: 'pointer' }} onClick={() => { setType('password') }} />}
                    </InputGroup.Text>
                  </InputGroup>
                  <ErrorMessage
                    component='div'
                    name='password'
                    className='text-danger'
                  />
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button
                className='m-2'
                variant='outline-warning'
                onClick={() => {
                  setShow(false)
                }}
              >
                No
              </Button>
              <Button
                className='m-2'
                variant='outline-success'
                style={{ width: '75px' }}
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </Modal.Footer>
          </Modal>
        </Form>)}
    </Formik>
  )
}

export const PortalDetailsModal = ({
  data = null,
  show,
  setShow,
  limit,
  pageNo,
  setLimit,
  setPageNo
}) => {
  const { excludedPortalDetails } = useSelector(state => state.RGModule)
  const totalPages = Math.ceil(excludedPortalDetails?.count / limit)
  const myUser = excludedPortalDetails?.rows?.filter(userId => userId !== data?.userId)

  const tableHeaders = [
    { label: 'User Id', value: myUser && (myUser[0]?.userId || 'NA') },
    { label: 'First Name', value: myUser && (myUser[0]?.firstName || 'NA') },
    { label: 'Last Name', value: myUser && (myUser[0]?.lastName || 'NA') },
    { label: 'Email', value: myUser && (myUser[0]?.email || 'NA') },
    { label: 'User Name', value: myUser && (myUser[0]?.username || 'NA') },
    { label: 'Portal Name', value: myUser && (myUser[0]?.tenantName || 'NA') },
    { label: 'Exclusion Date', value: myUser && ((myUser[0]?.exclusionDate) ? getDateTime(myUser[0]?.exclusionDate) : myUser[0]?.exclusionUpdatedDate ? (getDateTime(myUser[0]?.exclusionUpdatedDate)) : 'NA') }
  ]

  return (
    <Modal show={show} onHide={() => setShow(false)} size='xl'>
      <Modal.Header closeButton>
        <Modal.Title className='fs-2'>Self Exclusion</Modal.Title>
      </Modal.Header>

      <Modal.Body>

        <div style={{ overflow: 'auto', maxHeight: '640px' }}>
          <Table
            bordered
            size='sm'
            className='text-center fixTableHead'
          >
            <thead>
              <tr className='thead-dark fixTableHead-th'>
                {tableHeaders.map((h, idx) => (
                  <th key={`T-table_heading ${idx}`}>
                    <span>{h.label}</span><br />
                    <small>({h.value})</small>
                  </th>
                ))}
              </tr>
            </thead>

            <tbody>
              {excludedPortalDetails &&
                excludedPortalDetails?.rows?.map(
                  ({
                    firstName,
                    lastName,
                    email,
                    username,
                    userId,
                    tenantName,
                    exclusionDate,
                    exclusionUpdatedDate
                  }) => {
                    return (
                      <tr key={`excludedPortalDetails-list ${userId}`}>
                        <td>{userId}</td>
                        <td>{firstName || 'NA'}</td>
                        <td>{lastName || 'NA'}</td>
                        <td>{email || 'NA'}</td>
                        <td>{username || 'NA'}</td>
                        <td>{tenantName || 'NA'}</td>
                        <td>{exclusionDate ? getDateTime(exclusionDate) : exclusionUpdatedDate ? getDateTime(exclusionUpdatedDate) : 'NA'}</td>
                      </tr>
                    )
                  }
                )}
              {excludedPortalDetails?.count === 0 && (
                <tr>
                  <td colSpan={7} className='text-danger text-center'>
                    No data found
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>

        {excludedPortalDetails?.count > 0 && (
          <PaginationComponent
            page={excludedPortalDetails?.count < pageNo ? setPageNo(1) : pageNo}
            totalPages={totalPages}
            setPage={setPageNo}
            limit={limit}
            setLimit={setLimit}
          />
        )}

      </Modal.Body>
    </Modal>
  )
}

export const CreateLanguageModal = ({
  show,
  setShow,
  handleCreate,
  languageOptions
}) => {
  return (
    <Formik
      initialValues={{
        languageCode: []
      }}
      onSubmit={(formValues) => {
        handleCreate(formValues?.languageCode)
      }}
    >
      {({ values, handleSubmit, setFieldValue }) => (
        <Form className='align-items-center mt-3'>
          <Modal show={show}>
            <Modal.Header>
              <h4>Create Languages</h4>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col>
                  <Form.Label>
                    Langauges <span className='text-danger'> *</span>
                  </Form.Label>

                  <Select
                    isMulti
                    isClearable={false}
                    name='languageCode'
                    value={values?.languageCode}
                    options={languageOptions}
                    className='basic-multi-select'
                    classNamePrefix='select'
                    onChange={(option, e) => setFieldValue('languageCode', option)}
                  />
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button
                className='m-2'
                variant='outline-warning'
                onClick={() => {
                  setShow(false)
                }}
              >
                No
              </Button>
              <Button
                className='m-2'
                variant='outline-success'
                style={{ width: '75px' }}
                hidden={values?.languageCode?.length < 1}
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </Modal.Footer>
          </Modal>
        </Form>)}
    </Formik>
  )
}
