import { toast } from '../components/Toast'
import { getLoginToken } from './storageUtils'

export const downloadFile = (url) => {
  const element = document.createElement('a')
  element.setAttribute('href', url)

  element.style.display = 'none'
  document.body.appendChild(element)

  element.click()
  document.body.removeChild(element)

  toast('CSV downloaded', 'success')
}

async function onDownloadCsvClick (downloadURL, fileName) {
  const apiUrl = downloadURL
  const response = await fetch(apiUrl, {
    method: 'GET',
    credentials: 'include',
    headers: {
      Authorization: `Bearer ${getLoginToken()}`
    }
  })
  if (!response.ok) {
    toast('Failed to fetch data from the server', 'error')
    return
  }

  const reader = response.body.getReader()
  const chunks = []

  let result
  while (!(result = await reader.read()).done) {
    const { value } = result
    chunks.push(value)
  }

  // Concatenate the chunks into a single Blob
  const blob = new Blob(chunks, { type: 'text/csv' })

  // Create and download the CSV file
  const link = document.createElement('a')
  link.href = URL.createObjectURL(blob)
  link.download = `${fileName}_${new Date()}.csv`
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)

  toast('CSV downloaded successfully', 'success')
}

export const handleDownloadCSV = async (csvUrl, title) => {
  try {
    //   setDownloadInProgress(true);
    const url = csvUrl
    toast('Download started in background', 'success')
    await onDownloadCsvClick(url, title)
  } catch (error) {
    console.error('Error downloading CSV', error)
  } finally {
    //   setDownloadInProgress(false);
  }
}
