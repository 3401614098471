import React from 'react'
import useSessionData from '../hooks/useSessionData'
import Trigger from '../../../../components/OverlayTrigger'
import { downloadFile } from '../../../../utils/fileDownloader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PaginationComponent from '../../../../components/Pagination'
import DateRangePicker from '../../../../components/DateRangePicker'
import { currencyCodeList } from '../../../../utils/currencyCodeList'
import { Button, Col, Row, Form, Table } from '@themesberg/react-bootstrap'
import { faFileDownload, faRedoAlt } from '@fortawesome/pro-solid-svg-icons'
import { getDateDaysAgo, getDateTime } from '../../../../utils/dateFormatter'
import CustomDatePicker from '../../../../components/Custom/CustomDatePicker'

const UserSessionData = ({
  page,
  setPage,
  limit,
  setLimit,
  search,
  setSearch,
  state,
  setState,
  user
}) => {
  const {
    loading,
    sessionLogs,
    totalPages,
    getCsvDownloadUrl
  } = useSessionData({ limit, page, search, state })

  const currCode = currencyCodeList[user?.currencyCode] || user?.currencyCode

  const tableHeaders = ['Start', 'End', 'End Reason', 'Start Balance', 'Start Bonus Balance', 'End Balance', 'End Bonus Balance', 'Game Sessions', 'Game Rounds', 'Total bets', 'Total wins', 'Income', 'Max cash bet', 'Max bet bonus', 'IP', 'Device Type', 'OS', 'Browser']

  return (
    <>
      <Row>
        <Col xs='auto' className='d-flex mb-3'>
          <Form.Label column='sm' className='mx-auto text-nowrap px-2'>
            Time Period
          </Form.Label>
          {/* <DateRangePicker className='mb-3' state={state} setState={setState} transaction bonus /> */}
          <CustomDatePicker onDateChange={setState} date={state} />
        </Col>
        <Col xs='auto' className='d-flex mb-3'>
          <Trigger message='Reset Filters'>
            <Button
              variant='outline-success'
              className=''
              size='sm'
              onClick={() => {
                setSearch('')
                setLimit(15)
                setPage(1)
                setState([{
                  startDate: getDateDaysAgo(0),
                  endDate: new Date(),
                  key: 'selection'
                }])
              }}
            >
              <FontAwesomeIcon icon={faRedoAlt} />
            </Button>
          </Trigger>
          <Trigger message='Download as CSV'>
            <Button
              variant='outline-success'
              size='sm'
              style={{ marginLeft: '10px' }}
              disabled={sessionLogs?.count <= 0}
              onClick={() => { downloadFile(getCsvDownloadUrl()) }}
            >
              <FontAwesomeIcon icon={faFileDownload} />
            </Button>
          </Trigger>
        </Col>

      </Row>
      <Table bordered striped responsive hover size='sm' className='text-center mt-4 session-table'>
        <thead className='thead-dark'>
          <tr>
            {tableHeaders.map((h, idx) => (
              <th key={`T-table_heading ${idx}`}>
                {h}
              </th>
            ))}
          </tr>
        </thead>

        <tbody id={loading ? 'cover-spin' : ''}>
          {sessionLogs && !loading &&
            sessionLogs?.rows?.map(
              ({
                id,
                startTime,
                endTime,
                sessionEndReason,
                startCashBalance,
                startNonCashBalance,
                endCashBalance,
                endNonCashBalance,
                gameSessions,
                gameRound,
                totalBetAmount,
                totalWinAmount,
                maxBetCashAmount,
                maxBetNonCashAmount,
                browserType,
                osType,
                ip,
                deviceType
              }) => {
                return (
                  <tr key={`audit-list ${id}`}>
                    <td>{startTime ? getDateTime(startTime) : '-'}</td>
                    <td>{endTime ? getDateTime(endTime) : '-'}</td>
                    <td>{sessionEndReason || 'NA'}</td>
                    <td>{currCode} {startCashBalance?.toFixed(2) || 0}</td>
                    <td>{currCode} {startNonCashBalance?.toFixed(2) || 0}</td>
                    <td>{currCode} {endCashBalance?.toFixed(2) || 0}</td>
                    <td>{currCode} {endNonCashBalance?.toFixed(2) || 0}</td>
                    <td>{gameSessions || '0'}</td>
                    <td>{gameRound || '0'}</td>
                    <td className={totalBetAmount > 0 ? 'text-success' : 'text-danger'}>{currCode} {totalBetAmount?.toFixed(2) || 0}</td>
                    <td className={totalWinAmount > 0 ? 'text-success' : 'text-danger'}>{currCode} {totalWinAmount?.toFixed(2) || 0}</td>
                    <td className={totalWinAmount < totalBetAmount ? 'text-success' : 'text-danger'}>{totalWinAmount > totalBetAmount ? '-' : ''} {currCode} {Math.abs((totalWinAmount || 0) - (totalBetAmount || 0))?.toFixed(2)}</td>
                    <td className='text-success'>{currCode} {maxBetCashAmount?.toFixed(2) || 0}</td>
                    <td className='text-success'>{currCode} {maxBetNonCashAmount?.toFixed(2) || 0}</td>
                    <td className='text-capitalize'>{ip || 'NA'}</td>
                    <td className='text-capitalize'>{deviceType || 'NA'}</td>
                    <td className='text-capitalize'>{osType || 'NA'}</td>
                    <td className='text-capitalize'>{browserType || 'NA'}</td>
                  </tr>
                )
              })}

          {(sessionLogs?.count === 0 || !sessionLogs) && !loading && (
            <tr>
              <td colSpan={20} className='text-danger text-center'>
                No data found
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      {sessionLogs?.count > 0 && !loading && (
        <PaginationComponent
          page={sessionLogs?.count < page ? setPage(1) : page}
          totalPages={totalPages}
          setPage={setPage}
          limit={limit}
          setLimit={setLimit}
        />
      )}
    </>
  )
}

export default React.memo(UserSessionData)
