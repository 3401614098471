export const kycLabels = [
  { value: '', label: 'All' },
  { value: 'APPROVED', label: 'Approved' },
  { value: 'PENDING', label: 'Pending' },
  { value: 'REJECTED', label: 'Rejected' },
  { value: 'REQUESTED', label: 'Requested' },
  { value: 'RE_REQUESTED', label: 'Re Requested' }
]

export const accountStatus = [
  { value: '', label: 'All' },
  { value: true, label: 'Active' },
  { value: false, label: 'In-Active' },
  { value: 'rg_underaged', label: 'Rg Underaged' },
  { value: 'aml_under_review', label: 'Rg Under Review' },
  { value: 'enforce_self_ex', label: 'Enforced Self Ex' },
  { value: 'location_ban', label: 'Location Ban' },
  { value: 'TAKE_A_BREAK', label: 'Frozen' },
  { value: 'gambling_issues', label: 'Gambling Issues' },
  { value: 'self_excluded', label: 'Self Excluded' },
  { value: 'SELF_EXCLUSION_PERMANENT', label: 'Self Exclusion Permanent' }
]

export const tableHeaders = [
  { label: 'Id', value: 'userId' },
  { label: 'UserName', value: 'username' },
  { label: 'Registration Date', value: 'regDate' },
  { label: 'Cash Balance', value: 'cashBalance' },
  { label: 'Bonus Balance', value: 'bonusBalance' },
  { label: 'Country', value: 'country' },
  { label: 'Pending w/d', value: 'pending' },
  { label: 'Total w/d', value: 'total' },
  { label: 'Status', value: 'status' },
  { label: 'kyc status', value: 'kycStatus' },
  { label: 'affiliate name', value: 'affiliate name' },
  { label: 'action', value: 'action' }
]

export const playerTypes = [
  { value: 'all', label: 'All' },
  { value: 'allDepositors', label: 'Depositors' },
  { value: 'newDepositors', label: 'First Depositors' },
  { value: 'nonDepositors', label: 'Non Depositors' }
]

export const playerCashierHeaders = [
  { label: 'Id', value: 'userId' },
  { label: 'UserName', value: 'username' },
  { label: 'Player Name', value: 'playerName' }
]

export const playerRestrictTransactionType = [
  { label: 'None', value: 'none' },
  { label: 'Deposit', value: 'deposit' },
  { label: 'Withdraw', value: 'withdraw' }
]
