import moment from "moment";

export const formatDate = (date) => {
  const d = new Date(date);
  let month = "" + (d.getMonth() + 1);
  let day = "" + d.getDate();
  const year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [month, day, year].join("-");
};

export const formatDateYMD = (date) => {
  const d = new Date(date);
  let month = "" + (d.getMonth() + 1);
  let day = "" + d.getDate();
  const year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

export const getUtcDateYMD = (date, value) => {
  let d = new Date(date);
  if (value === "kpiReport") {
    const parsedData = new Date();
    const parsedRequiredDate = new Date(date);
    parsedData.setDate(parsedRequiredDate.getDate());
    d = new Date(parsedData.toString());
  }
  const utcDate = new Intl.DateTimeFormat("en-GB", { timeZone: "UTC" }).format(
    d
  );
  const [day, month, year] = utcDate.split("/");
  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
};

export const formatMaltaDateYMD = (date) => {
  return [
    date.split("-")[0],
    date.split("-")[1],
    parseInt(date.split("-")[2]) <= 9
      ? "0" + parseInt(date.split("-")[2])
      : parseInt(date.split("-")[2]),
  ].join("-");
};

export const getDateDaysAgo = (days) => {
  const now = new Date();
  now.setDate(now.getDate() - days);
  return now;
};

export const getDateDaysAfter = (days) => {
  const now = new Date();
  now.setDate(now.getDate() + days);
  return now;
};

export const getDateTimeDaysAfter = (days, date) => {
  const now = date ? new Date(date) : new Date();
  now.setDate(now.getDate() + days);
  const year = now.getFullYear();
  const month = (now.getMonth() + 1).toString().padStart(2, "0");
  const day = now.getDate().toString().padStart(2, "0");
  const hh = !date ? "00" : now.getHours().toString().padStart(2, "0");
  const mm = !date ? "00" : now.getMinutes().toString().padStart(2, "0");

  const formattedDate = `${year}-${month}-${day} ${hh}:${mm}`;

  return formattedDate;
};

export const getDateTime = (dateTime) => {
  const formatedDateTime = moment(dateTime).format("DD-MM-YYYY hh:mm A");

  return formatedDateTime;
};

export const getDateTimeCET = (dateTime) => {
  return new Intl.DateTimeFormat("en-GB", {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    day: "numeric",
    month: "numeric",
    year: "numeric",
    timeZone: "CET",
  }).format(new Date(dateTime));
};

export const getFirstAndLastDate = (month, year) => {
  // Create a Date object for the first day of the month
  const firstDate = formatDateYMD(new Date(year, month - 1, 1));

  // Get the last day of the month
  const lastDay = new Date(year, month, 0).getDate();

  // Create a Date object for the last day of the month
  const lastDate = formatDateYMD(new Date(year, month - 1, lastDay));

  return [firstDate, lastDate];
};

export const commonDateFormat = (date) => {
  const d = new Date(date);
  let month = "" + (d.getMonth() + 1);
  let day = "" + d.getDate();
  const year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [day, month, year].join("-");
};

/**
 * @param {Date}  start - Older date object
 * @param {Date}  end - Newer date object
 */

/**
 * @returns {Number} - Difference between 2 date objects in ms
 */

export const getDifferenceInMilliSeconds = (start, end) => {
  return (end - start)
}

/**
 * @param {Date}  start - Older date object
 * @param {Date}  end - Newer date object
 * @param {Number}  range - Time tenure in ms under which the difference should fall
 */

/**
 * @returns {Boolean} - Whether the difference of 2 dates in ms fall within the given range or not
 */

export const checkTimeDiffFallsInRange = (start, end, range) => {
  const timeDiff = getDifferenceInMilliSeconds(start, end)

  return timeDiff < range
}
