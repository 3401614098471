import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateProvidersReOrderStart } from "../../../../store/redux-slices/tenantCasino";
import { useNavigate } from "react-router-dom";
import { getAllCasinoProvidersStart } from "../../../../store/redux-slices/superAdminCasinoManagement";

const useReorderProviders = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { casinoProvidersData, loading } = useSelector((state) => state.superAdminCasino)

  const [state, setState] = useState({ rows: [], count: 0 });

  useEffect(() => {
    if (casinoProvidersData) {
      setState(casinoProvidersData);
    }
  }, [casinoProvidersData]);

  const reorder = (subCategories, startIndex, endIndex) => {
    const result = Array.from(subCategories);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const rows = reorder(
      state.rows,
      result.source.index,
      result.destination.index
    );
    setState({ rows, count: rows.length });
  };

  const handleSave = () => {
    const row = [];
    state.rows.map((list) => row.push(list.tenantCasinoProviderId));
    dispatch(
      updateProvidersReOrderStart({
        data: { order: row },
        navigate,
      })
    );
  };

  useEffect(() => {
    dispatch(
      getAllCasinoProvidersStart({
        limit: "",
        pageNo: "",
        reorder: true
      })
    );
  }, []);

  return {
    loading,
    state,
    onDragEnd,
    handleSave,
    navigate,
  };
};

export default useReorderProviders;
