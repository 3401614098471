import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getGlobalRegistrationStart,
  updateGlobalRegistrationStart,
} from "../../../store/redux-slices/fetchData";
import { checkIsTenant } from "../../../utils/constants";
import {
  getUserFieldsStart,
  updateUserFieldsStart,
} from "../../../store/redux-slices/tenantUsers";

const useFormFields = () => {
  const dispatch = useDispatch();
  const [selectedClient, setSelectedClient] = useState("");
  const [selectedPortal, setSelectedPortal] = useState("");
  const { formFields, loading } = useSelector((state) => state.fetch);
  const { userFields, loading: TAloading } = useSelector(
    (state) => state.tenantUsers
  );

  const [data, setData] = useState(null);

  const handleChange = (e, check) => {
    const isVerificationOrDuplicate = (name) =>
      name === "phoneVerification" || name === "duplicateUser";

    const updateData = (key, value, additionalData = {}) => {
      setData({
        ...data,
        [key]: value,
        ...additionalData,
      });
    };

    const { name, checked } = e.target;

    if (!(checkIsTenant() || selectedPortal)) {
      if (isVerificationOrDuplicate(name)) {
        updateData(name, checked);
      } else if (name === "newsletterAndSms") {
        updateData(name, checked ? 2 : 0, { sms: 0, newsLetter: 0 });
      } else if (name === "sms" || name === "newsLetter") {
        updateData(name, checked ? 2 : 0, { newsletterAndSms: 0 });
      } else {
        updateData(name, checked ? 2 : 0);
      }
    } else {
      if (isVerificationOrDuplicate(name)) {
        updateData(name, checked);
      } else if (check === "") {
        if (name === "newsletterAndSms") {
          updateData(name, checked ? 1 : 0, { sms: 0, newsLetter: 0 });
        } else if (name === "sms" || name === "newsLetter") {
          updateData(name, checked ? 1 : 0, { newsletterAndSms: 0 });
        } else {
          updateData(name, checked ? 1 : 0);
        }
      } else {
        if (name === "newsletterAndSms") {
          updateData(name, check ? 2 : 1, { sms: 0, newsLetter: 0 });
        } else if (name === "sms" || name === "newsLetter") {
          updateData(name, check ? 2 : 1, { newsletterAndSms: 0 });
        } else {
          updateData(name, check ? 2 : 1);
        }
      }
    }
  };

  const updateFields = () => {
    const apiData = { ...data };

    if (apiData.newsletterAndSms) {
      apiData.newsLetter = 0;
      apiData.sms = 0;
    }

    dispatch(
      checkIsTenant() || selectedPortal
        ? updateUserFieldsStart({ ...apiData, tenantId: selectedPortal || "" })
        : updateGlobalRegistrationStart({ data: apiData })
    );
  };

  useEffect(() => {
    setData(checkIsTenant() || selectedPortal ? userFields : formFields);
  }, [formFields, userFields]);

  useEffect(() => {
    dispatch(
      checkIsTenant() || selectedPortal
        ? getUserFieldsStart({ tenantId: selectedPortal || "" })
        : getGlobalRegistrationStart()
    );
  }, [selectedPortal]);

  return {
    formFields,
    loading: checkIsTenant() || selectedPortal ? TAloading : loading,
    data,
    setData,
    handleChange,
    updateFields,
    selectedClient,
    setSelectedClient,
    selectedPortal,
    setSelectedPortal,
  };
};

export default useFormFields;
