import React from 'react'
import { Row, Col, Form } from '@themesberg/react-bootstrap'
import DataTable from '../../../../../shared/DataTable'
import useDepositsGGRReport from '../../hooks/useDepositsGGRReport'
import { SpinnerLoader } from '../../../../../components/Preloader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloudArrowDown, faRedo } from '@fortawesome/pro-regular-svg-icons'
import { DateRangePickerWithoutInput } from '../../../../../components/DateRangePicker'
import DateOptionsFilter from '../../../../../components/DateOptionsFilter'
import { Button } from 'react-bootstrap'
import { handleDownloadCSV } from '../../../../../utils/fileDownloader'

const DepositsGGRReport = ({ isHidden, selectedPortal }) => {
  const {
    columns,
    data,
    loading,
    initiateDepositGGRReport,
    dateOptions,
    setDateOptions,
    state,
    setState,
    getCsvDownloadUrl
  } = useDepositsGGRReport(selectedPortal)

  return (
    <>
      <Row className='border-bottom d-flex align-items-center justify-content-between mb-2 pb-2'>
        <Col>
          <button
            onClick={() => initiateDepositGGRReport()}
            className='btn btn-sm btn-secondary d-flex align-items-center gap-1'
          >
            <span className='fs-7'>Refresh</span>
            {loading
              ? (
                <SpinnerLoader />
                )
              : (
                <FontAwesomeIcon style={{ fontSize: '15px' }} icon={faRedo} />
                )}
          </button>
        </Col>

        <Col xs='auto'>
          <div className='d-flex justify-content-end align-items-center DateRangePickerWithoutInputWrapper'>
            {dateOptions === 'custom' &&
              <DateRangePickerWithoutInput
                state={state} setState={setState}
              />}

            <Form.Label style={{ marginTop: '5px', marginRight: '10px', marginLeft: '10px' }}>
              Date Options
            </Form.Label>
            <DateOptionsFilter dateOptions={dateOptions} setDateOptions={setDateOptions} />

            <Button
              variant='success'
              size='sm'
              style={{ width: '150px' }}
              disabled={data?.length < 1}
              hidden={isHidden({ module: { key: 'DepositGgrReport', value: 'DR' } })}
              onClick={() => { handleDownloadCSV(getCsvDownloadUrl(selectedPortal), 'Deposit_GGR_Report') }}
            >
              Export <FontAwesomeIcon icon={faCloudArrowDown} />
            </Button>
          </div>
        </Col>
      </Row>
      <DataTable columns={columns} loading={loading} rowData={data} />
    </>
  )
}

export default DepositsGGRReport
