import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getloyaltyLevelStart, updateloyaltyLevelStart } from '../../store/redux-slices/superAdminSettings'
import { getAllBonusStart } from '../../store/redux-slices/admins'
import { getAllTABonusStart } from '../../store/redux-slices/bonus'
import { safeStringify } from '../../utils/helpers'
import { getItem } from '../../utils/storageUtils'

const useLoyalty = (isTenant, tenant, setEdit, tenantLoyalty) => {
  const dispatch = useDispatch()
  const { tenantId, adminId } = useParams()
  const { loading, loyaltyLevel } = useSelector(state => state.superAdminSettings)
  const [myLevels, setMyLevels] = useState(loyaltyLevel)

  useEffect(() => {
    dispatch(getloyaltyLevelStart({ isTenant, tenantId: tenant ? tenantId : '' }))
  }, [tenant])

  useEffect(() => {
    if (tenantLoyalty) {
      !isTenant
        ? dispatch(getAllBonusStart({
          adminId,
          tenantId,
          isActive: 'true',
          bonusType: safeStringify(['match', 'balance', 'wagering', 'freespins'])
        }))
        : dispatch(getAllTABonusStart({
          limit: '',
          pageNo: '',
          search: '',
          isActive: 'true',
          bonusType: safeStringify(['match', 'balance', 'wagering', 'freespins'])
        }))
    }
  }, [tenantLoyalty])

  useEffect(() => {
    const newLoyaltyLevel = []
    for (const level in loyaltyLevel) {
      const obj = loyaltyLevel[level]
      const myNewData = { level: obj.level, startPoint: obj.startPoint, endPoint: obj.endPoint, cashback_multiplier: (obj?.cashback_multiplier * 100).toFixed(2) }
      tenantLoyalty ? newLoyaltyLevel.push({ ...myNewData, bonusId: obj.bonusId }) : newLoyaltyLevel.push(myNewData)
    }
    setMyLevels(newLoyaltyLevel)
  }, [loyaltyLevel])

  const updateloyaltyLevel = (loyaltyLevel) => {
    const newLoyaltyLevel = []
    for (const level in loyaltyLevel?.loyaltyLevel) {
      const obj = loyaltyLevel?.loyaltyLevel[level]
      newLoyaltyLevel.push({ ...obj, cashback_multiplier: obj?.cashback_multiplier / 100 })
    }
    newLoyaltyLevel && dispatch(updateloyaltyLevelStart({ isTenant, loyaltyLevel: { loyaltyLevel: newLoyaltyLevel }, tenant, tenantId: tenant ? tenantId : getItem('tenant-id') }))
    setEdit && setEdit(false)
  }

  const addLevels = (levels) => {
    const lastLevel = levels?.[levels.length - 1]?.level
    setMyLevels([...levels, { level: lastLevel + 1, startPoint: '', endPoint: '', cashback_multiplier: '' }])
  }

  const deleteLevel = (levels) => {
    setMyLevels(levels.slice(0, levels?.length - 1))
  }

  return {
    loading,
    myLevels,
    updateloyaltyLevel,
    addLevels,
    deleteLevel
  }
}

export default useLoyalty
