import React, { useState } from 'react'
import { Formik, Form, ErrorMessage } from 'formik'
import {
  Col,
  Row,
  Form as BForm,
  Button,
  Tabs,
  Tab
} from '@themesberg/react-bootstrap'
import { createCmsNewSchema } from '../schema'
import useCreateCms from '../hooks/useCreateCms'
import ClientFilter from '../../../../components/ClientFilter'
import PortalFilter from '../../../../components/PortalFilter'
import useCheckPermission from '../../../../utils/checkPermission'
import Trigger from '../../../../components/OverlayTrigger'
import { faImages } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GalleryModal } from '../../../../components/ConfirmationModal'
import { SuperAdminRoutes, TenantRoutes } from '../../../../routes'
import { CreateCMSTemplate } from '../../../../components/EditCMSTemplate/CreateCMSTemplate'
import { languageCode } from '../../../Tenant/TenantConfigurations/Languages/constants'
import { toast } from '../../../../components/Toast'
import { checkIsTenant } from '../../../../utils/constants'
import { getItem } from '../../../../utils/storageUtils'

const CreateCMSNew = () => {
  const {
    navigate,
    createCms,
    selectedClient,
    selectedPortal,
    setSelectedClient,
    setSelectedPortal,
    galleryModal,
    setGalleryModal,
    getGalleryData,
    cmsKeys,
    selectedTab,
    setSelectedTab,
    languages,
    deleteCms
  } = useCreateCms()

  const { isHidden } = useCheckPermission()
  const [title, setTitle] = useState({ EN: '' })
  const [metaTitle, setMetaTitle] = useState({ EN: '' })
  const [metaDescription, setMetaDescription] = useState({ EN: '' })
  const [content, setContent] = useState({ EN: '' })

  return (
    <>
      <Row>
        <Col sm={8}>
          <h3>Create CMS</h3>
        </Col>
        <Col className='d-flex justify-content-end'>
          <Trigger message='Gallery'>
            <Button
              hidden={isHidden({ module: { key: 'ImageGallery', value: 'R' } })}
              onClick={() => {
                getGalleryData()
                setGalleryModal(true)
              }}
              variant='outline-secondary'
            >
              <FontAwesomeIcon icon={faImages} />
            </Button>
          </Trigger>
        </Col>
      </Row>

      <Formik
        initialValues={{
          title: title?.EN || '',
          slug: '',
          content: content?.EN || '',
          category: 1,
          isActive: true
        }}
        validationSchema={createCmsNewSchema}
        onSubmit={(formValues) => {
          for (const lang in title) {
            if (([undefined, ''].includes(content?.[lang]) && [undefined, ''].includes(title?.[lang]))) {
              delete title[lang]
              delete content[lang]
              delete metaTitle[lang]
              delete metaDescription[lang]
            }
          }
          createCms({
            cmsData: {
              ...formValues,
              title,
              metaTitle,
              metaDescription,
              content,
              tenantId: checkIsTenant() ? getItem('tenant-id') : selectedPortal,
              adminUserId: selectedClient
            }
          })
        }}
      >
        {({ values, errors, handleChange, handleSubmit, handleBlur, setFieldValue }) => (
          <Form>
            <Row className='mb-3 align-items-center'>
              <Col xs={6}>
                <Col>
                  <BForm.Label>
                    Slug <span className='text-danger'>*</span>
                  </BForm.Label>
                </Col>
                <Col>
                  <BForm.Control
                    type='text'
                    name='slug'
                    placeholder='Enter Slug'
                    value={values.slug}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />

                  <ErrorMessage
                    component='div'
                    name='slug'
                    className='text-danger'
                  />
                </Col>
              </Col>

              <Col>
                <BForm.Label>
                  Category <span className='text-danger'>*</span>
                </BForm.Label>

                <BForm.Select
                  type='text'
                  name='category'
                  style={{ maxWidth: '200px' }}
                  value={values.category}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option value={1}>Support Links</option>
                  <option value={2}>Other Links</option>
                  <option value={3}>Landing Page</option>
                </BForm.Select>
              </Col>

              <Col>
                <Col>
                  <BForm.Label>
                    Status <span className='text-danger'>*</span>
                  </BForm.Label>
                </Col>
                <Col className='mb-4'>
                  <BForm.Check
                    type='switch'
                    name='isActive'
                    defaultChecked={values.isActive}
                    value={values.isActive}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Col>
              </Col>

            </Row>
            {!checkIsTenant() &&
              <Row className='mt-3 d-flex'>
                <Col>
                  <ClientFilter
                    setSelectedClient={setSelectedClient}
                    setSelectedPortal={setSelectedPortal}
                    selectedClient={selectedClient}
                    cmsData
                  />
                </Col>

                {selectedClient && (
                  <Col>
                    <PortalFilter
                      setSelectedPortal={setSelectedPortal}
                      selectedPortal={selectedPortal}
                      selectedClient={selectedClient}
                      cmsData
                    />
                  </Col>
                )}
              </Row>}

            <Tabs
              activeKey={selectedTab}
              onSelect={(tab) => {
                if (content?.EN !== '' && title?.EN !== '') {
                  setSelectedTab(tab)
                } else {
                  toast('You must enter data for English language before switching to another language ', 'error')
                }
              }}
              className='nav-light mt-3'
            >
              <Tab
                eventKey='EN'
                title={
                  <Trigger message='English'>
                    <span>EN</span>
                  </Trigger>
                }
                mountOnEnter
                tabClassName={selectedTab !== 'EN' ? 'email' : 'email-active'}
              >
                <div className='mt-5'>
                  <CreateCMSTemplate
                    cmsKeys={cmsKeys}
                    setFieldValue={setFieldValue}
                    isHidden={isHidden}
                    selectedTab={selectedTab}
                    setSelectedTab={setSelectedTab}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    handleSubmit={handleSubmit}
                    navigate={navigate}
                    deleteCms={deleteCms}
                    initValues={values}
                    errors={errors}
                    title={title}
                    setTitle={setTitle}
                    metaTitle={metaTitle}
                    setMetaTitle={setMetaTitle}
                    metaDescription={metaDescription}
                    setMetaDescription={setMetaDescription}
                    content={content}
                    setContent={setContent}
                  />
                </div>
              </Tab>
              {languages?.length
                ? languages?.map((code) => {
                  return code !== 'EN' && (
                    <Tab
                      eventKey={code}
                      title={
                        <Trigger message={languageCode[code]}>
                          <span>{code}</span>
                        </Trigger>
                      }
                      key={code}
                      mountOnEnter
                      tabClassName={selectedTab !== code
                        ? (![undefined, ''].includes(content?.[code]) ||
                            ![undefined, ''].includes(title?.[code]))
                            ? 'email'
                            : ''
                        : 'email-active'}
                    >
                      <div className='mt-5'>
                        <CreateCMSTemplate
                          cmsKeys={cmsKeys}
                          setFieldValue={setFieldValue}
                          isHidden={isHidden}
                          selectedTab={selectedTab}
                          setSelectedTab={setSelectedTab}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          navigate={navigate}
                          handleSubmit={handleSubmit}
                          deleteCms={deleteCms}
                          initValues={values}
                          errors={errors}
                          title={title}
                          setTitle={setTitle}
                          metaTitle={metaTitle}
                          setMetaTitle={setMetaTitle}
                          metaDescription={metaDescription}
                          setMetaDescription={setMetaDescription}
                          content={content}
                          setContent={setContent}
                        />
                      </div>
                    </Tab>
                  )
                })
                : null}
            </Tabs>

            <div className='d-flex justify-content-between'>
              <Button
                variant='outline-warning'
                className='m-2'
                onClick={() => {
                  navigate(checkIsTenant() ? TenantRoutes.CMS : SuperAdminRoutes.CMS)
                }}
              >
                Cancel
              </Button>
              <Button
                variant='outline-success'
                hidden={isHidden({ module: { key: 'CMS', value: 'U' } })}
                onClick={() => {
                  if (content?.EN === '') {
                    toast('Content for English is Required', 'error')
                  } else {
                    if (title?.EN === '') {
                      toast('Title for English is Required', 'error')
                      window.scroll(0, 0)
                    } else if (errors.slug) {
                      window.scroll(0, 0)
                      handleSubmit()
                    } else {
                      handleSubmit()
                    }
                  }
                }}
                className='m-2'
              >
                Create
              </Button>

            </div>
          </Form>
        )}
      </Formik>
      {galleryModal &&
        <GalleryModal
          galleryModal={galleryModal}
          setGalleryModal={setGalleryModal}
        />}
    </>
  )
}

export default CreateCMSNew
