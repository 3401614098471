import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { getAllCasinoGamesStart } from '../../../../store/redux-slices/superAdminCasinoManagement'
import useDidMountEffect from '../../../../utils/useDidMountEffect'
import { getBannedGamesListDetailStart, setBannedGamesName, updateBannedGamesStart } from '../../../../store/redux-slices/superAdminTransactions'
import { checkIsTenant } from '../../../../utils/constants'
import { getItem } from '../../../../utils/storageUtils'

const useEditBannedGames = () => {
  const [searchParams] = useSearchParams()
  const { loading, getBannedGamesListDetail, removeBannedGames, listName } = useSelector((state) => state.superAdminTransactions)
  const { bannedGamesListId } = useParams()
  const [selectedProvider, setSelectedProvider] = useState('')
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [search, setSearch] = useState('')
  const [listBannedGames, setListBannedGames] = useState([])
  const isInitialRender = useDidMountEffect()
  const [limit, setLimit] = useState(15)
  const [page, setPage] = useState(1)
  const [selectedTab, setSelectedTab] = useState('games')
  const url = window.location.href
  const isTenant = url.includes('/tenant/')
  const isClone = url.includes('/clone/')
  const { allProviders } = useSelector((state) => checkIsTenant() ? state.tenantCasino : state.superAdminCasino)
  const tenantId = searchParams.get('tenantId')

  // const totalPages = Math.ceil(casinoGamesData?.count / limit)
  const totalBannedGamesPages = Math.ceil(getBannedGamesListDetail?.gameDetail?.count / limit)

  const fetchDetails = () => {
    dispatch(getBannedGamesListDetailStart({
      isTenant,
      bannedGamesListId,
      limit,
      pageNo: page,
      providerId: selectedProvider,
      search: search.trim()
    }))
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (!isInitialRender) {
        if (page === 1) {
          dispatch(getBannedGamesListDetailStart({
            isTenant,
            bannedGamesListId,
            limit,
            pageNo: page,
            providerId: selectedProvider,
            search: search.trim()
          }))
        } else {
          setPage(1)
        }
      }
    }, 1000)
    return () => clearTimeout(delayDebounceFn)
  }, [search])

  useEffect(() => {
    dispatch(
      getAllCasinoGamesStart({
        isTenant,
        limit,
        pageNo: page,
        casinoCategoryId: '',
        search: search.trim(),
        isActive: '',
        tenantId: '',
        selectedProvider
      })
    )
  }, [selectedProvider, page, limit])

  useEffect(() => {
    fetchDetails()
  }, [selectedProvider, page, limit])

  useEffect(() => {
    if (getBannedGamesListDetail?.gameDetail?.rows) {
      dispatch(setBannedGamesName(getBannedGamesListDetail.bannedGamesListName))
      setListBannedGames(getBannedGamesListDetail.gameDetail.rows)
      if (removeBannedGames.length > 0) {
        const filteredArray = getBannedGamesListDetail?.gameDetail?.rows.filter(item1 =>
          !removeBannedGames.some(item2 => item2.masterCasinoGameId === item1.masterCasinoGameId)
        )
        setListBannedGames(filteredArray)
      } else {
        setListBannedGames(getBannedGamesListDetail.gameDetail.rows)
      }
    }
  }, [getBannedGamesListDetail, selectedProvider])

  const handleSubmit = () => {
    const gameData = {
      name: listName,
      games: [],
      isClone,
      tenantId: checkIsTenant() ? getItem('tenant-id') : tenantId
    }

    const removedGames = removeBannedGames.map(item => item.identifier) || []
    dispatch(updateBannedGamesStart({ isTenant, gameData: { ...gameData, bannedGamesListId, removedGames }, navigate }))
  }

  return {
    loading,
    navigate,
    page,
    limit,
    setLimit,
    setPage,
    getBannedGamesListDetail,
    totalBannedGamesPages,
    bannedGamesListId,
    isTenant,
    isClone,
    listBannedGames,
    setListBannedGames,
    listName,
    dispatch,
    handleSubmit,
    selectedProvider,
    setSelectedProvider,
    search,
    setSearch,
    allProviders,
    selectedTab,
    setSelectedTab
  }
}

export default useEditBannedGames
