import { Button, Table } from '@themesberg/react-bootstrap'
import React from 'react'
import PaginationComponent from '../Pagination'
import { getDateTime } from '../../utils/dateFormatter'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Trigger from '../OverlayTrigger'
import { faWindowClose } from '@fortawesome/pro-regular-svg-icons'
import { faCheck, faEdit } from '@fortawesome/pro-solid-svg-icons'
import './style.css'

const CommentsList = ({
  setPage,
  page,
  totalPages,
  limit,
  setLimit,
  commentsList,
  loading = false,
  resolveComment,
  onEditClick,
  isEditButtonVisible
}) => {
  const tableHeaders = ['Id', 'Title', 'Comment', 'Commented By', 'Role', 'Status', 'Commented At', 'Action']

  return (
    <>
      {/* Table with tenants info */}
      <Table bordered striped responsive hover size='sm' className='text-center mt-4'>
        <thead className='thead-dark'>
          <tr>
            {tableHeaders.map((h, idx) => (
              <th
                key={`T-table_heading ${idx}`}
              >
                {h}
              </th>
            ))}
          </tr>
        </thead>

        <tbody id={loading ? 'cover-spin' : ''}>
          {commentsList && !loading &&
            commentsList?.rows?.map(
              ({
                comment,
                commentId,
                createdAt,
                commentedBy,
                role,
                status,
                title
              }) => {
                return (
                  <tr key={`transaction-list ${commentId}`}>
                    <td>{commentId}</td>
                    <td>{title || 'NA'}</td>
                    <td className='wrap' title={comment && comment}>{comment || 'NA'}</td>
                    <td>{commentedBy || 'NA'}</td>
                    <td>{role}</td>
                    <td>{status ? 'Active' : 'Resolved' || 'NA'}</td>
                    <td>{createdAt ? getDateTime(createdAt) : 'NA'}</td>
                    <td>
                      <Trigger message={`${!status ? 'Active' : 'Resolve'}`}>
                        <Button
                          className='m-1'
                          size='sm'
                          disabled={!status}
                          variant={!status ? 'success' : 'danger'}
                          onClick={() => resolveComment(commentId)}
                        >
                          <FontAwesomeIcon icon={!status ? faCheck : faWindowClose} />
                        </Button>
                      </Trigger>
                      {isEditButtonVisible(status, commentedBy, createdAt) && (
                        <Trigger message='Edit Comment'>
                          <Button
                            className='m-1'
                            size='sm'
                            variant='info'
                            onClick={() => {
                              onEditClick(title, comment, commentId)
                            }}
                          >
                            <FontAwesomeIcon icon={faEdit} />
                          </Button>
                        </Trigger>
                      )}
                    </td>
                  </tr>
                )
              })}

          {commentsList?.count === 0 && !loading && (
            <tr>
              <td colSpan={10} className='text-danger text-center'>
                No data found
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      {commentsList?.count > 0 && !loading && (
        <PaginationComponent
          page={commentsList?.count < page ? setPage(1) : page}
          totalPages={totalPages}
          setPage={setPage}
          limit={limit}
          setLimit={setLimit}
        />
      )}
    </>
  )
}

export default CommentsList
