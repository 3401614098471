import { Button, Form, FormCheck, InputGroup, Modal, Table } from '@themesberg/react-bootstrap'
import { ErrorMessage, Formik } from 'formik'
import React, { useState } from 'react'
import useManageMoney from './hooks/useManageMoney'
import { depositSchema } from './schemas'

const ManageMoney = ({
  show,
  handleClose,
  user,
  isTenant = false,
  isHidden
}) => {
  const {
    deposit
  } = useManageMoney({ isTenant, user, show })
  const [walletType, setWalletType] = useState('')
  const [transactionType, setTransactionType] = useState('')

  const getAmount = (transactionType, walletType, value) => {
    if (user) {
      const { currencyCode, userWallet } = user
      switch (transactionType) {
        case 'Cash':
          return walletType === 'add-money'
            ? <>{currencyCode} {(Number(userWallet?.amount || 0) + Number(value || 0))?.toFixed(2)}</>
            : walletType === 'remove-money'
              ? <>{currencyCode} {(Number(userWallet?.amount || 0) - Number(value || 0))?.toFixed(2)}</>
              : <>{currencyCode} {userWallet?.amount || 0}</>

        case 'Bonus':
          return walletType === 'add-money'
            ? <>{currencyCode} {(Number(userWallet?.nonCashAmount || 0) + Number(value || 0))?.toFixed(2)}</>
            : walletType === 'remove-money'
              ? <>{currencyCode} {(Number(userWallet?.nonCashAmount || 0) - Number(value || 0))?.toFixed(2)}</>
              : <>{currencyCode} {userWallet?.nonCashAmount || 0}</>

        default:
          return <>Please select wallet type</>
      }
    }
  }

  return (
    <>
      <Formik
        initialValues={{
          addAmount: '',
          transactionType: '',
          walletType: ''
        }}
        validationSchema={depositSchema}
        onSubmit={(formValues, { resetForm }) => {
          deposit(formValues)
          handleClose()
          resetForm({ formValues: '' })
        }}
      >
        {({ values, touched, errors, handleChange, handleBlur, handleSubmit, resetForm }) => (
          <Form>
            <Modal
              show={show}
              onHide={handleClose}
              backdrop='static'
              keyboard={false}
              size='lg'
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  <h4>Manage Money for '{user?.firstName} {user?.lastName}'</h4>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Table>
                  <tbody>
                    <tr>
                      <td>
                        <h6>Transaction Type</h6>
                        <ErrorMessage
                          component='div'
                          name='transactionType'
                          className='text-danger'
                        />
                      </td>
                      {
                        !isHidden({ module: { key: 'Users', value: 'AB' } }) &&
                          <td>
                            <FormCheck.Input
                              type='radio'
                              id='add-money'
                              name='transactionType'
                              label='Add Money'
                              aria-label='Add Money'
                              value='add-money'
                              style={{ marginRight: '10px' }}
                              onChange={(e) => {
                                handleChange(e)
                                setWalletType('add-money')
                              }}
                              onBlur={handleBlur}
                            />
                            <FormCheck.Label htmlFor='add-money'>Add Money</FormCheck.Label>
                          </td>
                      }
                      <td>
                        {!isHidden({ module: { key: 'Users', value: 'RB' } }) &&
                          <>
                            <FormCheck.Input
                              type='radio'
                              id='remove-money'
                              name='transactionType'
                              label='Remove Money'
                              aria-label='Remove Money'
                              value='remove-money'
                              style={{ marginRight: '10px' }}
                              onChange={(e) => {
                                handleChange(e)
                                setWalletType('remove-money')
                              }}
                              onBlur={handleBlur}
                            />
                            <FormCheck.Label htmlFor='remove-money'>Remove Money</FormCheck.Label>
                          </>}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h6>Wallet Type</h6>
                        <ErrorMessage
                          component='div'
                          name='walletType'
                          className='text-danger'
                        />
                      </td>
                      <td>
                        <FormCheck.Input
                          type='radio'
                          id='cash'
                          name='walletType'
                          aria-label='Cash Wallet'
                          value='cash'
                          style={{ marginRight: '10px' }}
                          onChange={(e) => {
                            handleChange(e)
                            setTransactionType('Cash')
                          }}
                          onBlur={handleBlur}
                        />
                        <FormCheck.Label htmlFor='cash'>Cash</FormCheck.Label>
                      </td>
                      <td>
                        <FormCheck.Input
                          type='radio'
                          id='non-cash'
                          name='walletType'
                          aria-label='Non-Cash Wallet'
                          value='non-cash'
                          style={{ marginRight: '10px' }}
                          onChange={(e) => {
                            handleChange(e)
                            setTransactionType('Bonus')
                          }}
                          onBlur={handleBlur}
                        />
                        <FormCheck.Label htmlFor='non-cash'>Bonus</FormCheck.Label>
                      </td>
                    </tr>
                  </tbody>
                </Table>

                <div className='form-group'>
                  <label
                    htmlFor='addAmount'
                    className={touched.addAmount && errors.addAmount ? 'text-danger' : ''}
                  >
                    Amount
                  </label>

                  <InputGroup
                    className={touched.addAmount && errors.addAmount
                      ? 'border border-danger'
                      : ''}
                  >
                    <InputGroup.Text>
                      {user?.currencyCode}
                    </InputGroup.Text>

                    <Form.Control
                      name='addAmount'
                      type='number'
                      step='any'
                      placeholder='Enter the amount'
                      value={values.addAmount}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </InputGroup>

                  <ErrorMessage
                    component='div'
                    name='addAmount'
                    className='text-danger'
                  />
                </div>
                <div className='d-flex form-group'>
                  <div className='w-40' style={{ width: '40%', margin: '2rem' }}>
                    <label
                      htmlFor='addAmount'
                      className={walletType ? 'd-flex h4' : 'd-flex'}
                    >
                      {getAmount(transactionType)}
                    </label>
                    <label htmlFor='realBalance'>
                      Real {transactionType} Balance
                    </label>
                  </div>
                  <div className='w-40' style={{ width: '40%', margin: '2rem' }}>
                    <label
                      htmlFor='getAmount'
                      className={walletType === 'add-money' ? 'd-flex text-success h4' : walletType === 'remove-money' ? 'text-danger d-flex h4' : 'd-flex'}
                    >
                      {getAmount(transactionType, walletType, values.addAmount)}
                    </label>
                    <label
                      htmlFor='updateBalance'
                      className={walletType === 'add-money' ? 'text-success' : walletType === 'remove-money' ? 'text-danger' : ''}
                    >
                      Updated {transactionType} Balance
                    </label>
                  </div>
                </div>
              </Modal.Body>
              <div className='mt-4 '>
                <Modal.Footer className='d-flex justify-content-between align-items-center'>
                  <Button
                    variant='outline-warning'
                    onClick={() => {
                      resetForm({ formValues: '' })
                      handleClose(false)
                      setWalletType('')
                      setTransactionType('')
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant='outline-success'
                    onClick={(e) => {
                      handleSubmit(e)
                      setWalletType('')
                      setTransactionType('')
                    }}
                    className='ml-2'
                  >
                    Submit
                  </Button>
                </Modal.Footer>
              </div>
            </Modal>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default ManageMoney
