import React from 'react'
import {
  Form,
  Row,
  Col,
  Table,
  ButtonGroup,
  Button
} from '@themesberg/react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStoreSlash, faBan, faEdit, faCheckSquare, faWindowClose, faLanguage } from '@fortawesome/pro-solid-svg-icons'
import useCheckPermission from '../../../utils/checkPermission'
import PaginationComponent from '../../../components/Pagination'
import Trigger from '../../../components/OverlayTrigger'
import useCountriesListing from './useCountriesListing'
import ConfirmationModal, { UpdateKycMethodModal } from '../../../components/ConfirmationModal'
import { kycMethodsList } from './constants'
import { checkIsTenant } from '../../../utils/constants'
import { downloadFile } from '../../../utils/fileDownloader'
import UploadModal from '../../../components/LanguageMgmt/components/uploadModal'

const Countries = () => {
  const {
    page,
    limit,
    showKycUpdateModal,
    countryData,
    name,
    totalPages,
    countries,
    loading,
    handleKycUpdateMethod,
    setCountryData,
    setShowKycUpdateModal,
    setLimit,
    setName,
    setPage,
    navigate,
    handleStatusShow,
    status,
    statusShow,
    myName,
    handleYes,
    setStatusShow,
    // active,
    // setActive,
    KYCMethod,
    setKYCMethod,
    languages,
    handleDownload,
    uploadModal,
    setUploadModal,
    handleUpload
  } = useCountriesListing()

  const { isHidden } = useCheckPermission()

  return (
    <>
      <Row>
        <Col xxl={6} xl={12}>
          <h3>Countries</h3>
        </Col>

        <Col xxl={6} xl={12} className='country-container'>
          <div className='d-flex justify-responsive flex-wrap' style={{ gap: '10px' }}>
            <div className='d-flex align-items-center'>
              <Form.Label style={{ marginRight: '15px', minWidth: '70px', marginBottom: '0px' }}>
                Kyc Method
              </Form.Label>

              <Form.Select
                onChange={(e) => { setKYCMethod(e.target.value) }}
                size='sm'
                style={{ maxWidth: '230px' }}
                value={KYCMethod}
              >
                <option value=''>All</option>
                <option value='0'>System KYC</option>
                <option value='1'>Sumsub</option>
              </Form.Select>
            </div>

            <div className='d-flex align-items-center'>
              <Form.Label style={{ marginRight: '15px', minWidth: '40px', marginBottom: '0px' }}>
                Search
              </Form.Label>

              <Form.Control
                type='search'
                style={{ maxWidth: '230px' }}
                placeholder='Search Country'
                size='sm'
                value={name}
                onChange={(event) =>
                  setName(event.target.value.replace(/[^\w\s]/gi, ''))}
              />
              {/* <Form.Label style={{ marginBottom: '0', marginRight: '15px' }}>
              Status
            </Form.Label>

            <Form.Select
              onChange={(e) => { setActive(e.target.value) }}
              size='sm'
              style={{ maxWidth: '230px' }}
              value={active}
            >
              <option value=''>All</option>
              <option value='true'>Active</option>
              <option value='false'>In-Active</option>
            </Form.Select> */}
            </div>
            {
              !isHidden({ module: { key: 'MultiLanguage', value: 'U' } }) &&
                <div className='d-flex'>
                  <Trigger message='Download CSV File'>
                    <Button
                      variant='outline-success'
                      size='sm'
                      hidden={isHidden({ module: { key: 'MultiLanguage', value: 'U' } })}
                      style={{ marginLeft: '10px' }}
                      className='m-1'
                      onClick={() => { downloadFile(handleDownload()) }}
                    >
                      Download Country Data
                    </Button>
                  </Trigger>
                </div>
            }
            {
              !isHidden({ module: { key: 'MultiLanguage', value: 'U' } }) &&
                <div className='d-flex'>
                  <Trigger message='Upload Xls File'>
                    <Button
                      variant='outline-success'
                      size='sm'
                      style={{ marginLeft: '10px' }}
                      className='m-1'
                      hidden={isHidden({ module: { key: 'MultiLanguage', value: 'U' } })}
                      onClick={() => setUploadModal(true)}
                    >
                      Upload Country Data
                    </Button>
                  </Trigger>
                </div>
             }

          </div>
        </Col>
      </Row>

      <Table bordered striped responsive hover size='sm' className='text-center mt-4'>
        <thead className='thead-dark'>
          <tr>
            <th>Id</th>
            <th>Country Code</th>
            <th>Country Name</th>
            {!checkIsTenant() && <th>Language</th>}
            <th>Kyc Method</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>

        <tbody id={loading ? 'cover-spin' : ''}>
          {countries && !loading &&
                      countries?.rows?.map(
                        ({ countryId, code, name: countryName, kycMethod, status, language }) => (
                          <tr key={countryId}>
                            <td>{countryId}</td>
                            <td>{code}</td>
                            <td>{countryName?.EN}</td>
                            {!checkIsTenant() && <td>{language?.languageName}</td>}
                            <td>{kycMethodsList[kycMethod] || 'NA'}</td>
                            <td className={status ? 'text-success' : 'text-danger'}>{status ? 'Active' : 'In-Active'}</td>

                            <td>
                              {!isHidden({ module: { key: checkIsTenant() ? 'TenantSettings' : 'RestrictedCountry', value: 'U' } })
                                ? (
                                  <ButtonGroup>
                                    <Trigger message='Edit'>
                                      <Button
                                        className='m-1'
                                        size='sm'
                                        variant='warning'
                                        onClick={() => {
                                          setCountryData((prev) => ({ ...prev, countryName, countryId, kycMethod: kycMethod || 0, languageId: language?.languageId, code }))
                                          setShowKycUpdateModal(true)
                                        }}
                                      >
                                        <FontAwesomeIcon icon={faEdit} />
                                      </Button>
                                    </Trigger>

                                    <Trigger message='Edit Translation'>
                                      <Button
                                        className='m-1'
                                        size='sm'
                                        variant='info'
                                        onClick={() => {
                                          navigate(`/${checkIsTenant() ? 'tenant' : 'super-admin'}/countries/edit/${code}`)
                                        }}
                                      >
                                        <FontAwesomeIcon icon={faLanguage} />
                                      </Button>
                                    </Trigger>

                                    <Trigger message='View Blocked Games'>
                                      <Button
                                        className='m-1'
                                        size='sm'
                                        variant='warning'
                                        hidden={checkIsTenant()}
                                        onClick={() => navigate(`/super-admin/countries/restricted-games/${countryId}`)}
                                      >
                                        <FontAwesomeIcon icon={faBan} />
                                      </Button>
                                    </Trigger>

                                    <Trigger message='View Blocked Providers'>
                                      <Button
                                        className='m-1'
                                        size='sm'
                                        variant='success'
                                        hidden={checkIsTenant()}
                                        onClick={() => navigate(`/super-admin/countries/restricted-providers/${countryId}`)}
                                      >
                                        <FontAwesomeIcon icon={faStoreSlash} />
                                      </Button>
                                    </Trigger>
                                    {!status
                                      ? (
                                        <Trigger message='Set Status Active'>
                                          <Button
                                            className='m-1'
                                            size='sm'
                                            variant='success'
                                            onClick={() =>
                                              handleStatusShow(
                                                checkIsTenant() ? code : countryId,
                                                status,
                                                countryName?.EN
                                              )}
                                            hidden={isHidden({ module: { key: 'CasinoManagement', value: 'T' } })}
                                          >
                                            <FontAwesomeIcon icon={faCheckSquare} />
                                          </Button>
                                        </Trigger>
                                        )
                                      : (
                                        <Trigger message='Set Status In-Active'>
                                          <Button
                                            className='m-1'
                                            size='sm'
                                            variant='danger'
                                            onClick={() =>
                                              handleStatusShow(
                                                checkIsTenant() ? code : countryId,
                                                status,
                                                countryName?.EN
                                              )}
                                            hidden={isHidden({ module: { key: 'CasinoManagement', value: 'T' } })}
                                          >
                                            <FontAwesomeIcon icon={faWindowClose} />
                                          </Button>
                                        </Trigger>
                                        )}
                                  </ButtonGroup>)
                                : '-'}
                            </td>
                          </tr>
                        )
                      )}
        </tbody>

        {countries?.count === 0 && !loading &&
                    (
                      <tr>
                        <td
                          colSpan={5}
                          className='text-danger text-center'
                        >
                          No data found
                        </td>
                      </tr>
                    )}
      </Table>

      {countries?.count !== 0 && !loading &&
              (
                <PaginationComponent
                  page={countries?.count < page ? setPage(1) : page}
                  totalPages={totalPages}
                  setPage={setPage}
                  limit={limit}
                  setLimit={setLimit}
                />
              )}

      {(checkIsTenant() ? true : languages?.count > 0) &&
        <UpdateKycMethodModal
          show={showKycUpdateModal}
          setShow={setShowKycUpdateModal}
          countryData={countryData}
          setCountryData={setCountryData}
          handleKycUpdateMethod={handleKycUpdateMethod}
          languages={languages}
        />}

      <ConfirmationModal
        setShow={setStatusShow}
        show={statusShow}
        handleYes={handleYes}
        active={status}
        name={myName}
      />

      {uploadModal &&
        <UploadModal
          show={uploadModal}
          setShow={setUploadModal}
          handleYes={handleUpload}
        />}
    </>
  )
}

export default Countries
