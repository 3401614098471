
import React from 'react'
import {
  Button,
  Form, Table
} from '@themesberg/react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUsers, faEdit, faTrash } from '@fortawesome/pro-solid-svg-icons'
import PaginationComponent from '../../../components/Pagination'
import Trigger from '../../../components/OverlayTrigger'
import ClientFilter from '../../../components/ClientFilter'
import PortalFilter from '../../../components/PortalFilter'
import { commonDateFormat } from '../../../utils/dateFormatter'
import useBannedUsers from './useBannedUsers'
import { tableHeaders } from './BannedUserConstant'
import { ButtonGroup } from 'react-bootstrap'
import DeleteBannedUser from './DeleteBannedUser'
import BannedPlayerForm from './BannedPlayerForm'
import './bannedUsers.scss'
export default ({ isTenant = false }) => {
  const {
    limit,
    setLimit,
    page,
    setPage,
    search,
    setSearch,
    totalPages,
    selectedClient,
    selectedPortal,
    setSelectedPortal,
    setSelectedClient,
    loading,
    bannedPlayers,
    isBannedPlayerFormVisible,
    setIsBannedPlayerFormVisible,
    openEditBannedPlayerModal,
    handleBannedPlayerSubmit,
    showBannedPlayerDeleteModal,
    setShowBannedPlayerDeleteModal,
    selectedBannedPlayer,
    setSelectedBannedPlayer,
    confirmDeleteBannedPlayer,
    deleteBannedPlayer,
    isEditing,
    setIsEditing
  } = useBannedUsers({ isTenant })

  return (
    <div className='p-1'>
      <div className='d-flex align-item-center justify-content-between banned-game-header-container'>
        <div className='banned-game-header'>
          <h3><FontAwesomeIcon icon={faUsers} />&nbsp; Banned Players</h3>
        </div>
        <div className='banned-game-filters'>
          <div className='d-flex align-item-center justify-content-end' style={{ gap: '15px' }}>
            {!isTenant && (
              <div className='d-flex banned-game-filter-container'>
                <ClientFilter
                  setSelectedClient={setSelectedClient}
                  setSelectedPortal={setSelectedPortal}
                  selectedClient={selectedClient}
                />
              </div>
            )}

            {selectedClient && (
              <div className='d-flex banned-game-filter-container'>
                <PortalFilter
                  setSelectedPortal={setSelectedPortal}
                  selectedPortal={selectedPortal}
                  selectedClient={selectedClient}
                />
              </div>
            )}

            <div className='d-flex banned-game-filter-container'>
              <Form.Label style={{ marginBottom: '0', marginRight: '5px', marginTop: '5px' }}>
                Search
              </Form.Label>

              <Form.Control
                type='search'
                placeholder='Search'
                size='sm'
                value={search}
                onChange={(event) =>
                  setSearch(
                    event.target.value.replace(/[~`!$%^&*#=)()><?]+/g, '')
                  )}
              />
            </div>

            <div className='d-flex '>
              <Button
                variant='outline-success'
                size='sm'
                onClick={() => {
                  setIsBannedPlayerFormVisible(true)
                  setIsEditing(false)
                }}
              >
                Create
              </Button>
            </div>
          </div>
        </div>
      </div>

      <Table bordered striped responsive hover size='sm' className='text-center mt-4'>
        <thead className='thead-dark'>
          <tr>
            {tableHeaders.map((h, idx) => (
              <th
                key={idx}
              >
                {h.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody id={loading ? 'cover-spin' : ''}>
          {!loading && Boolean(bannedPlayers) &&
            bannedPlayers?.rows?.map(
              (bannedPlayer) => {
                const { firstName, lastName, email, dateOfBirth, id, tenant } = bannedPlayer
                return (
                  <tr key={id}>
                    <td>{id}</td>
                    <td>{tenant?.name || 'Banned Across License'}</td>
                    <td>
                      <Trigger message={`${firstName} ${lastName}`}>
                        <span
                          style={{
                            width: '150px',
                            cursor: 'pointer'
                          }}
                          className='d-inline-block text-truncate'
                        >{`${firstName} ${lastName}`}
                        </span>
                      </Trigger>
                    </td>

                    <td>
                      <Trigger message={email}>
                        <span
                          style={{
                            width: '200px',
                            cursor: 'pointer'
                          }}
                          className='text-link d-inline-block text-truncate'
                        >
                          {email}
                        </span>
                      </Trigger>
                    </td>
                    <td>{dateOfBirth ? commonDateFormat(dateOfBirth) : 'NA'}</td>
                    <td>
                      <ButtonGroup>
                        <Trigger message='Edit'>
                          <Button
                            className='m-1'
                            size='sm'
                            variant='warning'
                            onClick={() => openEditBannedPlayerModal(bannedPlayer)}
                          >
                            <FontAwesomeIcon icon={faEdit} />
                          </Button>
                        </Trigger>
                        <Trigger message='Delete'>
                          <Button
                            className='m-1'
                            size='sm'
                            variant='danger'
                            onClick={() => confirmDeleteBannedPlayer(bannedPlayer)}
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </Button>
                        </Trigger>
                      </ButtonGroup>
                    </td>
                  </tr>
                )
              }
            )}

          {bannedPlayers?.count === 0 && !loading && (
            <tr>
              <td colSpan={13} className='text-danger text-center'>
                No data found
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      {bannedPlayers?.count !== 0 && !loading && (
        <PaginationComponent
          page={bannedPlayers?.count < page ? setPage(1) : page}
          totalPages={totalPages}
          setPage={setPage}
          limit={limit}
          setLimit={setLimit}
          totalCount={bannedPlayers?.count}
        />
      )}

      {isBannedPlayerFormVisible &&
        <BannedPlayerForm
          isBannedPlayerFormVisible={isBannedPlayerFormVisible}
          closeBannedPlayerForm={() => {
            setSelectedBannedPlayer('')
            setIsBannedPlayerFormVisible(false)
          }}
          selectedBannedPlayer={selectedBannedPlayer}
          handleBannedPlayerSubmit={handleBannedPlayerSubmit}
          isTenant={isTenant}
          isEdit={isEditing}
          adminId={selectedBannedPlayer?.adminId}
          tenantId={selectedBannedPlayer?.tenantId}
        />}

      {showBannedPlayerDeleteModal &&
        <DeleteBannedUser
          showModal={showBannedPlayerDeleteModal}
          handleDeleteYes={deleteBannedPlayer}
          name={selectedBannedPlayer.firstName + ' ' + selectedBannedPlayer.lastName}
          handleClose={() => setShowBannedPlayerDeleteModal(false)}
          loading={loading}
        />}

    </div>
  )
}
