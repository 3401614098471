import { createSlice } from '@reduxjs/toolkit'

const {
  actions: {
    getDemographicReportFailed,
    getDemographicReportComplete,
    getDemographicReportStart,
    getPlayerManagementStart,
    getPlayerManagementComplete,
    getPlayerManagementFailure,
    getLivePlayersReportStart,
    getLivePlayersReportComplete,
    getLivePlayersReportFailure,
    getPlayerLiabilityStart,
    getPlayerLiabilitySuccess,
    getPlayerLiabilityFailure,
    getKPISummaryStart,
    getKPISummarySuccess,
    getKPISummaryFailure,
    getKPIReportStart,
    getKPIReportSuccess,
    getKPIReportFailure,
    getGameReportFailure,
    getGameReportSuccess,
    getGameReportStart,
    getPlayerGameReportFailure,
    getPlayerGameReportSuccess,
    getPlayerGameReportStart,
    getElasticHealthStart,
    getElasticHealthComplete,
    getElasticHealthFailure,
    getCurrencyWiseWithdrawalStart,
    getCurrencyWiseWithdrawalComplete,
    getCurrencyWiseWithdrawalFailure,
    getJackpotContributionStart,
    getJackpotContributionComplete,
    getJackpotContributionFailure,
    depositGGRReportStart,
    depositGGRReportComplete,
    depositGGRReportFailure
  },
  reducer
} = createSlice({
  name: 'dashboard',
  initialState: {
    loading: false,
    playerLoading: false,
    demogData: [],
    topPlayers: null,
    playerLogedInData: null,
    playerLiabilityData: null,
    KPISummary: null,
    KPIReport: null,
    elasticHealth: false,
    currencyWiseWithdrawal: null,
    jackpotContribution: null,
    jackpotContributionLoading: false,
    depositGGRReportLoading: false,
    depositGGRReport: []
  },
  reducers: {
    getDemographicReportStart: (state) => ({
      ...state,
      demogLoading: true
    }),
    getDemographicReportComplete: (state, { payload }) => ({
      ...state,
      demogLoading: false,
      demogData: payload
    }),
    getDemographicReportFailed: (state) => ({
      ...state,
      demogLoading: false
    }),
    getPlayerManagementStart: (state) => ({
      ...state,
      playerLoading: true
    }),
    getPlayerManagementComplete: (state, { payload }) => ({
      ...state,
      playerLoading: false,
      playerData: payload
    }),
    getPlayerManagementFailure: (state) => ({
      ...state,
      playerLoading: false
    }),
    getLivePlayersReportStart: (state) => ({
      ...state,
      livePlayerLoading: true
    }),
    getLivePlayersReportComplete: (state, { payload }) => ({
      ...state,
      livePlayerLoading: false,
      livePlayerData: payload
    }),
    getLivePlayersReportFailure: (state) => ({
      ...state,
      livePlayerLoading: false
    }),
    getPlayerLiabilityStart: (state) => ({
      ...state,
      playerLiabilityLoading: true
    }),
    getPlayerLiabilitySuccess: (state, { payload }) => ({
      ...state,
      playerLiabilityLoading: false,
      playerLiabilityData: payload
    }),
    getPlayerLiabilityFailure: (state) => ({
      ...state,
      playerLiabilityLoading: false,
      playerLiabilityData: {}
    }),
    getKPISummaryStart: (state) => ({
      ...state,
      kpiSummeryLoading: true
    }),
    getKPISummarySuccess: (state, { payload }) => ({
      ...state,
      kpiSummeryLoading: false,
      KPISummary: payload
    }),
    getKPISummaryFailure: (state) => ({
      ...state,
      kpiSummeryLoading: false
    }),
    getKPIReportStart: (state) => ({
      ...state,
      kpiReportLoading: true
    }),
    getKPIReportSuccess: (state, { payload }) => ({
      ...state,
      kpiReportLoading: false,
      KPIReport: payload
    }),
    getKPIReportFailure: (state) => ({
      ...state,
      kpiReportLoading: false
    }),
    getGameReportStart: (state) => ({
      ...state,
      gameReportLoading: true
    }),
    getGameReportSuccess: (state, { payload }) => ({
      ...state,
      gameReportLoading: false,
      gameReport: payload
    }),
    getGameReportFailure: (state) => ({
      ...state,
      gameReportLoading: false
    }),
    getPlayerGameReportStart: (state) => ({
      ...state,
      gameReportLoadingPlayer: true
    }),
    getPlayerGameReportSuccess: (state, { payload }) => ({
      ...state,
      gameReportLoadingPlayer: false,
      gameReportPlayer: payload
    }),
    getPlayerGameReportFailure: (state) => ({
      ...state,
      gameReportLoadingPlayer: false
    }),
    getElasticHealthStart: (state) => ({
      ...state
    }),
    getElasticHealthComplete: (state, { payload }) => ({
      ...state,
      elasticHealth: payload
    }),
    getElasticHealthFailure: (state) => ({
      ...state
    }),
    getCurrencyWiseWithdrawalStart: (state) => ({
      ...state,
      playerLiabilityLoading: true
    }),
    getCurrencyWiseWithdrawalComplete: (state, { payload }) => ({
      ...state,
      currencyWiseWithdrawal: payload,
      playerLiabilityLoading: false
    }),
    getCurrencyWiseWithdrawalFailure: (state) => ({
      ...state,
      playerLiabilityLoading: false,
      currencyWiseWithdrawal: []
    }),
    getJackpotContributionStart: (state) => ({
      ...state,
      jackpotContributionLoading: true
    }),
    getJackpotContributionComplete: (state, { payload }) => ({
      ...state,
      jackpotContribution: payload,
      jackpotContributionLoading: false
    }),
    getJackpotContributionFailure: (state) => ({
      ...state,
      jackpotContributionLoading: false
    }),
    depositGGRReportStart: (state) => ({
      ...state,
      depositGGRReportLoading: true
    }),
    depositGGRReportComplete: (state, { payload }) => ({
      ...state,
      depositGGRReport: payload,
      depositGGRReportLoading: false
    }),
    depositGGRReportFailure: (state) => ({
      ...state,
      depositGGRReportLoading: false,
      depositGGRReport: []
    })
  }
})

export default reducer

export {
  getDemographicReportFailed,
  getDemographicReportComplete,
  getDemographicReportStart,
  getPlayerManagementStart,
  getPlayerManagementComplete,
  getPlayerManagementFailure,
  getLivePlayersReportStart,
  getLivePlayersReportComplete,
  getLivePlayersReportFailure,
  getPlayerLiabilityStart,
  getPlayerLiabilitySuccess,
  getPlayerLiabilityFailure,
  getKPISummaryStart,
  getKPISummarySuccess,
  getKPISummaryFailure,
  getKPIReportStart,
  getKPIReportSuccess,
  getKPIReportFailure,
  getGameReportFailure,
  getGameReportSuccess,
  getGameReportStart,
  getPlayerGameReportFailure,
  getPlayerGameReportSuccess,
  getPlayerGameReportStart,
  getElasticHealthStart,
  getElasticHealthComplete,
  getElasticHealthFailure,
  getCurrencyWiseWithdrawalStart,
  getCurrencyWiseWithdrawalComplete,
  getCurrencyWiseWithdrawalFailure,
  getJackpotContributionStart,
  getJackpotContributionComplete,
  getJackpotContributionFailure,
  depositGGRReportStart,
  depositGGRReportComplete,
  depositGGRReportFailure
}
