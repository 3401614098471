import { Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import {
  Row,
  Col,
  Form as BForm,
  Button,
  InputGroup
} from '@themesberg/react-bootstrap'
import { toast } from '../../../../components/Toast'
import Trigger from '../../../../components/OverlayTrigger'
import { getSAConvertAmount } from '../../../../utils/apiCalls'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExchangeAlt, faRedoAlt } from '@fortawesome/pro-solid-svg-icons'
import { convertCurrencyAmountDepositOptions, convertCurrencyAmountWithdrawalOptions } from '../../Bonus/components/CreateBonus/constants'
import { useDispatch, useSelector } from 'react-redux'
import { checkIsTenant } from '../../../../utils/constants'
import { safeStringify } from '../../../../utils/helpers'
import { getTenantStart } from '../../../../store/redux-slices/tenants'

const CurrenciesForm = ({
  details,
  tenantId,
  updateData,
  providerDetails
}) => {
  const dispatch = useDispatch()
  const { tenantDetails: SATenantDetails } = useSelector((state) => state.tenants)
  const [conversion, setConversion] = useState('')
  const [initialCurrVal, setInitialCurrValue] = useState({})
  const [selectedCurr, setSelectedCurr] = useState([])
  const { tenantDetails } = useSelector((state) => state.login)
  const [currencyCodeValue, setCurrencyCodeValue] = useState('EUR')
  const [isCurrencyChanged, setIsCurrencyChanged] = useState(false)
  const [isConversionVisible, setIsConversionVisible] = useState(false)
  const [isDefaultCurrencyValue, setIsDefaultCurrencyValue] = useState(false)

  const currData = (checkIsTenant() ? tenantDetails : SATenantDetails)?.tenantConfig?.allowedCurrencies

  const initialCurrValue = initialCurrVal
  const transformedData = {}
  const updateCurrData = (currency) => {
    transformedData[currency] = {
      minDeposit: providerDetails?.settings?.minDeposit !== undefined && parseFloat(providerDetails?.settings.minDeposit[currency]),
      maxDeposit: providerDetails?.settings?.maxDeposit !== undefined && parseFloat(providerDetails?.settings.maxDeposit[currency]),
      maxWithdraw: providerDetails?.settings?.maxWithdraw !== undefined && parseFloat(providerDetails?.settings.maxWithdraw[currency]),
      minWithdraw: providerDetails?.settings?.minWithdraw !== undefined && parseFloat(providerDetails?.settings.minWithdraw[currency]),
      depositAmount: providerDetails?.settings?.depositAmount !== undefined && parseFloat(providerDetails?.settings.depositAmount[currency]),
      kycDepositAmount: providerDetails?.settings?.kycDepositAmount !== undefined && parseFloat(providerDetails?.settings.kycDepositAmount[currency])
    }
  }
  const providerPaymentDetail = providerDetails?.supportsDeposit ? providerDetails?.settings?.minDeposit : (providerDetails?.supportsWithdrawal && providerDetails?.settings?.minWithdraw)
  if (providerPaymentDetail) {
    for (const currency in providerPaymentDetail) {
      updateCurrData(currency)
    }
  }

  useEffect(() => {
    if (typeof (providerPaymentDetail) === 'object') {
      setIsDefaultCurrencyValue(false)
    } else {
      setIsDefaultCurrencyValue(true)
    }
    setInitialCurrValue(transformedData)
    if (providerDetails?.settings?.kycDepositAmount) {
      setSelectedCurr(Object.keys(providerDetails?.settings?.kycDepositAmount))
    }
    if (providerDetails?.settings?.kycDepositAmount) {
      setIsConversionVisible(true)
      setConversion('exchanged')
    }
  }, [providerDetails?.settings])

  useEffect(() => {
    if (providerDetails) {
      !checkIsTenant() && dispatch(getTenantStart({ tenantId }))
    }
  }, [])

  const result = {
    minDeposit: {},
    maxDeposit: {},
    minWithdraw: {},
    maxWithdraw: {},
    depositAmount: {},
    kycDepositAmount: {}
  }

  useEffect(() => {
    setCurrencyCodeValue(selectedCurr?.length ? selectedCurr[0] : 'EUR')
  }, [selectedCurr])

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          minDeposit: isDefaultCurrencyValue && parseFloat(providerDetails?.settings?.minDeposit),
          maxDeposit: isDefaultCurrencyValue && parseFloat(providerDetails?.settings?.maxDeposit),
          maxWithdraw: isDefaultCurrencyValue && parseFloat(providerDetails?.settings?.maxWithdraw),
          minWithdraw: isDefaultCurrencyValue && parseFloat(providerDetails?.settings?.minWithdraw),
          depositAmount: isDefaultCurrencyValue && parseFloat(providerDetails?.settings?.depositAmount),
          kycDepositAmount: isDefaultCurrencyValue && parseFloat(providerDetails?.settings?.kycDepositAmount)
        }}
        onSubmit={async (formValues) => {
          if ((formValues.minDeposit === '' || formValues.minDeposit === 0) && providerDetails?.supportsDeposit) {
            return toast('Enter Min Deposit', 'error')
          } else if ((formValues.maxDeposit === '' || formValues.maxDeposit === 0) && providerDetails?.supportsDeposit) {
            return toast('Enter Max Deposit', 'error')
          } else if ((formValues.minWithdraw === '' || formValues.minWithdraw === 0) && providerDetails?.supportsWithdrawal) {
            return toast('Enter Min Withdraw', 'error')
          } else if ((formValues.maxWithdraw === '' || formValues.maxWithdraw === 0) && providerDetails?.supportsWithdrawal) {
            return toast('Enter Max Withdraw', 'error')
          } else if (formValues.depositAmount === '' && providerDetails?.supportsDeposit) {
            return toast('Enter Deposit Amount', 'error')
          } else if (formValues.kycDepositAmount === '' && providerDetails?.supportsDeposit) {
            return toast('Enter KYC Deposit Amount', 'error')
          } else if (parseFloat(formValues.minDeposit) >= parseFloat(formValues.maxDeposit)) {
            return toast('Minimum Deposit must be less than Maximum Deposit', 'error')
          } else if (providerDetails?.supportsWithdrawal && parseFloat(formValues.minWithdraw) >= parseFloat(formValues.maxWithdraw)) {
            return toast('Minimum Withdraw must be less than Maximum Withdraw', 'error')
          }

          const response = []
          for (const currencyKey in initialCurrVal) {
            const currencyData = initialCurrVal[currencyKey]
            response.push({
              currencyKey,
              ...currencyData
            })
          }

          const updateResult = (item) => {
            result.minDeposit[item?.currencyKey] = item?.minDeposit
            result.maxDeposit[item?.currencyKey] = item?.maxDeposit
            result.minWithdraw[item?.currencyKey] = item?.minWithdraw
            result.maxWithdraw[item?.currencyKey] = item?.maxWithdraw
            result.depositAmount[item?.currencyKey] = item?.depositAmount
            result.kycDepositAmount[item?.currencyKey] = item?.kycDepositAmount
          }

          if (providerDetails) {
            await response?.forEach(item => updateResult(item))
          } else {
            await response?.forEach(item => item?.currencyKey === 'EUR' && updateResult(item))

            await response?.forEach(item => item?.currencyKey !== 'EUR' && updateResult(item))
          }

          if (!isDefaultCurrencyValue) {
            for (const currencyKey in initialCurrVal) {
              if ((result?.minDeposit[currencyKey] === '' || result?.minDeposit[currencyKey] === 0) && providerDetails?.supportsDeposit) {
                return toast('Enter Min Deposit', 'error')
              } else if ((result?.maxDeposit[currencyKey] === '' || result?.maxDeposit[currencyKey] === 0) && providerDetails?.supportsDeposit) {
                return toast('Enter Max Deposit', 'error')
              } else if ((result?.minWithdraw[currencyKey] === '' || result?.minWithdraw[currencyKey] === 0) && providerDetails?.supportsWithdrawal) {
                return toast('Enter Min Withdraw', 'error')
              } else if ((result?.maxWithdraw[currencyKey] === '' || result?.maxWithdraw[currencyKey] === 0) && providerDetails?.supportsWithdrawal) {
                return toast('Enter Max Withdraw', 'error')
              } else if (result?.depositAmount[currencyKey] === '' && providerDetails?.supportsDeposit) {
                return toast('Enter Deposit Amount', 'error')
              } else if (result?.kycDepositAmount[currencyKey] === '' && providerDetails?.supportsDeposit) {
                return toast('Enter KYC Deposit Amount', 'error')
              } else if (providerDetails?.supportsDeposit && parseFloat(result?.minDeposit[currencyKey]) >= parseFloat(result?.maxDeposit[currencyKey])) {
                return toast(`Minimum Deposit must be less than Maximum Deposit for ${currencyKey}`, 'error')
              } else if (providerDetails?.supportsWithdrawal && parseFloat(result?.minWithdraw[currencyKey]) >= parseFloat(result?.maxWithdraw[currencyKey])) {
                return toast(`Minimum Withdraw must be less than Maximum Withdraw for ${currencyKey}`, 'error')
              }
            }
          }
          if (conversion === 'exchanged') {
            await getSAConvertAmount({
              currencyFields: {
                minDeposit: (result?.minDeposit?.[currencyCodeValue]) || formValues?.minDeposit || 0,
                maxDeposit: (result?.maxDeposit?.[currencyCodeValue]) || formValues?.maxDeposit || 0,
                maxWithdraw: (result?.maxWithdraw?.[currencyCodeValue]) || formValues?.maxWithdraw || 0,
                minWithdraw: (result?.minWithdraw?.[currencyCodeValue]) || formValues?.minWithdraw || 0,
                depositAmount: (result?.depositAmount?.[currencyCodeValue]) || formValues?.depositAmount || 0,
                kycDepositAmount: (result?.kycDepositAmount?.[currencyCodeValue]) || formValues?.kycDepositAmount || 0
              },
              currencyCode: currencyCodeValue,
              tenantIds: JSON.stringify([tenantId]),
              requiredCurrencyCodes: providerDetails ? safeStringify(selectedCurr) : ''
            }).then((res) => {
              setInitialCurrValue(providerDetails ? res?.data?.data?.currenciesAmount : { EUR: res?.data?.data?.currenciesAmount?.EUR, ...res?.data?.data?.currenciesAmount })
            })
          } else {
            await updateData({ data: providerDetails ? { ...result, requiredCurrencyCodes: selectedCurr } : { ...result } })
          }
          setIsDefaultCurrencyValue(false)
        }}
      >
        {({ values, handleChange, handleSubmit, handleBlur, setFieldValue, resetForm }) => (
          <>
            {/* For cashier */}
            {providerDetails &&
              <Row key={tenantId} className='mt-5 mx-2'>
                {currData?.map((code) => (
                  <Col sm={2} key={code} className='d-flex align-items-center'>
                    <BForm.Check
                      name={code}
                      type='checkbox'
                      checked={selectedCurr.includes(code)}
                      onBlur={handleBlur}
                      onChange={(e) => {
                        if (e.target.checked) setSelectedCurr(curr => [...curr, code])
                        else setSelectedCurr(curr => curr.filter(c => c !== code))
                      }}
                    />
                    <BForm.Label className='mx-2 mb-0 text-nowrap'>
                      {code}
                    </BForm.Label>
                  </Col>
                ))}
              </Row>}

            {isConversionVisible && !details &&
              <Col className='d-flex justify-content-end'>
                {providerDetails &&
                  <Trigger message='Reset'>
                    <Button
                      variant='outline-success'
                      className='align-self-center mx-2'
                      onClick={() => {
                        result.minDeposit = {}
                        result.maxDeposit = {}
                        result.minWithdraw = {}
                        result.maxWithdraw = {}
                        result.depositAmount = {}
                        result.kycDepositAmount = {}
                        resetForm()
                        setConversion('')
                        setSelectedCurr([])
                        setIsCurrencyChanged(false)
                        setCurrencyCodeValue('EUR')
                        setIsConversionVisible(false)
                        setInitialCurrValue({})
                      }}
                    >
                      <FontAwesomeIcon icon={faRedoAlt} />
                    </Button>
                  </Trigger>}

                <Trigger message='Fetch according to primary currency'>
                  <Button
                    disabled={
                      !(initialCurrValue && Object.keys(initialCurrValue).length > 0)
                        ? providerDetails?.supportsDeposit
                          ? providerDetails?.supportsWithdrawal
                            ? (!((values.minDeposit && values.maxDeposit && values.minWithdraw && values.maxWithdraw)))
                            : (!((values.minDeposit && values.maxDeposit && values?.depositAmount >= 0 && values?.kycDepositAmount >= 0)))
                          : providerDetails?.supportsWithdrawal ? (!((values.minWithdraw && values.maxWithdraw))) : true
                        : false
                    }
                    onClick={() => {
                      setConversion('exchanged')
                      handleSubmit()
                    }}
                  >
                    <FontAwesomeIcon icon={faExchangeAlt} />
                  </Button>
                </Trigger>
              </Col>}
            <Form className='align-items-center m-3'>
              {!isDefaultCurrencyValue && initialCurrValue && Object.keys(initialCurrValue).length > 0
                ? Object.keys(initialCurrValue).map((key, index) => (providerDetails ? selectedCurr?.includes(key) : true) && (
                  <Row key={index} className='g-2 mb-2'>
                    <Col sm='auto' className='d-flex'>
                      <Col className='px-1 text-center'>
                        {index < 1 && <label style={{ fontSize: '14px' }} htmlFor='currency'>Currency</label>}
                        <InputGroup>
                          <BForm.Control
                            name={`currency[${key}]`}
                            value={key}
                            onInput={handleChange}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            disabled
                          />
                        </InputGroup>
                      </Col>
                      {providerDetails?.supportsWithdrawal &&
                        Object.keys(initialCurrValue[key]).map((currKey, currIndex) => {
                          const hide = !(currKey === 'minWithdraw' || currKey === 'maxWithdraw')
                          return (
                            <Col className='px-1 text-center' key={currIndex} hidden={hide}>
                              {index < 1 && (
                                <label htmlFor={currKey} style={{ fontSize: '14px' }}>
                                  {
                                    convertCurrencyAmountWithdrawalOptions?.find((val) =>
                                      val.value === currKey)?.label
                                  }
                                </label>
                              )}
                              <InputGroup>
                                <BForm.Control
                                  name={`currency[${key}][${currKey}]`}
                                  value={initialCurrValue[key][currKey]}
                                  onInput={handleChange}
                                  hidden={hide}
                                  placeholder={initialCurrValue[key][currKey] === 0 && 'Enter Amount'}
                                  disabled={details}
                                  onBlur={handleBlur}
                                  onChange={(e) => {
                                    setFieldValue(`currency[${key}][${currKey}]`, e)
                                    initialCurrValue[key][currKey] = e.target.value
                                    setInitialCurrValue(initialCurrValue)
                                    setCurrencyCodeValue(key)
                                    setIsConversionVisible(true)
                                    setConversion('exchanged')
                                  }}
                                />
                              </InputGroup>
                            </Col>
                          )
                        })}

                      {providerDetails?.supportsDeposit && Object.keys(initialCurrValue[key]).map((currKey, currIndex) => {
                        const hide = !(currKey === 'minDeposit' || currKey === 'maxDeposit' || currKey === 'depositAmount' || currKey === 'kycDepositAmount')
                        return (
                          <Col className='px-1 text-center' key={currIndex} hidden={hide}>
                            {index < 1 && (
                              <label htmlFor={currKey} style={{ fontSize: '14px' }}>
                                {
                                  convertCurrencyAmountDepositOptions?.find((val) =>
                                    val.value === currKey)?.label
                                }
                              </label>
                            )}
                            <InputGroup>
                              <BForm.Control
                                type='number'
                                placeholder={initialCurrValue[key][currKey] === 0 && 'Enter Amount'}
                                name={`currency[${key}][${currKey}]`}
                                value={initialCurrValue[key][currKey] === 0 ? (currKey === 'depositAmount' || currKey === 'kycDepositAmount') ? 0 : '' : initialCurrValue[key][currKey]}
                                onInput={handleChange}
                                hidden={hide}
                                disabled={details}
                                onBlur={handleBlur}
                                onChange={(e) => {
                                  setFieldValue(`currency[${key}][${currKey}]`, e)
                                  initialCurrValue[key][currKey] = (currKey === 'depositAmount' || currKey === 'kycDepositAmount') ? e.target.value : Number(e.target.value)
                                  setInitialCurrValue(initialCurrValue)
                                  setCurrencyCodeValue(key)
                                  setIsConversionVisible(true)
                                  setConversion('exchanged')
                                }}
                              />
                            </InputGroup>
                          </Col>
                        )
                      })}
                    </Col>
                  </Row>
                ))
                : (
                  <Row className='d-flex mt-3'>
                    <Col>
                      <BForm.Label>
                        Currency
                      </BForm.Label>
                      <BForm.Control
                        type='text'
                        name='minWithdraw'
                        disabled
                        value={selectedCurr?.length ? selectedCurr[0] : 'EUR'}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Col>
                    {providerDetails?.supportsDeposit && (
                      <>
                        <Col>
                          <BForm.Label>
                            Min Deposit
                          </BForm.Label>
                          <BForm.Control
                            type='number'
                            name='minDeposit'
                            disabled={details}
                            placeholder={values.minDeposit === 0 ? 'Enter Amount' : details ? '' : 'Enter Amount'}
                            value={values.minDeposit === 0 ? '' : isCurrencyChanged ? values.minDeposit : providerDetails?.settings?.minDeposit}
                            onChange={(e) => {
                              setIsConversionVisible(true)
                              setFieldValue('minDeposit', Number(e.target.value))
                              setIsCurrencyChanged(true)
                            }}
                            onBlur={handleBlur}
                          />
                        </Col>
                        <Col>
                          <BForm.Label>
                            Max Deposit
                          </BForm.Label>
                          <BForm.Control
                            type='number'
                            name='maxDeposit'
                            disabled={details}
                            placeholder={values.maxDeposit === 0 ? 'Enter Amount' : details ? '' : 'Enter Amount'}
                            value={values.maxDeposit === 0 ? '' : isCurrencyChanged ? values.maxDeposit : providerDetails?.settings?.maxDeposit}
                            onChange={(e) => {
                              setIsConversionVisible(true)
                              setFieldValue('maxDeposit', Number(e.target.value))
                              setIsCurrencyChanged(true)
                            }}
                            onBlur={handleBlur}
                          />
                        </Col>

                        {providerDetails?.supportsDeposit && (
                          <>
                            <Col>
                              <BForm.Label>
                                Deposit Amount
                              </BForm.Label>
                              <BForm.Control
                                type='number'
                                name='depositAmount'
                                disabled={details}
                                placeholder={values.depositAmount === '' ? 'Enter Amount' : details ? '' : 'Enter Amount'}
                                value={values.depositAmount === 0 ? 0 : values.depositAmount === '' ? '' : isCurrencyChanged ? values.depositAmount : providerDetails?.settings?.depositAmount}
                                onChange={(e) => {
                                  setIsConversionVisible(true)
                                  setFieldValue('depositAmount', e.target.value)
                                  setIsCurrencyChanged(true)
                                }}
                                onBlur={handleBlur}
                              />
                            </Col>

                            <Col>
                              <BForm.Label>
                                KYC Deposit Amount
                              </BForm.Label>
                              <BForm.Control
                                type='number'
                                name='kycDepositAmount'
                                disabled={details}
                                placeholder={values.kycDepositAmount === '' ? 'Enter Amount' : details ? '' : 'Enter Amount'}
                                value={values.kycDepositAmount === 0 ? 0 : values.kycDepositAmount === '' ? '' : isCurrencyChanged ? values.kycDepositAmount : providerDetails?.settings?.kycDepositAmount}
                                onChange={(e) => {
                                  setIsConversionVisible(true)
                                  setFieldValue('kycDepositAmount', e.target.value)
                                  setIsCurrencyChanged(true)
                                }}
                                onBlur={handleBlur}
                              />
                            </Col>
                          </>
                        )}
                      </>
                    )}
                    {providerDetails?.supportsWithdrawal && (
                      <>
                        <Col>
                          <BForm.Label>
                            Min Withdraw
                          </BForm.Label>
                          <BForm.Control
                            type='number'
                            name='minWithdraw'
                            disabled={details}
                            placeholder={values.minWithdraw === 0 ? 'Enter Amount' : details ? '' : 'Enter Amount'}
                            value={values.minWithdraw === 0 ? '' : isCurrencyChanged ? values.minWithdraw : providerDetails?.settings?.minWithdraw}
                            onChange={(e) => {
                              setFieldValue('minWithdraw', Number(e.target.value))
                              setIsConversionVisible(true)
                              setIsCurrencyChanged(true)
                            }}
                            onBlur={handleBlur}
                          />
                        </Col>

                        <Col>
                          <BForm.Label>
                            Max Withdraw
                          </BForm.Label>
                          <BForm.Control
                            type='number'
                            name='maxWithdraw'
                            disabled={details}
                            placeholder={values.maxWithdraw === 0 ? 'Enter Amount' : details ? '' : 'Enter Amount'}
                            value={values.maxWithdraw === 0 ? '' : isCurrencyChanged ? values.maxWithdraw : providerDetails?.settings?.maxWithdraw}
                            onChange={(e) => {
                              setFieldValue('maxWithdraw', Number(e.target.value))
                              setIsConversionVisible(true)
                              setIsCurrencyChanged(true)
                            }}
                            onBlur={handleBlur}
                          />
                        </Col>
                      </>
                    )}
                  </Row>)}

              <div className='mt-4 d-flex justify-content-end align-items-center'>
                {conversion === 'exchanged' &&
                  <div>
                    <Button
                      variant='outline-success'
                      onClick={() => {
                        setConversion('')
                        handleSubmit()
                        setIsConversionVisible(true)
                      }}
                      disabled={
                        !(initialCurrValue && Object.keys(initialCurrValue).length > 0)
                          ? providerDetails?.supportsDeposit
                            ? providerDetails?.supportsWithdrawal
                              ? (!((values.minDeposit && values.maxDeposit && values.minWithdraw && values.maxWithdraw)))
                              : (!((values.minDeposit && values.maxDeposit)))
                            : providerDetails?.supportsWithdrawal ? (!((values.minWithdraw && values.maxWithdraw))) : true
                          : false
                      }
                      hidden={details}
                      className='ml-2'
                    >
                      Submit
                    </Button>
                  </div>}
              </div>
            </Form>
          </>
        )}
      </Formik>
    </>
  )
}

export default CurrenciesForm
