import {
  Button,
  Col,
  Row,
  Form,
  Modal,
  InputGroup
} from '@themesberg/react-bootstrap'
import React from 'react'
import Preloader from '../../../../components/Preloader'
import CommentsList from '../../../../components/CommentsList'
import useComments from '../hooks/useComments'

const Comments = ({
  userData,
  isHidden,
  page,
  setPage,
  limit,
  setLimit,
  search,
  setSearch,
  role,
  setRole,
  status,
  setStatus,
  isTenant
}) => {
  const {
    title,
    loading,
    setTitle,
    totalPages,
    description,
    commentsList,
    setDescription,
    addCommentHandler,
    editCommentHandler,
    commentModalVariant,
    setCommentModalVariant,
    resolveComment,
    isEditButtonVisible
  } = useComments({
    page,
    limit,
    search,
    role,
    status,
    isTenant
  })

  return (
    <>
      {loading
        ? (
          <Preloader />
          )
        : (
          <>
            <Row className='d-flex'>
              <Col style={{ maxHeight: '25px' }} xs='auto' className='d-flex'>
                <Form.Label column='sm' className='mx-auto text-nowrap px-2'>
                  Search
                </Form.Label>
                <Form.Control
                  type='search'
                  size='sm'
                  value={search}
                  style={{ width: '260px' }}
                  placeholder='Search by Title / Description / Agent'
                  onChange={(e) => setSearch(e.target.value)}
                />

                <Form.Label
                  column='sm'
                  style={{ margin: '0 15px', minWidth: 'fit-content' }}
                >
                  Role
                </Form.Label>
                <Form.Select
                  name='isActive'
                  size='sm'
                  value={role}
                  onChange={(e) => setRole(e.target.value)}
                  style={{ maxWidth: '230px', height: '30px' }}
                >
                  <option value=''>Select Role</option>
                  <option value='admin'>Admin</option>
                  <option value='superadmin'>Super Admin</option>
                </Form.Select>

                <Form.Label
                  column='sm'
                  style={{ margin: '0 15px', minWidth: 'fit-content' }}
                >
                  Status
                </Form.Label>
                <Form.Select
                  name='isActive'
                  size='sm'
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                  style={{ maxWidth: '230px', height: '30px' }}
                >
                  <option value=''>Select Status</option>
                  <option value='true'>Active</option>
                  <option value='false'>Resolved</option>
                </Form.Select>
              </Col>
              {!isHidden({ module: { key: 'UserComment', value: 'C' } }) && (
                <Col className='d-flex mb-3 justify-content-end'>
                  <Button
                    variant='outline-success'
                    className=''
                    size='sm'
                    hidden={isHidden({
                      module: { key: 'UserComment', value: 'C' }
                    })}
                    onClick={() => setCommentModalVariant({ variant: 'add-comment' })}
                    style={{ marginRight: '10px' }}
                  >
                    Add Comment
                  </Button>
                </Col>
              )}
            </Row>
            <CommentsList
              page={page}
              setLimit={setLimit}
              limit={limit}
              setPage={setPage}
              totalPages={totalPages}
              commentsList={commentsList}
              isTenant={false}
              resolveComment={resolveComment}
              onEditClick={(title, description, commentId) => {
                setCommentModalVariant({ variant: 'edit-comment', commentId })
                setTitle(title)
                setDescription(description)
              }}
              isEditButtonVisible={isEditButtonVisible}
            />
          </>
          )}

      <Modal show={commentModalVariant.variant}>
        <Modal.Header>
          <h4>
            {`${commentModalVariant.variant === 'add-comment' ? 'Add' : 'Edit'} comment for '${userData?.firstName} ${userData?.lastName}'`}
          </h4>
        </Modal.Header>
        <form onSubmit={commentModalVariant.variant === 'add-comment' ? addCommentHandler : editCommentHandler} autoComplete='off'>
          <Modal.Body>
            <div className='form-group'>
              <label htmlFor='AddTitle'>Title</label>
              <InputGroup>
                <Form.Control
                  name='AddTitle'
                  type='text'
                  step='any'
                  placeholder='Enter the Title'
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  required
                />
              </InputGroup>
            </div>
            <div className='form-group mt-2'>
              <label htmlFor='description'>Comment</label>
              <InputGroup>
                <textarea
                  id='description'
                  name='description'
                  placeholder='Enter the Comment'
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  style={{
                    width: '100%',
                    borderRadius: '8px',
                    outline: 'none',
                    padding: '5px'
                  }}
                  rows='5'
                  required
                />
              </InputGroup>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className='m-2'
              variant='outline-warning'
              onClick={() => {
                setCommentModalVariant({ variant: '' })
                setTitle('')
                setDescription('')
              }}
            >
              Cancel
            </Button>
            <Button
              className='m-2'
              type='submit'
              variant='outline-success'
              style={{ width: '75px' }}
            >
              Submit
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  )
}

export default React.memo(Comments)
