import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { getAllCurrenciesStart } from '../../../../store/redux-slices/currencies'
import { getAllSuperAdminTransactionsStart, getSuperAdminAllTransactionsStart } from '../../../../store/redux-slices/superAdminTransactions'
import { getDateDaysAgo, formatDateYMD } from '../../../../utils/dateFormatter'
import { SuperAdminRoutes } from '../../../../routes'
import { getLoginToken } from '../../../../utils/storageUtils'
import useDidMountEffect from '../../../../utils/useDidMountEffect'
import { getCountriesStart } from '../../../../store/redux-slices/fetchData'
import { getTransactionPaymentCategoriesStart, getTransactionReportingPaymentCategoriesStart } from '../../../../store/redux-slices/payment'
import { getTransactionSortedValues } from '../../../../utils/constants'

const useTransactionBList = ({ isUserDetail, allTransaction = false, isTenant = false }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { userId } = useParams()
  const isInitialRender = useDidMountEffect()

  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(15)
  const [status, setStatus] = useState('')
  const [kycStatus, setKycStatus] = useState('')
  const [search, setSearch] = useState('')
  const [myUserId, setMyUserId] = useState(userId || '')
  const [transactionId, setTransactionId] = useState('')
  const [selectedClient, setSelectedClient] = useState('')
  const [selectedPortal, setSelectedPortal] = useState('')
  const { userData } = useSelector((state) => state.fetch)
  const [selectedCountry, setSelectedCountry] = useState('')
  const [selectedCurrency, setSelectedCurrency] = useState('')
  const [check, setCheck] = useState({ createdAt: false, updatedAt: true })
  const [selectedAction, setSelectedAction] = useState(JSON.stringify([]))

  const [transactionsSortBy, setTransactionsSortBy] = useState({
    id: '',
    paymentId: '',
    targetId: '',
    trxSuccess: '',
    trxDecline: '',
    mdr: '',
    pspFee: '',
    primaryCurrencyAmount: '',
    isSortingField: null
  })
  const [transactionHover, setTransactionHover] = useState({
    id: false,
    paymentId: false,
    targetId: false,
    trxSuccess: false,
    trxDecline: false,
    mdr: false,
    pspFee: false,
    primaryCurrencyAmount: false
  })
  const { countries } = useSelector(state => state.fetch)
  const [paymentMethod, setPaymentMethod] = useState('[]')
  const { allCurrencies } = useSelector((state) => state.currencies)
  const { loading, transactions } = useSelector((state) => state.superAdminTransactions)
  const [selectedPaymentProvider, setSelectedPaymentProvider] = useState('[]')
  const { allTransactions } = useSelector((state) => state.superAdminTransactions)
  const { allowedCurrencies } = useSelector((state) => state.login.tenantDetails?.tenantConfig || {})
  const { transactionPaymentCategories, transactionReportingPaymentCategories } = useSelector((state) => state.payment)
  const totalPages = Math.ceil(transactions?.count / limit)
  const allTransactionsTotalPages = Math.ceil(allTransactions?.count / limit)

  const [sortBy, setSortBy] = useState({
    id: '',
    paymentId: '',
    targetId: '',
    trxSuccess: '',
    trxDecline: '',
    mdr: '',
    pspFee: '',
    primaryCurrencyAmount: '',
    isSortingField: null
  })

  const [over, setOver] = useState({
    id: false,
    paymentId: false,
    targetId: false,
    trxSuccess: false,
    trxDecline: false,
    mdr: false,
    pspFee: false,
    primaryCurrencyAmount: false
  })

  const [state, setState] = useState([
    {
      startDate: getDateDaysAgo(10),
      endDate: new Date(),
      key: 'selection'
    }
  ])

  const [createdAt, setCreatedAt] = useState([
    {
      startDate: getDateDaysAgo(10),
      endDate: new Date(),
      key: 'selection'
    }
  ])

  // useEffect(() => {
  //   const orderBy = getTransactionSortedValues[transactionsSortBy.isSortingField] || ''
  //   const { id, paymentId, targetId, trxSuccess, trxDecline, mdr, pspFee, primaryCurrencyAmount, isSortingField } = transactionsSortBy
  //   const sort = id || paymentId || targetId || trxSuccess || trxDecline || mdr || pspFee || primaryCurrencyAmount || isSortingField

  //   const sortByField = (field, order) => {
  //     return function (a, b) {
  //       const valueA = parseFloat(a[field]) || 0
  //       const valueB = parseFloat(b[field]) || 0

  //       if (order === 'asc') return valueA - valueB
  //       else if (order === 'desc') return valueB - valueA
  //       return valueA - valueB
  //     }
  //   }

  //   const tableData = JSON.parse(JSON.stringify(transactions)) // creating a dep copy since key were not mutable.
  //   if (tableData?.rows?.length > 1) {
  //     const data = tableData?.rows?.sort(sortByField(orderBy, sort))
  //   }
  //   setTransactionsData(tableData)
  // }, [transactions, transactionsSortBy])

  useEffect(() => {
    if (allTransaction ? !isUserDetail : !isTenant) dispatch(getAllCurrenciesStart({ limit: '', pageNo: '' }))
    !isUserDetail && dispatch(getCountriesStart({ limit: '', pageNo: '', isActive: '' }))
    if (allTransaction ? !isUserDetail : true) dispatch(getTransactionPaymentCategoriesStart({ isTenant, adminId: isUserDetail ? userData?.adminId : '', tenantId: isUserDetail ? userData?.tenantId : '' }))
    if (allTransaction ? !isUserDetail : true) dispatch(getTransactionReportingPaymentCategoriesStart({ isTenant, adminId: isUserDetail ? userData?.adminId : '', tenantId: isUserDetail ? userData?.tenantId : '' }))
  }, [])

  const onDeposit = () => {
    navigate(SuperAdminRoutes.Deposit)
  }

  const getParams = (myPage) => {
    const orderBy = getTransactionSortedValues[transactionsSortBy.isSortingField] || ''
    const { id, paymentId, targetId, trxSuccess, trxDecline, mdr, pspFee, primaryCurrencyAmount, isSortingField } = transactionsSortBy
    const sort = id || paymentId || targetId || trxSuccess || trxDecline || mdr || pspFee || primaryCurrencyAmount || isSortingField || 'desc'

    return {
      sort,
      limit,
      check,
      search,
      status,
      kycStatus,
      orderBy,
      isTenant,
      isUserDetail,
      transactionId,
      paymentMethod,
      pageNo: myPage,
      orderType: sort,
      userId: myUserId,
      adminId: selectedClient,
      tenantId: selectedPortal,
      countryCode: selectedCountry,
      currencyId: selectedCurrency,
      transactionType: selectedAction,
      paymentProvider: selectedPaymentProvider,
      endDate: formatDateYMD(state.map(a => a.endDate)),
      startDate: formatDateYMD(state.map(a => a.startDate)),
      createdAtEndDate: formatDateYMD(createdAt.map(a => a.endDate)),
      createdAtStartDate: formatDateYMD(createdAt.map(a => a.startDate))
    }
  }

  useEffect(() => {
    if (!isInitialRender) {
      !allTransaction
        ? dispatch(getAllSuperAdminTransactionsStart({
          ...getParams(page)
        }))
        : dispatch(getSuperAdminAllTransactionsStart({
          ...getParams(page)
        }))
    }
  }, [page])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (!isInitialRender) {
        if (page === 1) {
          !allTransaction
            ? dispatch(getAllSuperAdminTransactionsStart({
              ...getParams(page)
            }))
            : dispatch(getSuperAdminAllTransactionsStart({
              ...getParams(page)
            })
            )
        } else {
          setPage(1)
        }
      }
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [transactionId, myUserId])

  useEffect(() => {
    setPage(1)
    !allTransaction
      ? dispatch(getAllSuperAdminTransactionsStart({
        ...getParams(1)
      }))
      : dispatch(getSuperAdminAllTransactionsStart({
        ...getParams(page)
      }))
  }, [transactionsSortBy, limit, selectedPortal, selectedCountry, selectedCurrency, selectedAction, state, selectedClient, search, status, kycStatus, selectedPaymentProvider, createdAt, paymentMethod, check])

  const getCsvDownloadUrl = (value) => {
    if (value === 'player') {
      return `${process.env.REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/transactions?csvDownload=true&limit=${limit}&pageNo=${page}&actioneeType=${search}${check.updatedAt ? `&startDate=${formatDateYMD(state.map(a => a.startDate))}&endDate=${formatDateYMD(state.map(a => a.endDate))}` : ''}${check.createdAt ? `&createdAtStartDate=${formatDateYMD(createdAt.map(a => a.startDate))}&createdAtEndDate=${formatDateYMD(createdAt.map(a => a.endDate))}` : ''}&currencyCode=${selectedCurrency}&transactionId=${transactionId}&transactionType=${selectedAction?.length ? selectedAction : '[]'}&tenantId=${selectedPortal}&adminId=${selectedClient}&paymentProvider=${selectedPaymentProvider}&status=${status}&kycStatus=${kycStatus}&token=${getLoginToken()}&userId=${myUserId}&countryCode=${selectedCountry}&paymentMethod=${paymentMethod}`
    } else if (value === 'allTransactions') {
      return `${process.env.REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/transactions-all?csvDownload=true&limit=${limit}&pageNo=${page}&actioneeType=${search}${check.updatedAt ? `&startDate=${formatDateYMD(state.map(a => a.startDate))}&endDate=${formatDateYMD(state.map(a => a.endDate))}` : ''}${check.createdAt ? `&createdAtStartDate=${formatDateYMD(createdAt.map(a => a.startDate))}&createdAtEndDate=${formatDateYMD(createdAt.map(a => a.endDate))}` : ''}&currencyCode=${selectedCurrency}&transactionId=${transactionId}&transactionType=${selectedAction?.length ? selectedAction : '[]'}&tenantId=${selectedPortal}&adminId=${selectedClient}&paymentProvider=${selectedPaymentProvider}&status=${status}&kycStatus=${kycStatus}&token=${getLoginToken()}&userId=${myUserId}&countryCode=${selectedCountry}&paymentMethod=${paymentMethod}`
    } else {
      return `${process.env.REACT_APP_API_URL}/api/${isTenant ? 'tenant' : 'superadmin'}/transactions?csvDownload=true&limit=${limit}&pageNo=${page}&actioneeType=${search}${check.updatedAt ? `&startDate=${formatDateYMD(state.map(a => a.startDate))}&endDate=${formatDateYMD(state.map(a => a.endDate))}` : ''}${check.createdAt ? `&createdAtStartDate=${formatDateYMD(createdAt.map(a => a.startDate))}&createdAtEndDate=${formatDateYMD(createdAt.map(a => a.endDate))}` : ''}&currencyCode=${selectedCurrency}&transactionId=${transactionId}&transactionType=${selectedAction?.length ? selectedAction : '[]'}&tenantId=${selectedPortal}&adminId=${selectedClient}&paymentProvider=${selectedPaymentProvider}&status=${status}&kycStatus=${kycStatus}&token=${getLoginToken()}&countryCode=${selectedCountry}&userId=${myUserId}&paymentMethod=${paymentMethod}`
    }
  }

  const getTransactionsCsvUrl = () => {
    return `${process.env.REACT_APP_API_URL}/api/superadmin/transactions-report?csvDownload=true${check.updatedAt ? `&startDate=${formatDateYMD(state.map(a => a.startDate))}&endDate=${formatDateYMD(state.map(a => a.endDate))}` : ''}`
  }

  return {
    allCurrencies,
    allowedCurrencies,
    setSelectedCurrency,
    setSearch,
    setLimit,
    setPage,
    totalPages,
    limit,
    page,
    setSelectedAction,
    navigate,
    selectedCurrency,
    selectedAction,
    search,
    state,
    setState,
    transactions,
    loading,
    onDeposit,
    selectedClient,
    setSelectedClient,
    selectedPortal,
    setSelectedPortal,
    getCsvDownloadUrl,
    getTransactionsCsvUrl,
    status,
    setStatus,
    kycStatus,
    setKycStatus,
    selectedCountry,
    setSelectedCountry,
    countries,
    setSelectedPaymentProvider,
    selectedPaymentProvider,
    transactionPaymentCategories,
    userId,
    allTransactions,
    allTransactionsTotalPages,
    sortBy,
    setSortBy,
    over,
    setOver,
    transactionId,
    setTransactionId,
    myUserId,
    setMyUserId,
    createdAt,
    setCreatedAt,
    transactionReportingPaymentCategories,
    paymentMethod,
    setPaymentMethod,
    check,
    setCheck,
    transactionsSortBy,
    setTransactionsSortBy,
    transactionHover,
    setTransactionHover,
    userData
  }
}

export default useTransactionBList
