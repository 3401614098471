import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { addCommentsStart, getCommentsStart, updateCommentStart } from '../../../../store/redux-slices/tenantsFetchData'
import useDidMountEffect from '../../../../utils/useDidMountEffect'
import { checkTimeDiffFallsInRange } from '../../../../utils/dateFormatter'

const COMMENTS_LIST_POLLING_INTERVAL = 30000 // 30 seconds
const EDIT_COMMENT_TIME_LIMIT = 300000 // 5 minutes

const useComments = ({
  page,
  limit,
  search,
  role,
  status,
  isTenant
}) => {
  const dispatch = useDispatch()
  const { userId } = useParams()
  const [title, setTitle] = useState('')
  const isInitialRender = useDidMountEffect()
  const [description, setDescription] = useState('')
  const [commentModalVariant, setCommentModalVariant] = useState({ variant: '' })

  const { loading, commentsList } = useSelector((state) => state.tenantsFetch)
  const { adminDetails } = useSelector(state => state.admins)
  const totalPages = Math.ceil(commentsList?.count / limit)

  const payload = {
    isTenant,
    pageNo: page,
    limit,
    userId,
    search,
    role,
    status
  }

  const getCommentsListHandler = async () => {
    dispatch(getCommentsStart({ ...payload }))
  }

  useEffect(() => {
    getCommentsListHandler()

    /*
      Polling to fetch comments list so that data is consistent between super admin and tenants.
        - Comments could be resolved by any admin, so data needs to be updated
    */
    const interval = setInterval(() => {
      getCommentsListHandler()
    }, COMMENTS_LIST_POLLING_INTERVAL)

    return () => clearInterval(interval)
  }, [role, status, limit, page])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (!isInitialRender) {
        dispatch(getCommentsStart({ ...payload }))
      }
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [search])

  const addCommentHandler = async (e) => {
    e.preventDefault()
    await dispatch(addCommentsStart({
      isTenant,
      data: {
        title,
        comment: description,
        userId
      },
      getComment: { ...payload }
    }))
    setCommentModalVariant({ variant: '' })
    setDescription('')
    setTitle('')
  }

  const editCommentHandler = async (e) => {
    e.preventDefault()
    dispatch(updateCommentStart({
      isTenant,
      data: {
        title,
        userId,
        comment: description,
        commentId: commentModalVariant?.commentId
      },
      getComment: { ...payload }
    }))
    setCommentModalVariant({ variant: '' })
    setDescription('')
    setTitle('')
  }

  const resolveComment = async (commentId) => {
    dispatch(updateCommentStart({
      isTenant,
      data: {
        commentId,
        status: false,
        userId
      },
      getComment: { ...payload }
    }))
  }

  /**
 * @param {Boolean}  status - Status of callee comment
 * @param {String}  commentedBy - Commenting user email of comment
 * @param {String} createdAt - JS timestamp of comment creation time
 */

  /**
 * @returns {Boolean} - Whether under given params a comment should have edit button visible or not
 */
  const isEditButtonVisible = (status, commentedBy, createdAt) => {
    if (!status) {
      return false
    }

    if (commentedBy !== adminDetails?.email) {
      return false
    }

    return checkTimeDiffFallsInRange(new Date(createdAt), new Date(), EDIT_COMMENT_TIME_LIMIT)
  }

  return {
    title,
    loading,
    setTitle,
    totalPages,
    description,
    commentsList,
    setDescription,
    addCommentHandler,
    editCommentHandler,
    commentModalVariant,
    setCommentModalVariant,
    resolveComment,
    isEditButtonVisible
  }
}

export default useComments
