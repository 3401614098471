import React from 'react'

import TableLayout from './TableLayout'
import DataRow from './DataRow'
const DataTable = (props) => {
  const { columns, rowData, loading } = props
  return (
    <TableLayout
      columns={columns}
      rowData={rowData}
      loading={loading}
      renderRow={(row) => <DataRow key={rowData.id || Math.random()} rowData={row} columns={columns} />}
    />
  )
}

export default DataTable
