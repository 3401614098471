import React from 'react'
import TooltipTrigger from '../TooltipTrigger'

/**
 * DataRow component renders a single row in a table.
 * It dynamically renders data based on the columns and supports custom actions for each row.
 *
 * @param {Object} props - The props passed to the component.
 * @param {Object} props.rowData - The data object representing a row.
 * @param {Array} props.columns - An array of column definitions, each with properties like key, label, render function, etc.
 * @param {Array} props.actions - An optional array of action buttons, each with a handler and icon.
 */
const DataRow = ({ rowData = {}, columns = [] }) => {
  return (
    <tr key={rowData.id || Math.random()}>
      {/* Loop through each column definition and render the appropriate cell content */}
      {columns.map((column, idx) => (
        <td key={idx}>
          {/* If column has a render function */}
          {column?.render
            ? (
                column?.isTruncated
                  ? (
                    <TooltipTrigger message={column.render(rowData)}>
                      <span
                        style={{ width: column.width || 'auto', cursor: 'pointer' }}
                        className={`d-inline-block ${column.className || ''} text-truncate`}
                      >
                        {column.render(rowData)}
                      </span>
                    </TooltipTrigger>
                    )
                  : (
                      column.render(rowData)
                    )
              )
            : column?.isTruncated
              ? (
                <TooltipTrigger message={rowData[column.key] || ''}>
                  <span
                    style={{ width: column.width || 'auto', cursor: 'pointer' }}
                    className={`d-inline-block ${column.className || ''} text-truncate`}
                  >
                    {rowData[column.key] !== undefined ? rowData[column.key] : 'N/A'}
                  </span>
                </TooltipTrigger>
                )
              : (
                  rowData[column?.key] !== undefined ? rowData[column.key] : column?.fallback || 'N/A'
                )}
        </td>
      ))}
    </tr>
  )
}

export default DataRow
