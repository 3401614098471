import React, { useEffect } from 'react'
import { walletConstants } from '../constants'
import { Button, Card, Col } from 'react-bootstrap'
import { Table } from '@themesberg/react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import { faRedo } from '@fortawesome/pro-solid-svg-icons'
import Trigger from '../../../../components/OverlayTrigger'
import { InlineLoader } from '../../../../components/Preloader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  getCashbackDataStart,
  getCurrentCashbackDataStart
} from '../../../../store/redux-slices/tenantUsers'
import { commonDateFormat } from '../../../../utils/dateFormatter'
import { currencyCodeList } from '../../../../utils/currencyCodeList'
import { getWalletDataStart } from '../../../../store/redux-slices/fetchData'

const UserWalletTable = ({ myUserData }) => {
  const walletData = myUserData?.userWallet
  const currencyCode = currencyCodeList[walletData?.currencyCode]
  return (
    <>
      {/* Table with UserWallet info */}
      <Table
        bordered
        responsive
        striped
        hover
        className='mt-1 text-left'
        size='sm'
      >
        <tbody>
          <tr key={`currencyCode-${walletData?.currencyCode}`}>
            <td>Currency</td>
            <td>{walletData?.currencyCode}</td>
          </tr>
          <tr
            key={`totalBalance-${
              walletData?.amount + walletData?.nonCashAmount
            }`}
          >
            <td>Total Balance</td>
            <td>
              {currencyCode}&nbsp;
              {walletData?.amount + walletData?.nonCashAmount}
            </td>
          </tr>
          {Object.keys(walletConstants)?.map((key, index) => (
            key === 'Deposit To Bonus Ratio'
              ? (
                <tr key={index}>
                  <td>{key}</td>
                  <td>
                    {walletData?.[walletConstants[key]] || '0'} %
                  </td>
                </tr>)
              : (
                <tr key={index}>
                  <td>{key}</td>
                  <td>
                    {currencyCode}&nbsp;
                    {walletData?.[walletConstants[key]] || '0'}
                  </td>
                </tr>
                )
          ))}
          <tr key={`createdAt-${walletData?.createdAt}`}>
            <td>Signed Up </td>
            <td>
              {walletData?.createdAt && commonDateFormat(walletData?.createdAt)}
            </td>
          </tr>
        </tbody>
      </Table>
    </>
  )
}

const UserWallet = ({ myUserData, isTenant = false, pdfDownload = false }) => {
  const dispatch = useDispatch()
  const {
    cashBackData,
    cashBackLoading,
    currentCashBackData,
    currentCashBackLoading
  } = useSelector((state) => state.tenantUsers)
  const { walletLoading } = useSelector((state) => state.fetch)

  const fetchWalletData = (fetch) => {
    (fetch ? true : myUserData?.userWallet?.ownerId !== myUserData?.userId) &&
      dispatch(
        getWalletDataStart({
          userId: myUserData?.userId,
          isTenant,
          tenantId: myUserData?.tenantId
        })
      )
  }

  useEffect(() => {
    fetchWalletData()
  }, [myUserData?.userId])

  const fetchCurrentCashback = () => {
    dispatch(
      getCurrentCashbackDataStart({
        userId: myUserData?.userId,
        level: myUserData?.level || 1,
        tenantId: myUserData?.tenantId,
        currencyCode: myUserData?.currencyCode,
        isTenant
      })
    )
  }

  const fetchCashback = () => {
    dispatch(
      getCashbackDataStart({
        userId: myUserData?.userId,
        level: myUserData?.level || 1,
        tenantId: myUserData?.tenantId,
        currencyCode: myUserData?.currencyCode,
        isTenant
      })
    )
  }

  useEffect(() => {
    if (myUserData?.tenantId) {
      fetchCashback()
      fetchCurrentCashback()
    }
  }, [myUserData?.userId])

  return (
    <>
      <Col xs={12} md={6} className='mb-2'>
        <Card className='card-overview' style={{ minHeight: '608px' }}>
          <h5 className='h4-overview text-center'>
            Player Wallet &nbsp;
            <Trigger message='Fetch Wallet Data' placement='top'>
              <Button
                variant='success'
                size='sm'
                hidden={pdfDownload}
                onClick={() => {
                  fetchWalletData(true)
                }}
              >
                <FontAwesomeIcon icon={faRedo} />
              </Button>
            </Trigger>
            <hr className='h4-hr' />
          </h5>
          <div className='div-overview'>
            {walletLoading
              ? (
                <InlineLoader />
                )
              : (
                <UserWalletTable myUserData={myUserData} />
                )}
          </div>
        </Card>
      </Col>
      <Col xs={12} md={6} className='mb-2'>
        <Card className='h-49 mb-4'>
          <h4 className='h4-overview text-center'>
            Cashback &nbsp;
            <Trigger message='Fetch Cashback Data' placement='top'>
              <Button
                variant='success'
                size='sm'
                hidden={pdfDownload}
                onClick={() => {
                  fetchCurrentCashback()
                }}
              >
                <FontAwesomeIcon icon={faRedo} />
              </Button>
            </Trigger>
            <hr className='h4-hr' />
          </h4>

          <div className='div-overview'>
            {currentCashBackLoading
              ? (
                <InlineLoader />
                )
              : (
                <>
                  <div className='d-flex justify-content-between py-1'>
                    <h6 className='px-2'>Daily Cashback</h6>
                    <div>
                      <span>
                        {currencyCodeList[myUserData?.userWallet?.currencyCode]}
                      &nbsp;
                        {currentCashBackData?.dailyCashback || '0'}
                      </span>
                    </div>
                  </div>

                  <div className='d-flex justify-content-between py-1'>
                    <h6 className='px-2'>Weekly Cashback</h6>
                    <div>
                      <span>
                        {currencyCodeList[myUserData?.userWallet?.currencyCode]}
                      &nbsp;
                        {currentCashBackData?.weeklyCashback || '0'}
                      </span>
                    </div>
                  </div>

                  <div className='d-flex justify-content-between py-1'>
                    <h6 className='px-2'>Monthly Cashback</h6>
                    <div>
                      <span>
                        {currencyCodeList[myUserData?.userWallet?.currencyCode]}
                      &nbsp;
                        {currentCashBackData?.monthlyCashback || '0'}
                      </span>
                    </div>
                  </div>
                </>
                )}
          </div>
        </Card>

        <Card className='h-47'>
          <h4 className='h4-overview text-center'>
            Previous Cashback &nbsp;
            <Trigger message='Fetch Previous Cashback Data' placement='top'>
              <Button
                variant='success'
                size='sm'
                hidden={pdfDownload}
                onClick={() => {
                  fetchCashback()
                }}
              >
                <FontAwesomeIcon icon={faRedo} />
              </Button>
            </Trigger>
            <hr className='h4-hr' />
          </h4>

          <div className='div-overview'>
            {cashBackLoading
              ? (
                <InlineLoader />
                )
              : (
                <>
                  <div className='d-flex justify-content-between py-1'>
                    <h6 className='px-2'>Daily Cashback</h6>
                    <div>
                      <span>
                        {currencyCodeList[myUserData?.userWallet?.currencyCode]}
                      &nbsp;
                        {cashBackData?.dailyCashback || '0'}
                      </span>
                    </div>
                  </div>

                  <div className='d-flex justify-content-between py-1'>
                    <h6 className='px-2'>Weekly Cashback</h6>
                    <div>
                      <span>
                        {currencyCodeList[myUserData?.userWallet?.currencyCode]}
                      &nbsp;
                        {cashBackData?.weeklyCashback || '0'}
                      </span>
                    </div>
                  </div>

                  <div className='d-flex justify-content-between py-1'>
                    <h6 className='px-2'>Monthly Cashback</h6>
                    <div>
                      <span>
                        {currencyCodeList[myUserData?.userWallet?.currencyCode]}
                      &nbsp;
                        {cashBackData?.monthlyCashback || '0'}
                      </span>
                    </div>
                  </div>
                </>
                )}
          </div>
        </Card>
      </Col>
    </>
  )
}

export default UserWallet
