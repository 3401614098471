import React from 'react'
import Trigger from '../../../../components/OverlayTrigger'
import { Button } from '@themesberg/react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons'
import TooltipTrigger from '../../../../shared/TooltipTrigger'
import './LabelData.scss'
const LabelData = ({ label, subValue, value, desc, inActive = false, adminPermissions }) => {
  const isLongValue = value && value.length > 200

  const displayedValue = isLongValue ? `${value.substring(0, 200)}...` : value

  return (
    <div key={label} className='d-flex justify-content-between m-1 labelData-container'>
      <h6 className='fontSize-14'>{label}</h6>
      <div className='maxWidth-400'>
        {isLongValue && label === 'In-Active Reason'
          ? (
            <TooltipTrigger message={value}>
              <span className={`${subValue} fontSize-14`}>
                {displayedValue}
              </span>
            </TooltipTrigger>
            )
          : (
            <span className={`${subValue} fontSize-14`}>
              {value || ''}
            </span>
            )}
        {(inActive
          ? value !== '-' && true
          : !adminPermissions?.Users?.includes('RUI') && label === 'Email') && desc && (
            <Trigger message={desc}>
              <Button
                variant='outline-warning'
                size='xs'
                className='m-1'
                color='warning'
              >
                <FontAwesomeIcon icon={faInfoCircle} />
              </Button>
            </Trigger>
        )}
      </div>
    </div>
  )
}

export default LabelData
